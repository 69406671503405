import {Alert, Button} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {resetAllDbFilters} from '../../../src/redux/actions/filters';
import {getFilters} from '../../../src/selectors/filters';
import {ApisContext} from '../apis/ApisContext';

// An alert to warn the user that his current filter settings return no data. Has a button to allow the user to
// reset the filters.
export const NoDataAlert: React.FC = React.memo(() => {
  const {analytics, store, t} = React.useContext(ApisContext);
  const filters = useSelector(getFilters);
  const hasFilters = Object.entries(filters).some(
    ([key, value]) =>
      (Array.isArray(value) && value.length > 0) || (key === 'added_on' && (value.startDate || value.endDate)),
  );
  return (
    <Alert
      message={t('NoData')}
      showIcon
      description={t(hasFilters ? 'NoDataFoundForYourFilters' : 'NoData')}
      type="warning"
      action={
        hasFilters ? (
          <Button
            size="large"
            type="ghost"
            onClick={() => {
              analytics.logEvent({
                event_name: 'no-data-alert/reset-filters',
              });
              store.dispatch(resetAllDbFilters());
            }}>
            {t('Reset')}
          </Button>
        ) : null
      }
    />
  );
});

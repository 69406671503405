import {FetcherFunc} from '../../../../src/FetcherFunc';
import {Uuid} from '../../../../src/models/types';
import {DbThunkAction, _deleteDbEntries, queueDeleteMutation} from '../../../../src/redux/actions/db';
import {fetchEntitiesBy} from '../../../../src/util/fetchEntity';

//we still use api/rpc custom function for claim deletion, because we delete the claim_damage as well
// we tried to use CASCADE, but postgreSQL always deletes the first entity (claim)
// before cascade deleting connected entities (claim_damage). This breaks realtime.sql.
export function deleteClaim(authedFetcher: FetcherFunc, claim_id: Uuid): DbThunkAction<Promise<void>> {
  return async dispatch => {
    // delete related claim damages locally and api/rpc/delete_claim will delete them on the server
    const claimDamages = await fetchEntitiesBy(authedFetcher, 'claim_damage', {
      column: 'claim_id',
      operator: 'eq',
      value: claim_id,
    });
    dispatch(_deleteDbEntries({claim_damage: claimDamages.map(claimDamage => claimDamage.claim_damage_id)}));
    dispatch(queueDeleteMutation('claim', claim_id));
  };
}

import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import intersect from '@turf/intersect';
import Flatbush from 'flatbush';
import {convert} from 'geo-coordinates-parser';
import {GeoJSON, MultiPolygon, Polygon, Position} from 'geojson';
import {AreaUnit, AreaValue} from './models/types';
import {convertArea} from './selectors/units';
import {filterNulls} from './util/arr-util';

export type LngLat = [number, number];

export function bBoxAreaSqM(bbox: Bbox) {
  const {
    ne: [e, n],
    sw: [w, s],
  } = bbox;
  return geometryAreaSqM({
    type: 'Polygon',
    coordinates: [
      [
        [n, w],
        [n, e],
        [s, e],
        [s, w],
        [n, w],
      ],
    ],
  });
}

const RADIUS = 6378137;

export function geometryArea(geojson: GeoJSON, unit: AreaUnit): AreaValue {
  const area = convertArea(unit, {val: geometryAreaSqM(geojson) / 10000, unit: 'hectares'})!;
  area.val = Number(area.val.toFixed(2));
  return area;
}

export function geometryAreaSqM(geojson: GeoJSON): number {
  var area = 0,
    i;
  switch (geojson.type) {
    case 'Polygon':
      return polygonAreaSqM(geojson.coordinates);
    case 'MultiPolygon':
      for (i = 0; i < geojson.coordinates.length; i++) {
        area += polygonAreaSqM(geojson.coordinates[i]);
      }
      return area;
    case 'Feature':
      return geometryAreaSqM(geojson.geometry);
    case 'GeometryCollection':
      for (i = 0; i < geojson.geometries.length; i++) {
        area += geometryAreaSqM(geojson.geometries[i]);
      }
      return area;
    case 'Point':
    case 'MultiPoint':
    case 'LineString':
    case 'MultiLineString':
    default:
      return 0;
  }
}

export function polygonAreaSqM(coords: Position[][]) {
  var area = 0;
  if (coords && coords.length > 0) {
    area += Math.abs(ringAreaSqM(coords[0]));
    for (var i = 1; i < coords.length; i++) {
      area -= Math.abs(ringAreaSqM(coords[i]));
    }
  }
  return area;
}

/**
 * Calculate the approximate area of the polygon were it projected onto
 *     the earth.  Note that this area will be positive if ring is oriented
 *     clockwise, otherwise it will be negative.
 *
 * Reference:
 * Robert. G. Chamberlain and William H. Duquette, "Some Algorithms for
 *     Polygons on a Sphere", JPL Publication 07-03, Jet Propulsion
 *     Laboratory, Pasadena, CA, June 2007 http://trs-new.jpl.nasa.gov/dspace/handle/2014/40409
 *
 * Returns:
 * {float} The approximate signed geodesic area of the polygon in square
 *     meters.
 */

function ringAreaSqM(coords: Position[]) {
  var p1,
    p2,
    p3,
    lowerIndex,
    middleIndex,
    upperIndex,
    i,
    area = 0,
    coordsLength = coords.length;

  if (coordsLength > 2) {
    for (i = 0; i < coordsLength; i++) {
      if (i === coordsLength - 2) {
        // i = N-2
        lowerIndex = coordsLength - 2;
        middleIndex = coordsLength - 1;
        upperIndex = 0;
      } else if (i === coordsLength - 1) {
        // i = N-1
        lowerIndex = coordsLength - 1;
        middleIndex = 0;
        upperIndex = 1;
      } else {
        // i = 0 to N-3
        lowerIndex = i;
        middleIndex = i + 1;
        upperIndex = i + 2;
      }
      p1 = coords[lowerIndex];
      p2 = coords[middleIndex];
      p3 = coords[upperIndex];
      area += (rad(p3[0]) - rad(p1[0])) * Math.sin(rad(p2[1]));
    }

    area = (area * RADIUS * RADIUS) / 2;
  }

  return area;
}

function rad(x: number) {
  return (x * Math.PI) / 180;
}

export function toMinMaxBbox(bbox: Bbox): [LngLat, LngLat] {
  return [bbox.sw, bbox.ne];
}

export function toMaxMinBbox(bbox: Bbox): [LngLat, LngLat] {
  return [bbox.ne, bbox.sw];
}

// Take a bbox array of any order (MinMax or MaxMin) and return a bbox object.
export function fromArrBbox(bbox: [LngLat, LngLat]): Bbox {
  const [a, b] = bbox;
  return {ne: [Math.max(a[0], b[0]), Math.max(a[1], b[1])], sw: [Math.min(a[0], b[0]), Math.min(a[1], b[1])]};
}

export function fromLngLatLike(x: LngLat | {lng: number; lat: number} | {lon: number; lat: number}): LngLat {
  if (x instanceof Array) {
    return [x[0], x[1]];
  }

  return ['lng' in x ? x.lng : x.lon, x.lat];
}

export interface Bbox {
  ne: LngLat;
  sw: LngLat;
}

// Returns a bbox whose width and height are extended by `factor`, that is:
// new_width = width * factor
// (new_max - new_min) = width * factor
// (max + x - (min - x)) = width * factor
// 2x + max - min = width * factor
// 2x + width = width * factor
// 2x = width * factor - width
// x = width * (factor - 1) / 2
export function extendBbox(bbox: Bbox, factor: number): Bbox {
  const dLng = bbox.ne[0] - bbox.sw[0],
    dLat = bbox.ne[1] - bbox.sw[1];
  return clampBbox({
    ne: [bbox.ne[0] + (dLng * (factor - 1)) / 2, bbox.ne[1] + (dLat * (factor - 1)) / 2],
    sw: [bbox.sw[0] - (dLng * (factor - 1)) / 2, bbox.sw[1] - (dLat * (factor - 1)) / 2],
  });
}

export function clampBbox(bbox: Bbox): Bbox {
  return {
    ne: [Math.min(Math.max(bbox.ne[0], -179), 179), Math.min(Math.max(bbox.ne[1], -89), 89)],
    sw: [Math.min(Math.max(bbox.sw[0], -179), 179), Math.min(Math.max(bbox.sw[1], -89), 89)],
  };
}

export function getShapeBbox(shape: Position[]): null | Bbox {
  let minLong = undefined,
    minLat = undefined,
    maxLong = undefined,
    maxLat = undefined;

  for (const point of shape) {
    if (minLong === undefined || point[0] < minLong) {
      minLong = point[0];
    }
    if (minLat === undefined || point[1] < minLat) {
      minLat = point[1];
    }
    if (maxLong === undefined || point[0] > maxLong) {
      maxLong = point[0];
    }
    if (maxLat === undefined || point[1] > maxLat) {
      maxLat = point[1];
    }
  }

  if (minLong !== undefined && minLat !== undefined && maxLong !== undefined && maxLat !== undefined) {
    return {sw: [minLong, minLat], ne: [maxLong, maxLat]};
  } else {
    return null;
  }
}

export function getBoundingBox(data: GeoJSON): null | Bbox {
  let bboxes: Bbox[];
  switch (data.type) {
    case 'Feature':
      return getBoundingBox(data.geometry);
    case 'FeatureCollection':
      bboxes = filterNulls(data.features.map(x => getBoundingBox(x)));
      return getShapeBbox(bboxes.map(x => [x.sw, x.ne]).flat(1));
    case 'GeometryCollection':
      bboxes = filterNulls(data.geometries.map(x => getBoundingBox(x)));
      return getShapeBbox(bboxes.map(x => [x.sw, x.ne]).flat(1));
    case 'MultiPoint':
    case 'LineString':
      return getShapeBbox(data.coordinates);
    case 'Polygon':
      return (data.coordinates[0] && getShapeBbox(data.coordinates[0])) || null;
    case 'MultiLineString':
      bboxes = filterNulls(data.coordinates.map(x => getShapeBbox(x)));
      return getShapeBbox(bboxes.map(x => [x.sw, x.ne]).flat(1));
    case 'MultiPolygon':
      bboxes = filterNulls(data.coordinates.map(x => getShapeBbox(x[0])));
      return getShapeBbox(bboxes.map(x => [x.sw, x.ne]).flat(1));
    case 'Point':
      return {sw: data.coordinates as LngLat, ne: data.coordinates as LngLat};
    default:
      return null;
  }
}

// Returns true, if the inner bbox is wholy contained in the outer one.
export function bboxContained(outer: Bbox, inner: Bbox) {
  return bboxContainsPoint(outer, inner.sw) && bboxContainsPoint(outer, inner.ne);
}

// Returns true, if the inner bbox is wholy contained in the outer one.
export function bboxContainsPoint(bbox: Bbox, point: LngLat) {
  return bbox.sw[0] <= point[0] && point[0] <= bbox.ne[0] && bbox.sw[1] <= point[1] && point[1] <= bbox.ne[1];
}

function between(a: number, b: number, c: number) {
  const eps = (c - a) * 0.01;
  return a + eps < b && b < c - eps;
}

function segmentIntersects(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number,
): boolean {
  var x =
    ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) /
    ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
  var y =
    ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) /
    ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
  if (isNaN(x) || isNaN(y)) {
    return false;
  } else {
    if (x1 >= x2) {
      if (!between(x2, x, x1)) {
        return false;
      }
    } else {
      if (!between(x1, x, x2)) {
        return false;
      }
    }
    if (y1 >= y2) {
      if (!between(y2, y, y1)) {
        return false;
      }
    } else {
      if (!between(y1, y, y2)) {
        return false;
      }
    }
    if (x3 >= x4) {
      if (!between(x4, x, x3)) {
        return false;
      }
    } else {
      if (!between(x3, x, x4)) {
        return false;
      }
    }
    if (y3 >= y4) {
      if (!between(y4, y, y3)) {
        return false;
      }
    } else {
      if (!between(y3, y, y4)) {
        return false;
      }
    }
  }

  return true;
}

export function getFirstInvalidPointIndex(points: LngLat[]): null | number {
  const index = new Flatbush(points.length);
  for (let i = 0; i < points.length; ++i) {
    const from = i == 0 ? points[points.length - 1] : points[i - 1];
    const to = points[i];
    index.add(Math.min(from[0], to[0]), Math.min(from[1], to[1]), Math.max(from[0], to[0]), Math.max(from[1], to[1]));
  }
  index.finish();

  for (var i = 0; i < points.length; ++i) {
    const from = i == 0 ? points[points.length - 1] : points[i - 1];
    const to = points[i];
    const ids = index.search(
      Math.min(from[0], to[0]),
      Math.min(from[1], to[1]),
      Math.max(from[0], to[0]),
      Math.max(from[1], to[1]),
    );

    for (const j of ids) {
      if (j <= i) continue; // we have either reported it, or it is current.

      const otherFrom = j == 0 ? points[points.length - 1] : points[j - 1];
      const otherTo = points[j];
      if (segmentIntersects(otherFrom[0], otherFrom[1], otherTo[0], otherTo[1], from[0], from[1], to[0], to[1])) {
        return j;
      }
    }
  }

  return null;
}

// Returns a number in [0, 1] which a measure of how much the two polygons overlap, calculated as the area of their
// intersection divided by the LARGER of the two areas (i.e. the smaller ratio).
export function getOverlapRatio(a: Polygon | MultiPolygon, b: Polygon): null | number {
  try {
    const intersection = intersect(a, b);
    const intersectionArea = intersection ? geometryAreaSqM(intersection) : 0;
    return Math.min(intersectionArea / geometryAreaSqM(a), intersectionArea / geometryAreaSqM(b));
  } catch (e) {
    console.error('getOverlapRatio error:', e);
    return null;
  }
}

export function isPointInPolygon(point: LngLat, polygon: Polygon | MultiPolygon): boolean {
  return booleanPointInPolygon(
    {type: 'Feature', geometry: {type: 'Point', coordinates: point}, properties: {}},
    {type: 'Feature', geometry: polygon, properties: {}},
  );
}

export function parseLatlng(s: string): null | LngLat {
  try {
    const nums = s
      .replace(/\s/g, '')
      .split(/[,]/)
      .map(x => parseFloat(x));
    if (nums.length == 2 && nums.every(x => !isNaN(x))) {
      return [nums[1], nums[0]];
    }
    let converted = convert(s);
    return [converted.decimalLongitude, converted.decimalLatitude];
  } catch (e) {
    console.warn('error:', `parseLatlng(${s}):`, e);
  }

  return null;
}

export function distanceMeters(from: LngLat, to: LngLat) {
  var dLat = rad(to[1] - from[1]);
  var dLon = rad(to[0] - from[0]);
  var lat1 = rad(from[1]);
  var lat2 = rad(to[1]);

  var a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);

  return RADIUS * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}

export function lineStringLengthM(vertices: LngLat[]): number {
  let length = 0;
  let prevVertex;
  for (const vertex of vertices) {
    if (prevVertex) {
      length += distanceMeters(prevVertex, vertex);
    }
    prevVertex = vertex;
  }
  return length;
}

export function bboxToGeoJSONPolygon(bbox: Bbox): GeoJSON.Polygon {
  const {ne, sw} = bbox;
  return {
    type: 'Polygon',
    coordinates: [
      [
        [sw[0], sw[1]],
        [ne[0], sw[1]],
        [ne[0], ne[1]],
        [sw[0], ne[1]],
        [sw[0], sw[1]],
      ],
    ],
  };
}

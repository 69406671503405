import {createSelector} from 'reselect';
import {reportErr} from '../util/err';
import {State} from './index';

// indexedObj can be one of db.farm or db.policy.
export function entitiesToCommonUserGroup(entities: {user_group: string}[]): null | string {
  const userGroups = new Set<string>(entities.map(x => x.user_group));

  if (userGroups.size > 1) {
    const err = 'Entity list selected >1 userGroups! ' + Array.from(userGroups).join(',');
    reportErr(new Error(err));
  }

  return userGroups.size == 0 ? null : Array.from(userGroups)[0];
}

export const getMap = (state: State) => state.map;
export const getLastZoom = createSelector(getMap, x => x.lastZoom);
export const getLastLocation = createSelector(getMap, x => x.lastLocation);

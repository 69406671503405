import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {CROP_COLORS} from '../../../src/constants/colors';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {convertYield, getIdealYieldUnit, getUnitSystem} from '../../../src/selectors/units';
import {ApisContext} from '../apis/ApisContext';
import ListOfValuesCard from './ListOfValuesCard';
import {groupByCrop} from './SamplesByCrop';
import {useStatsByHarvest} from './queries';

export const YieldByCrop: React.FC = () => {
  const apis = useContext(ApisContext),
    crops = useSelector(getCrops),
    units = useSelector(getUnitSystem);
  const {data = [], isFetching} = useStatsByHarvest();
  const yieldByCrop = groupByCrop(apis, data, x => x.estimated_yield_t_ha)
    .filter(x => x[2] != null)
    .map(x => {
      const estimatedYield = convertYield(
        getIdealYieldUnit(units, getBaseCrop(crops, x[0].crop_id)),
        {val: x[2], unit: 'tons-per-hectare'},
        getBaseCrop(crops, x[0].crop_id),
      );
      return {
        color: CROP_COLORS[getBaseCrop(crops, x[0].crop_id) ?? 'unknown'],
        title: x[1],
        value: (estimatedYield && apis.t({type: 'YieldUnit', ...estimatedYield})) ?? '',
        sortValue: x[0].total_area_ha ?? 0,
      };
    })
    .sort((a, b) => b.sortValue - a.sortValue)
    .slice(0, 7);

  return <ListOfValuesCard title={apis.t('CropYieldAverageTitle')} data={yieldByCrop ?? []} loading={isFetching} />;
};

import {Buffer} from 'buffer';
import {
  Feature,
  FeatureCollection,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from 'geojson';
import twkb from 'twkb';

const POINT = 1;
const LINESTRING = 2;
const POLYGON = 3;
const MULTIPOINT = 4;
const MULTILINESTRING = 5;
const MULTIPOLYGON = 6;
const COLLECTION = 7;

class TwkbUnknownType extends Error {}

interface TwkbPoint {
  type: 1;
  coordinates: number[];
}

interface TwkbLineString {
  type: 2;
  coordinates: number[];
}

interface TwkbPolygon {
  type: 3;
  coordinates: number[][];
}

interface TwkbMultiPoint {
  type: 4;
  ids: number[];
  geoms: number[][];
}

interface TwkbMiltiLineString {
  type: 5;
  ids: number[];
  geoms: number[][];
}

interface TwkbMultiPolygon {
  type: 6;
  ids: number[];
  geoms: TwkbPolygon[] | TwkbMultiPolygon[] | number[][][];
}

interface TwkbCollection {
  type: 7;
  ids: number[];
  ndims: 2 | 3 | 4;
  geoms: TwkbGeom[];
}

type TwkbGeom = TwkbPoint | TwkbLineString | TwkbPolygon | TwkbMultiPoint | TwkbMiltiLineString | TwkbMultiPolygon;

// Map TWKB type to correct transformation function from intermediate representation to GeoJSON object
function transform(
  geom: TwkbGeom,
  ndims: 2 | 3 | 4,
): Point | LineString | Polygon | MultiPoint | MultiLineString | MultiPolygon {
  if (geom.type === POINT) {
    return {
      type: 'Point',
      coordinates: toCoords(geom.coordinates, ndims)[0],
    } as Point;
  } else if (geom.type === LINESTRING) {
    return {
      type: 'LineString',
      coordinates: toCoords(geom.coordinates, ndims),
    } as LineString;
  } else if (geom.type === POLYGON) {
    return {
      type: 'Polygon',
      coordinates: geom.coordinates.map(c => toCoords(c, ndims)),
    } as Polygon;
  } else if (geom.type === MULTIPOINT) {
    throw new Error('No support for Multipoints!');
  } else if (geom.type === MULTILINESTRING) {
    throw new Error('No support for Multilinestrings!');
  } else if (geom.type === MULTIPOLYGON) {
    let coordinates: number[][][][] = [];
    for (let i = 0; i < geom.geoms.length; ++i) {
      const subGeom = geom.geoms[i];
      if (subGeom instanceof Array) {
        coordinates.push(subGeom.map(y => toCoords(y, ndims)));
      } else if (subGeom.type === POLYGON) {
        coordinates.push((transform(subGeom, ndims) as Polygon).coordinates);
      } else if (subGeom.type === MULTIPOLYGON) {
        coordinates = [...coordinates, ...(transform(subGeom, ndims) as MultiPolygon).coordinates];
      } else {
        throw new Error('No support for Multipolygons with nested features of a different sort.');
      }
    }
    return {
      type: 'MultiPolygon',
      coordinates,
    };
  } else {
    throw new TwkbUnknownType((geom as any).type);
  }
}

// TWKB flat coordinates to GeoJSON coordinates
function toCoords(coordinates: number[], ndims: 2 | 3 | 4): number[][] {
  const coords = [];
  for (let i = 0, len = coordinates.length; i < len; i += ndims) {
    const pos = [];
    for (let c = 0; c < ndims; ++c) pos.push(coordinates[i + c]);
    coords.push(pos);
  }
  return coords;
}

/**
 * Convert TWKB to a GeoJSON. Note that if the TWKB string contains multiple concatenated geometries, they will
 * be merged into one FeatureCollection. If each of those geometries is a collection itself, those will be merged into
 * one.
 * @param {ArrayBuffer|Buffer} buffer Binary buffer containing TWKB data
 */
export function twkbToGeoJSON(buffer: Buffer): FeatureCollection {
  const twkbs: (TwkbGeom | TwkbCollection)[] = twkb.read(buffer);
  let features: Feature[] = [];
  for (const twkb of twkbs) {
    if (twkb.type === COLLECTION) {
      for (const geom of twkb.geoms) {
        features.push({type: 'Feature', properties: {}, geometry: transform(geom, (geom as any).ndims || 2)});
      }
    } else {
      features.push({type: 'Feature', properties: {}, geometry: transform(twkb, (twkb as any).ndims || 2)});
    }
  }

  return {type: 'FeatureCollection', features};
}

export function layerBundleToGeoJSON(buffer: Buffer): FeatureCollection {
  if (buffer.length <= 4) {
    return {type: 'FeatureCollection', features: []};
  }
  const jsonLen = (buffer[0] << 24) + (buffer[1] << 16) + (buffer[2] << 8) + buffer[3];

  let properties: null | {[name: string]: any}[] = null;
  if (jsonLen !== 0) {
    properties = JSON.parse(buffer.slice(4, jsonLen + 4).toString('utf8'));
    if (!(properties instanceof Array)) {
      throw new Error("Couldn't parse LayerPackage; parsed props were not an array: " + typeof properties);
    }
  }

  const featureCollection = twkbToGeoJSON(buffer.slice(4 + jsonLen));
  if (properties) {
    if (featureCollection.features.length !== properties.length) {
      throw new Error(
        `Couldn't parse LayerBundle; mismatch between ` +
          `props len(${properties.length}) and feature len(${featureCollection.features.length}).`,
      );
    }

    for (let i = 0; i < featureCollection.features.length; ++i) {
      featureCollection.features[i].properties = properties[i];
    }
  }

  return featureCollection;
}

import React, {useEffect, useState} from 'react';

// A hook to determine if a component is visible.
export function useIsVisible(ref: React.RefObject<HTMLElement>): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref]);

  return isIntersecting;
}

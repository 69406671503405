// Given the user's current entry get suggestions for `field`. E.g. if field=policy_number, then we retrieve suggestions
// for policy numbers based on the user's current entry for external_farm_id & farm_name.
// To find these associations, we look in data.suggestion, which can connect farms to policies and vice versa.
import {FetcherFunc} from '../../../src/FetcherFunc';
import {OptionType, SuggestionTextFields} from '../../../src/Formy/Suggestion';
import {I18nFunction} from '../../../src/i18n/i18n';
import {Farm, Policy} from '../../../src/models/interfaces';
import {farmDesc} from '../../../src/text/desc';
import {filterNulls} from '../../../src/util/arr-util';
import {getPostgrestQueryParams} from '../../../src/util/postgrest-query';

export async function getItems(
  t: I18nFunction,
  authedFetcher: FetcherFunc,
  field: SuggestionTextFields,
  q: string,
): Promise<OptionType[]> {
  const path = field == 'policy_number' ? 'api/policy' : 'api/farm';
  const params = getPostgrestQueryParams({
    column: field,
    operator: 'ilike',
    value: '%' + q.replaceAll(' ', '%') + '%',
  });

  if (field == 'policy_number') {
    const res: Policy[] = await authedFetcher({method: 'GET', path, params});
    return res.map(x => ({
      type: 'entity',
      id: x.policy_id,
      text: x[field],
      description: x.policy_number,
    }));
  } else {
    const res: Farm[] = await authedFetcher({method: 'GET', path, params});
    return filterNulls(
      res.map(x =>
        !x[field]
          ? null
          : {
              type: 'entity',
              id: x.farm_id,
              text: x[field]!,
              description: farmDesc(t, x),
            },
      ),
    );
  }
}

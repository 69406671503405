import {I18nParametric, I18nSimpleKey} from '../i18n/i18n';

export function isErrorLike(e: unknown): e is Error {
  return e instanceof Error || (typeof e == 'object' && typeof (e as any).message == 'string');
}

export class I18nError extends Error {
  constructor(public msg: I18nSimpleKey | I18nParametric) {
    super(typeof msg == 'string' ? msg : msg.type);
  }
}

import {GadmLevel, GetMapStatsRow, Regions} from './models/interfaces';
import {aggregateBy} from './util/arr-util';

export type RegionSubset = Pick<Regions, 'gadm_level' | 'name' | 'region_id' | 'region_info'>;

export interface MapStatsWithRegion extends GetMapStatsRow {
  region: RegionSubset | null;
}

export function aggregateMapStats(stats: MapStatsWithRegion[], selectedRegionLevel: GadmLevel): MapStatsWithRegion[] {
  const validStats = stats.filter(
    // Filter out any stats that don't have the selected region level, as this indicates this level is not used
    // in that location, and we cannot aggregate on a non-available region_info.
    x => x.region && x.region.region_info?.some(r => (r?.region_level ?? '6') === selectedRegionLevel),
  );
  const byLeveledRegionId = (x: MapStatsWithRegion) =>
    x.region?.region_info?.find(r => r?.region_level === selectedRegionLevel)?.region_id ?? '-';
  const keyFn = (v: MapStatsWithRegion) => [byLeveledRegionId(v), v.crop_id, v.harvest_year].join('$');
  const aggregatedByRegionId = aggregateBy(validStats, keyFn, v => {
    const regionInfos =
      v[0].region?.region_info?.sort((a, b) => a!.region_level!.localeCompare(b!.region_level!)) ?? [];
    const regionInfoIndex = regionInfos?.findIndex(r => r?.region_level === selectedRegionLevel);
    const regionInfo = regionInfos[regionInfoIndex];
    const region: RegionSubset = {
      gadm_level: selectedRegionLevel,
      name: regionInfo?.region_name ?? '-',
      region_id: regionInfo?.region_id ?? '-',
      region_info: regionInfos.slice(0, regionInfoIndex + 1),
    };
    const sums = v.reduce(
      (acc, x) => {
        acc.num_samples += x.num_samples ?? 0;
        acc.num_fields += x.num_fields ?? 0;
        acc.total_area_ha += x.total_area_ha ?? 0;
        return acc;
      },
      {num_samples: 0, num_fields: 0, total_area_ha: 0},
    );

    const weighted_estimated_yield =
      sums.total_area_ha > 0
        ? v.reduce(
            (acc: {sum: number; count: number; area: number}, x) => {
              if (x.weighted_estimated_yield_t_ha == null || x.total_area_ha == null) {
                return acc;
              }
              return {
                sum: acc.sum + x.weighted_estimated_yield_t_ha * x.total_area_ha,
                count: acc.count + 1,
                area: acc.area + x.total_area_ha,
              };
            },
            {sum: 0, count: 0, area: 0},
          )
        : null;

    const straight_estimated_yield = v.reduce(
      (acc: {sum: number; count: number; area: number}, x) => {
        if (x.straight_estimated_yield_t_ha == null || x.total_area_ha == null) {
          return acc;
        }
        return {
          sum: acc.sum + x.straight_estimated_yield_t_ha,
          count: acc.count + 1,
          area: acc.area + x.total_area_ha,
        };
      },
      {sum: 0, count: 0, area: 0},
    );

    const result: MapStatsWithRegion = {
      ...v[0],
      // Use the region_id from the region_info
      region_id: region.region_id,
      region,
      // Sum aggregations
      ...sums,
      // Weighted average aggregation
      weighted_estimated_yield_t_ha:
        weighted_estimated_yield != null && weighted_estimated_yield.count > 0
          ? weighted_estimated_yield.sum / weighted_estimated_yield.area
          : null,
      // Straight average aggregation
      straight_estimated_yield_t_ha:
        straight_estimated_yield.count > 0 ? straight_estimated_yield.sum / straight_estimated_yield.count : null,
    };
    return result;
  });
  return Object.values(aggregatedByRegionId);
}

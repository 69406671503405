import * as d3 from 'd3';
import React from 'react';
import {I18nFunction} from '../../../src/i18n/i18n';
import {BoxPlotDatum} from './crop-mon-util';

interface BoxPlotProps {
  height: number;
  id: string;
  data: BoxPlotDatum[];
  t: I18nFunction;
}

export function drawBoxPlot(props: BoxPlotProps) {
  // append the svg object to the body of the page
  const element = document.getElementById(props.id);
  if (element) {
    element.innerHTML = '';
  }

  const clientWidth = element?.clientWidth ?? 400;
  const margin = {top: 10, right: 30, bottom: 100, left: 40};
  const width = clientWidth - margin.left - margin.right;
  const height = props.height - margin.top - margin.bottom;

  var svg = d3
    .select('#' + props.id)
    .append('svg')
    .attr('width', '100%')
    .attr('height', props.height)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  var minDate = null,
    maxDate = null;
  for (const datum of props.data) {
    if (!datum.date || isNaN(datum.date.getTime())) {
      continue;
    }

    if (minDate == null || datum.date.getTime() < minDate) {
      minDate = datum.date.getTime();
    }
    if (maxDate == null || datum.date.getTime() > maxDate) {
      maxDate = datum.date.getTime();
    }
  }

  if (!minDate || !maxDate) {
    return;
  }

  minDate -= 30 * 24 * 60 * 60 * 1000;
  const benchmarkDate = new Date(minDate);
  minDate -= 10 * 24 * 60 * 60 * 1000;
  const data: Required<BoxPlotDatum>[] = props.data.map(x => ({
    ...x,
    date: x.date || benchmarkDate,
  }));

  var tickFormat = (x: Date) =>
    x.getTime() == benchmarkDate.getTime() ? props.t('Benchmark') : d3.timeFormat('%d-%b-%Y')(x);
  const tickValues = [benchmarkDate];
  var dateIterator = new Date(benchmarkDate);
  dateIterator.setUTCDate(1);
  while (dateIterator.getTime() < maxDate) {
    dateIterator.setUTCMonth(dateIterator.getUTCMonth() + 1);
    tickValues.push(new Date(dateIterator));
  }

  var x = d3
    .scaleTime()
    .range([0, width])
    .domain([new Date(minDate), new Date(maxDate)]);
  svg
    .append('g')
    .attr('transform', 'translate(0,' + height + ')')
    .call(d3.axisBottom<Date>(x).tickFormat(tickFormat).tickValues(tickValues))
    .selectAll('text')
    .style('text-anchor', 'end')
    .attr('dx', '-.8em')
    .attr('dy', '.15em')
    .attr('transform', 'rotate(-65)');

  // Show the Y scale
  var y = d3
    .scaleLinear()
    .domain([d3.min(data.map(x => x.min))! * 0.8, 1.1 * d3.max(data.map(x => x.max))!])
    .range([height, 0]);
  svg.append('g').call(d3.axisLeft(y));

  // Show the main vertical line
  svg
    .selectAll('vertLines')
    .data(data)
    .enter()
    .append('line')
    .attr('x1', function (d) {
      return x(d.date);
    })
    .attr('x2', function (d) {
      return x(d.date);
    })
    .attr('y1', function (d) {
      return y(d.min);
    })
    .attr('y2', function (d) {
      return y(d.max);
    })
    .attr('stroke', 'black')
    .style('width', 40);

  // rectangle for the main box
  var boxWidth = 5;
  svg
    .selectAll('boxes')
    .data(data)
    .enter()
    .append('rect')
    .attr('x', function (d) {
      return x(d.date) - boxWidth / 2;
    })
    .attr('y', function (d) {
      return y(d.q3);
    })
    .attr('height', function (d) {
      return y(d.q1) - y(d.q3);
    })
    .attr('width', boxWidth)
    .attr('stroke', 'black')
    .style('fill', '#69b3a2');

  // Show the median
  svg
    .selectAll('medianLines')
    .data(data)
    .enter()
    .append('line')
    .attr('x1', function (d) {
      return x(d.date) - boxWidth / 2;
    })
    .attr('x2', function (d) {
      return x(d.date) + boxWidth / 2;
    })
    .attr('y1', function (d) {
      return y(d.median);
    })
    .attr('y2', function (d) {
      return y(d.median);
    })
    .attr('stroke', 'black')
    .style('width', 80);
}

class BoxPlot extends React.PureComponent<BoxPlotProps> {
  componentDidMount() {
    drawBoxPlot(this.props);
  }

  componentDidUpdate() {
    drawBoxPlot(this.props);
  }

  render() {
    return <div id={this.props.id} />;
  }
}

export default BoxPlot;

import {UseQueryResult, useQuery} from '@tanstack/react-query';
import React from 'react';
import {useSelector} from 'react-redux';
import {
  GetSampleCountsByAdjusterRow,
  GetSampleRowsRow,
  GetStatsByCropCondRow,
  GetStatsByHarvestRow,
  getSampleCountsByAdjuster,
  getSampleRows,
  getStatsByCropCond,
  getStatsByHarvest,
} from '../../../src/models/interfaces';
import {DbFilterState} from '../../../src/redux/reducers/filters';
import {filtersToRequest} from '../../../src/util/req-util';
import {ApisContext} from '../apis/ApisContext';
import {State} from '../redux';

interface CustomUseQueryParams {
  enabled?: boolean;
}

export const useSampleCountsByAdjuster: (
  options?: CustomUseQueryParams,
) => UseQueryResult<GetSampleCountsByAdjusterRow[]> = ({enabled = true} = {}) => {
  const {authedFetcher} = React.useContext(ApisContext);
  const filters = useSelector<State, DbFilterState>(state => state.filters);
  return useQuery(
    ['dashboard', 'getSampleCountsByAdjuster', filters],
    () => getSampleCountsByAdjuster(authedFetcher, filtersToRequest(filters)),
    {keepPreviousData: true, enabled},
  );
};

export const useStatsByHarvest: (options?: CustomUseQueryParams) => UseQueryResult<GetStatsByHarvestRow[]> = ({
  enabled = true,
}: CustomUseQueryParams = {}) => {
  const {authedFetcher} = React.useContext(ApisContext);
  const filters = useSelector<State, DbFilterState>(state => state.filters);
  return useQuery(
    ['dashboard', 'get_stats_by_harvest', filters],
    () => getStatsByHarvest(authedFetcher, filtersToRequest(filters)),
    {keepPreviousData: true, enabled},
  );
};

export const useStatsByCondition: (options?: CustomUseQueryParams) => UseQueryResult<GetStatsByCropCondRow[]> = ({
  enabled = true,
}: CustomUseQueryParams = {}) => {
  const {authedFetcher} = React.useContext(ApisContext);
  const filters = useSelector<State, DbFilterState>(state => state.filters);
  return useQuery(
    ['dashboard', 'get_stats_by_crop_cond', filters],
    () => getStatsByCropCond(authedFetcher, filtersToRequest(filters)),
    {keepPreviousData: true, enabled},
  );
};

export const useSampleRows: (options?: CustomUseQueryParams) => UseQueryResult<GetSampleRowsRow[]> = ({
  enabled = true,
}: CustomUseQueryParams = {}) => {
  const {authedFetcher} = React.useContext(ApisContext);
  const filters = useSelector<State, DbFilterState>(state => state.filters);
  return useQuery(
    ['dashboard', 'sample', filters],
    () => getSampleRows(authedFetcher, {...filtersToRequest(filters), ordering: 'added_on-desc', row_count: 100}),
    {keepPreviousData: true, enabled},
  );
};

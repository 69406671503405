import React from 'react';
import ReactTable, {Column, RowInfo} from 'react-table';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import cmp from '../../../src/util/cmp';
import {Apis} from '../apis/Apis';
import './ListView.css';

type ListViewProps<D> = {
  apis: Apis;
  data: D[];
  columns: Column<D>[];
  loading: boolean;
  filtered?: any;
  name: I18nSimpleKey;
  getRowInfo?: (row: D) => JSX.Element;
};

export const ExpanderCol = {
  expander: true,
  width: 40,
  Expander: ({isExpanded}: {isExpanded: boolean}) => (
    <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>
  ),
  style: {
    cursor: 'pointer',
    fontSize: 25,
    padding: '0',
    textAlign: 'center',
    userSelect: 'none',
  },
};

function sortMethod(a: any, b: any) {
  // Nulls last.
  if (a == null && b == null) {
    return 0;
  } else if (a == null) {
    return -1;
  } else if (b == null) {
    return 1;
  }

  if (typeof a == 'number' && typeof b == 'number') {
    return b - a;
  }

  return cmp(String(a), String(b));
}

class ListView<D> extends React.Component<ListViewProps<D>> {
  getSubComponent = (rowInfo: RowInfo) => {
    return this.props.getRowInfo && this.props.getRowInfo(rowInfo.original);
  };

  render() {
    let {
      apis: {t},
      data,
      columns,
      loading,
      filtered,
    } = this.props;
    return (
      <div className="List">
        <ReactTable<D>
          columns={columns}
          data={data || []}
          loading={loading}
          filtered={filtered}
          className="-striped -highlight"
          defaultPageSize={50}
          previousText={t('Previous')}
          nextText={t('Next')}
          loadingText={t('Loading')}
          noDataText={t('TableNoRowsFound')}
          pageText={t('TablePage')}
          ofText={t('TableOf')}
          rowsText={t('TableRows')}
          defaultSortMethod={sortMethod}
          SubComponent={this.props.getRowInfo && this.getSubComponent}
        />
      </div>
    );
  }
}

export default ListView;

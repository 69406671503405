import {useQuery} from '@tanstack/react-query';
import {Select, Spin} from 'antd';
import {LngLat} from 'mapbox-gl';
import React, {useCallback, useContext, useState} from 'react';
import {CommonApis} from '../../../src/CommonApis';
import {FormyComponent} from '../../../src/Formy/FormyComponent';
import {FormyEntitySelectorFilter, FormyEntitySelectorProps} from '../../../src/Formy/FormyEntitySelectorBase';
import {debounce} from '../../../src/debounce';
import {fetchEntity} from '../../../src/util/fetchEntity';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import {useSearchEntity, useSelectedValues} from '../queries/options';

export type InnerPickerOwnProps = {
  enabled: boolean;
  entityType: 'farm' | 'policy';
  apis: CommonApis;
  field: string;
  entity_id: null | string;
  filter: FormyEntitySelectorFilter;
  error: boolean;
  onChange: (value: null | string) => void;
  onBlur: () => void;
  sortBy?: (a_entity_id: string, b_entity_id: string) => number;
  location: null | LngLat;
};

export function InnerFormyEntitySelector({error, onChange, entity_id, entityType}: InnerPickerOwnProps) {
  const {t, authedFetcher} = useContext(ApisContext);
  const [query, setQuery] = useState<string>('');
  const {isFetching, data: options} = useSearchEntity(entityType, query, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce(setQuery, 250), []);
  const {data: farm} = useQuery(['farm', entity_id], () =>
    entityType === 'farm' && entity_id ? fetchEntity(authedFetcher, 'farm', entity_id) : null,
  );
  const selectedValue = useSelectedValues(options, entityType, entity_id ? [entity_id] : []);
  return (
    <span>
      <Select
        data-testid={`${entityType}-entity-selector`}
        className={`formy-select-style ${error ? 'formy-item-error' : ''}`}
        onChange={onChange}
        loading={isFetching}
        onSearch={handleSearch}
        filterOption={false}
        showSearch
        allowClear
        placeholder={t(entityType === 'farm' ? 'SelectFarm' : 'SelectPolicy')}
        options={options}
        notFoundContent={isFetching ? <Spin size="small" /> : null}
        value={selectedValue[0] as any} // Even though the antd type says it can't be a string, this works.
      />
      {farm?.user_group && (
        <span className="formy-portfolio-label">{t({type: 'CurPortfolio', user_group: farm.user_group})}</span>
      )}
    </span>
  );
}

interface FormyInnerSelectorProps<F extends {[P in Fk]: null | string}, Fk extends keyof F>
  extends FormyEntitySelectorProps<F, Fk> {
  entityType: 'farm' | 'policy';
}

export default class FormyEntitySelector<
  F extends {[P in Fk]: null | string},
  Fk extends keyof F,
> extends FormyComponent<F, Fk, FormyInnerSelectorProps<F, Fk>> {
  static contextType = ApisContext;
  context!: Apis;

  onChange = (value: null | string) => {
    if (value === 'select') {
      this.handleChange(null as F[Fk]);
      this.handleBlur();
    } else if (value === 'add') {
      this.props.onNewEntity &&
        this.props.onNewEntity(this.props.formy).then(newValue => {
          this.handleChange(newValue as F[Fk]);
          this.handleBlur();
        });
    } else {
      this.handleChange(value as F[Fk]);
      this.handleBlur();
    }
  };

  render() {
    let {entityType, field, filter} = this.props;
    if (entityType !== 'farm' && entityType !== 'policy') {
      return null;
    }
    return (
      <InnerFormyEntitySelector
        enabled={this.mode !== 'view'}
        entityType={entityType}
        apis={this.context}
        field={field as string}
        entity_id={this.value}
        error={this.error}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        filter={filter || null}
        sortBy={this.props.sortBy}
        location={null}
      />
    );
  }
}

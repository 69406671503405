import React from 'react';
import {FormyComponent} from '../../../src/Formy/FormyComponent';
import {ReportConfig} from '../../../src/forms/ReportConfig';
import {FormyCheckbox} from './FormyCheckbox';

export class FormyFarmerEmail extends FormyComponent<ReportConfig, 'farmer_email'> {
  render() {
    if (!this.value) {
      return null;
    }

    return (
      <FormyCheckbox
        formy={this.props.formy}
        field="sendToFarmer"
        label={() => this.value ?? ''}
        labelPosition={'left'}
      />
    );
  }
}

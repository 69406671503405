import {Polygon} from 'geojson';
import {FetcherFunc} from '../FetcherFunc';
import {Field} from '../models/interfaces';
import {SqlTransportFormat, transportToPolygon} from '../models/serialization';

export async function fetchFieldShapes(
  authedFetcher: FetcherFunc,
  filter: [string, string][],
): Promise<Record<string, undefined | Polygon>> {
  const select = ['field_id', 'field_shape'] as const;
  const params: [string, string][] = [['select', select.join(',')], ...filter];
  const shapesArr: SqlTransportFormat<Pick<Field, (typeof select)[number]>>[] = await authedFetcher({
    method: 'GET',
    path: 'api/field',
    params,
  });
  const shapes: Record<string, undefined | Polygon> = {};
  for (const {field_id, field_shape} of shapesArr) {
    if (field_shape) {
      shapes[field_id] = transportToPolygon(field_shape);
    }
  }

  return shapes;
}

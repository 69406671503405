import React from 'react';
import {ClockI} from '../../../src/Clock';
import {CommonViewStyle} from '../../../src/components/CommonViewStyle';
import {I18nFunction} from '../../../src/i18n/i18n';
import {HarvestCustomColumns} from '../../../src/models/CustomColumns';
import {TelepacReportPackage} from '../../../src/report/report-types';
import {getReportStyling} from '../../../src/report/report-util';
import {getBaseCrop} from '../../../src/selectors/crops';
import {convertArea} from '../../../src/selectors/units';
import {cropDesc} from '../../../src/text/desc';
import {filterFalsy, unique} from '../../../src/util/arr-util';
import cmp from '../../../src/util/cmp';
import {ReportFooter, ReportHeader} from './FarmReport';
import './Report.css';

const leftStyle: CommonViewStyle = {textAlign: 'left', paddingLeft: 5};
const centerStyle: CommonViewStyle = {textAlign: 'center'};
const rightStyle: CommonViewStyle = {textAlign: 'right', paddingRight: 5};
const leftNoWrapStyle = {...leftStyle, whiteSpace: 'nowrap'} as const;

export interface TelepacReportApis {
  clock: ClockI;
  t: I18nFunction;
}

interface TableRow {
  crop: string;
  span: number | null;
  existingArea: number | null;
  pacCode: string;
  pacArea: number | null;
  importedArea: number | null;
}

function getPacCode(
  codeCulture: string | null | undefined,
  precision: string | null | undefined,
  semences: boolean | null | undefined,
): string {
  return (codeCulture || '') + (precision ? '-' + precision : '') + (semences ? '-semences' : '');
}

export function TelepacReport(props: TelepacReportPackage & TelepacReportApis) {
  const {t, clock} = props;
  const {farm, harvest_year, policy_numbers} = props;

  function renderFarm(farm: TelepacReportPackage['farm']) {
    const importedAreas: Record<string, number> = {};
    for (const field of farm.fields) {
      if (!field.field_area) {
        continue;
      }
      for (const harvest of field.harvests) {
        const cc = new HarvestCustomColumns(harvest.custom_columns);
        if (cc.telepacData) {
          const {codeCulture, precision, semences} = cc.telepacData;
          const pacCode = getPacCode(codeCulture, precision, semences);
          importedAreas[pacCode] =
            (importedAreas[pacCode] || 0) + (convertArea('hectares', field.field_area)?.val || 0.0);
        }
      }
    }

    const hasGrapes = props.contents.some(x => getBaseCrop(props.crops, x.crop_id) == 'grapes');

    function renderArea(val: number | null) {
      if (val == null) {
        return '-';
      }
      return val.toFixed(hasGrapes ? 4 : 2);
    }

    const cropIds = unique([
      ...filterFalsy(props.contents.map(x => x.crop_id)),
      ...props.existing.map(x => x.crop_id || 'unknown'),
    ]);
    const rows: TableRow[] = [];
    for (const crop_id of cropIds) {
      const existingArea = filterFalsy(
        props.existing.filter(x => x.crop_id == crop_id).map(x => convertArea('hectares', x.area)?.val),
      ).reduce((a, b) => a + b, 0);

      const pacCodes = unique(
        props.contents.filter(x => x.crop_id == crop_id).map(x => getPacCode(x.codeCulture, x.precision, x.semences)),
        'asc',
      );
      const crop = cropDesc(t, props.crops, crop_id);

      if (pacCodes.length > 0) {
        for (const pacCode of pacCodes) {
          const pacArea = filterFalsy(
            // Note: (x as any).area is in case this code somehow gets called with older harvests, before I unified the object structure.
            props.contents
              .filter(x => getPacCode(x.codeCulture, x.precision, x.semences) == pacCode)
              .map(x => x.surfaceAdmissible ?? (x as any).area),
          ).reduce((a, b) => a + b, 0);
          const importedArea = importedAreas[pacCode];
          const span = pacCode == pacCodes[0] ? pacCodes.length : null;
          rows.push({span, crop, existingArea: span ? existingArea : 0, pacCode, pacArea, importedArea});
        }
      } else {
        rows.push({span: 1, crop, existingArea, pacCode: '-', pacArea: null, importedArea: null});
      }
    }
    rows.sort((a, b) => cmp(a.crop, b.crop));

    const unmappedContents = props.contents.filter(x => !x.crop_id);
    const pacCodes = unique(
      unmappedContents.map(x => getPacCode(x.codeCulture, x.precision, x.semences)),
      'asc',
    );
    for (const pacCode of pacCodes) {
      const pacArea = filterFalsy(
        // Note: (x as any).area is in case this code somehow gets called with older harvests, before I unified the object structure.
        unmappedContents
          .filter(x => getPacCode(x.codeCulture, x.precision, x.semences) == pacCode)
          .map(x => x.surfaceAdmissible ?? (x as any).area),
      ).reduce((a, b) => a + b, 0);
      const crop = 'Cultures non-importées';
      const span = pacCode == pacCodes[0] ? pacCodes.length : null;
      rows.push({span, crop, existingArea: null, pacCode: pacCode, pacArea, importedArea: null});
    }

    const totalExistingArea = filterFalsy(rows.map(x => x.existingArea)).reduce((a, b) => a + b, 0);
    const totalPacArea = filterFalsy(rows.map(x => x.pacArea)).reduce((a, b) => a + b, 0);
    const totalImportedArea = filterFalsy(rows.map(x => x.importedArea)).reduce((a, b) => a + b, 0);

    return (
      <div>
        <br />
        <table style={{width: '100%'}}>
          <thead>
            <tr>
              <th className="pac-report" style={leftStyle}>
                Libellé de la culture
              </th>
              <th className="pac-report" style={rightStyle}>
                Surface existante
              </th>
              <th className="pac-report" style={leftStyle}>
                Code PAC
              </th>
              <th className="pac-report" style={centerStyle}>
                Surface {props.areaType == 'admissible' ? 'admissible' : 'graphique'} (PAC)
              </th>
              <th className="pac-report" style={rightStyle}>
                Surface ajoutée
              </th>
              <th className="pac-report" style={rightStyle}>
                Différence
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <tr>
                {row.span && (
                  <td className="pac-report" style={leftNoWrapStyle} rowSpan={row.span}>
                    {row.crop}
                  </td>
                )}
                {row.span && (
                  <td className="pac-report" style={rightStyle} rowSpan={row.span}>
                    {renderArea(row.existingArea)}
                  </td>
                )}
                <td className="pac-report" style={leftNoWrapStyle}>
                  {row.pacCode}
                </td>
                <td className="pac-report" style={rightStyle}>
                  {renderArea(row.pacArea)}
                </td>
                <td className="pac-report" style={rightStyle}>
                  {renderArea(row.importedArea)}
                </td>
                <td className="pac-report" style={rightStyle}>
                  {renderArea((row.existingArea || 0) + (row.importedArea || 0) - (row.pacArea || 0))}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th className="pac-report" style={leftStyle}>
                {t('TotalCultivatedArea')}
              </th>
              <th className="pac-report" style={rightStyle}>
                {renderArea(totalExistingArea)}
              </th>
              <th className="pac-report" style={leftStyle}></th>
              <th className="pac-report" style={rightStyle}>
                {renderArea(totalPacArea)}
              </th>
              <th className="pac-report" style={rightStyle}>
                {renderArea(totalImportedArea)}
              </th>
              <th className="pac-report" style={rightStyle}>
                {renderArea(totalExistingArea + totalImportedArea - totalPacArea)}
              </th>
            </tr>
          </tfoot>
        </table>
        <br />
      </div>
    );
  }

  const {color, logo} = getReportStyling(props.user_group || '');
  return (
    <div>
      <div className="gt-report-container" style={{backgroundColor: 'white'}}>
        <ReportHeader
          type="TelepacReport"
          t={t}
          logo={logo}
          color={color}
          clock={clock}
          title={farm.farm_name || '-'}
        />
        <div className="gt-report-card" style={{margin: '1em'}}>
          <b>Année de récolte PAC: </b>
          {harvest_year || '-'}
          <br />
          {policy_numbers.length > 0 && (
            <span>
              <b>Contrat(s): </b>
              {policy_numbers.join(' / ')}
            </span>
          )}
        </div>
        <div style={{margin: '1em'}}>{renderFarm(props.farm)}</div>
        <ReportFooter color={color} />
      </div>
    </div>
  );
}

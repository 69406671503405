import React, {ChangeEvent} from 'react';
import {FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {useFormyMode, useFormyValue} from '../../../src/Formy/hooks';
import {CommonViewStyle} from '../../../src/components/CommonViewStyle';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {useApis} from '../apis/ApisContext';

interface FormyBoolProps<F, Fk extends keyof F> extends FormyComponentProps<F, Fk> {
  selectMsg: I18nSimpleKey | null;
  style?: CommonViewStyle;
  label?: I18nSimpleKey;
}

export const FormyBool = <F extends {[P in Fk]: null | boolean}, Fk extends keyof F>(props: FormyBoolProps<F, Fk>) => {
  const {t} = useApis();
  const mode = useFormyMode(props.formy);
  const value = useFormyValue(props.formy, props.field);

  const onChange = (x: ChangeEvent<HTMLSelectElement>) => {
    const valueMap: Record<string, null | boolean> = {
      null: null,
      yes: true,
      no: false,
    };
    props.formy.getChangeHandler(props.field)(valueMap[x.target.value] as F[Fk]);
    props.formy.getBlurHandler(props.field)();
  };
  return (
    <>
      {props.label && <label className={'formy-checkbox-label'}>{t(props.label)}</label>}
      <select
        data-testid={`FormyBool-${String(props.field)}`}
        className={`formy-item-style`}
        disabled={mode == 'view'}
        placeholder={t(props.selectMsg || '-')}
        value={value === null || value === undefined ? 'null' : value ? 'yes' : 'no'}
        onChange={onChange}
        style={props.style}
        onBlur={props.formy.getBlurHandler(props.field)}>
        <option key="select" value="null">
          {t('-')}
        </option>
        <option key="yes" value="yes">
          {t('Yes')}
        </option>
        <option key="no" value="no">
          {t('No')}
        </option>
      </select>
    </>
  );
};

import {
  AreaValue,
  CropCondition,
  DensityValue,
  DistanceValue,
  HarvestYear,
  LayerType,
  UnitPriceValue,
  WeightValue,
  YieldValue,
} from './interfaces';

export {AreaUnit, DensityUnit, YieldUnit} from './interfaces';

export type {
  AreaValue,
  Cost,
  DensityValue,
  DistanceValue,
  Loss,
  SampleImage,
  UnitPriceValue,
  WeightValue,
  YieldValue,
} from './interfaces';

export type ValueUnit = YieldValue | AreaValue | DensityValue | WeightValue | DistanceValue | UnitPriceValue;

export function strToLayerType(s: string): null | LayerType {
  return LayerType.includes(s as LayerType) ? (s as LayerType) : null;
}

export function strToHarvestYear(s: string): null | HarvestYear {
  return HarvestYear.includes(s as HarvestYear) ? (s as HarvestYear) : null;
}

export type DbFields = {
  added_on: string;
  added_by: string;
  updated_at: string;
};

export type OmitDbColumns<T> = Omit<T, keyof DbFields>;

export const OrderedCropCondition = [
  'very_good',
  'good',
  'normal',
  'poor',
  'very_poor',
  'harvested',
  'not_planted',
] as const;
type CropConditionCheck = CropCondition extends (typeof OrderedCropCondition)[number] ? null : never;
// noinspection JSUnusedLocalSymbols
const cropConditionCheck: CropConditionCheck = null;

export type Uuid = string;

export type NumRange = Range<number>;
export type DateRange = Range<string>;

export interface Range<T> {
  begin: T;
  end: T;
  bounds: string;
}

import {LeftOutlined, PlusOutlined, SaveOutlined, UndoOutlined} from '@ant-design/icons';
import {Alert, Button} from 'antd';
import React, {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Formy} from '../../../src/Formy/Formy';
import {ImportedData, ImportedFarm} from '../../../src/gt-pack/gt-pack';
import {getCrops} from '../../../src/selectors/crops';
import {FormySubmit} from '../Formy/FormySubmit';
import {useApis} from '../apis/ApisContext';
import SpinningDots from '../components/SpinningDots';
import {AllFieldsForm} from '../import/EntityForms';
import {saveImportedData} from '../import/save';
import {validateImportedData} from '../import/validators';
import {ErrorBoundary} from '../util/ErrorBoundary';
import './add-farms.css';

interface AddFarmsState {
  //
  mode: 'form' | 'saving' | 'saved' | 'error';
  error?: Error;
}

const AddFarms: React.FC = () => {
  const context = useApis();
  const crops = useSelector(getCrops);
  const history = useHistory();
  const [internal, setInternal] = useState<AddFarmsState>({
    mode: 'form',
  });

  const formy = useMemo(() => {
    const formy = new Formy<ImportedData>(
      'new',
      new ImportedData(null),
      context.t,
      async (data: ImportedData) => {
        setInternal({
          mode: 'saving',
        });
        try {
          await saveImportedData(context, data, false);
          // Resetting the form is tricky (throws an Error, or does not reset at all),
          // therefore we just show a success message.
          setInternal({
            mode: 'saved',
          });
        } catch (e) {
          setInternal({
            mode: 'error',
            error: e as Error,
          });
        }
      },
      x => validateImportedData(crops, x),
    );
    formy.getChangeHandler('farms')([new ImportedFarm(null)]);
    return formy;
  }, [context, crops]);

  const addFarm = useCallback(() => {
    formy.getChangeHandler('farms')([...formy.getValue('farms'), new ImportedFarm(null)]);
  }, [formy]);

  const reset = useCallback(() => {
    formy.getChangeHandler('farms')([new ImportedFarm(null)]);
  }, [formy]);

  if (internal.mode === 'saved') {
    return (
      <div className="add-farms add-farms-saved">
        <Button type="link" icon={<LeftOutlined />} onClick={() => history.goBack()}>
          {context.t('Back')}
        </Button>
        <h1>{context.t('FormSubmitted')}</h1>
      </div>
    );
  } else if (internal.mode === 'error') {
    return <div className="add-farms">{context.t('UnknownErrorOccurred')}</div>;
  } else {
    return (
      <div className="add-farms">
        <ErrorBoundary>
          {internal.mode === 'saving' && (
            <div className="add-farms-loading">
              <SpinningDots size={40} />
              <span>{context.t('Saving')}...</span>
            </div>
          )}
          <div className="add-farms-info">
            <Alert showIcon type="info" message={context.t('AddFarmInfo')} closable />
          </div>
          <AllFieldsForm formy={formy} field="farms" noSaveAsNew={true} />
          <div className="add-farms-buttons">
            <Button type="default" icon={<PlusOutlined />} onClick={addFarm}>
              {context.t('AddFarm')}
            </Button>
            <Button type="default" icon={<UndoOutlined />} onClick={reset}>
              {context.t('Reset')}
            </Button>
            <FormySubmit
              type="primary"
              icon={<SaveOutlined />}
              formy={formy}
              label="Save"
              doNotConfirmSubmissionToUser={true}
            />
          </div>
        </ErrorBoundary>
      </div>
    );
  }
};

export default AddFarms;

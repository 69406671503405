import {jwtDecode} from 'jwt-decode';
import {GetAuthToken} from './CommonApis';
import {DbActionTypes} from './redux/actions/db';
import {DbMetaState} from './redux/reducers/db';
import {ThunkStore} from './redux/types';

export async function initCurUser(getAuthToken: GetAuthToken, store: ThunkStore<{dbMeta: DbMetaState}, DbActionTypes>) {
  if (!store.getState().dbMeta.curUser) {
    const claims = jwtDecode<any>(await getAuthToken());
    if (!claims || !claims.email) {
      throw new Error(`Invalid claims from auth: ${JSON.stringify(claims)}`);
    }
    store.dispatch({type: 'UPDATE_USER', email: claims.email, name: claims.name});
  }
}

import {Polygon, Position} from 'geojson';
import {fastDeepEqual} from './fast-deep-equal';
import {LngLat} from './geo';
import {
  CropCondition,
  DistanceUnit,
  HarvestCrop,
  HarvestSeason,
  HarvestYear,
  LossCause,
  VegetationStage,
  WeightUnit,
} from './models/interfaces';
import {
  AreaUnit,
  AreaValue,
  DensityUnit,
  DensityValue,
  DistanceValue,
  WeightValue,
  YieldUnit,
  YieldValue,
} from './models/types';

export function isObject(x: unknown): x is Record<string, any> {
  return typeof x == 'object';
}

/**
 * Implementation is based on https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.6
 * Attention: The implementation is incomplete, as we for example do not check for intersections of inner and outer
 * rings or intersecting lines in the linear rings.
 */
export function isGeojsonPolygon(x: unknown): x is null | Polygon {
  return (
    x === null ||
    (isObject(x) &&
      x.type === 'Polygon' &&
      // We need at least one linear ring (the outer).
      x.coordinates.length > 0 &&
      x.coordinates.every(isLinearRing))
  );
}

/**
 * Check if the provided Positions constitute a linear ring as per RFC 7946.
 * Implementation is based on https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.6
 * This implementation ignores the right-hand-rule for backwards compatibility as advised by RFC 7946.
 * Attention: This implementation is incomplete, as we for example do not check for intersecting lines within the ring.
 */
function isLinearRing(positions: Position[]): boolean {
  // A linear ring has four or more positions.
  return (
    positions.length >= 4 &&
    positions.every(isLocation) &&
    // And the start and end positions are equal.
    fastDeepEqual(positions[0], positions[positions.length - 1])
  );
}

export function isAreaValue(x: unknown): x is null | AreaValue {
  // noinspection SuspiciousTypeOfGuard
  return x === null || (isObject(x) && typeof x.val == 'number' && x.val >= 0 && AreaUnit.includes(x.unit));
}

export function isYieldValue(x: unknown): x is null | YieldValue {
  // noinspection SuspiciousTypeOfGuard
  return (
    x === null ||
    (isObject(x) && typeof x.val == 'number' && isFinite(x.val) && x.val >= 0 && YieldUnit.includes(x.unit))
  );
}

export function isDensityValue(x: unknown): x is null | DensityValue {
  // noinspection SuspiciousTypeOfGuard
  return x === null || (isObject(x) && typeof x.val == 'number' && x.val >= 0 && DensityUnit.includes(x.unit));
}
export function isWeightValue(x: unknown): x is null | WeightValue {
  // noinspection SuspiciousTypeOfGuard
  return x === null || (isObject(x) && typeof x.val == 'number' && x.val >= 0 && WeightUnit.includes(x.unit));
}

export function isDistanceValue(x: unknown): x is null | DistanceValue {
  // noinspection SuspiciousTypeOfGuard
  return x === null || (isObject(x) && typeof x.val == 'number' && x.val >= 0 && DistanceUnit.includes(x.unit));
}

export function isLocation(x: unknown): x is null | LngLat {
  // noinspection SuspiciousTypeOfGuard
  return x === null || (x instanceof Array && x.length == 2 && typeof x[0] == 'number' && typeof x[1] == 'number');
}

export function isDateStr(x: unknown): x is null | string {
  // noinspection SuspiciousTypeOfGuard
  return x === null || (typeof x == 'string' && !!x.match(/^\d\d\d\d-\d\d-\d\d$/));
}

export function isCropCondition(x: unknown): x is null | CropCondition {
  return x === null || CropCondition.includes(x as any);
}

export function isLossCause(x: unknown): x is null | LossCause {
  return x === null || LossCause.includes(x as any);
}

export function isVegetationStage(x: unknown): x is null | VegetationStage {
  return x === null || VegetationStage.includes(x as any);
}

export function isHarvestCrop(x: unknown): x is null | HarvestCrop {
  return x === null || HarvestCrop.includes(x as any);
}

export function isHarvestSeason(x: unknown): x is null | HarvestSeason {
  return x === null || HarvestSeason.includes(x as any);
}

export function isHarvestYear(x: unknown): x is null | HarvestYear {
  return x === null || HarvestYear.includes(x as any);
}

export function isNum(x: unknown, min?: number, max?: number): x is null | number {
  return (
    (x === null && min === undefined && max === undefined) ||
    (typeof x == 'number' && (min === undefined || x >= min) && (max === undefined || x <= max))
  );
}

export function isBool(x: unknown): x is null | boolean {
  return x === null || typeof x == 'boolean';
}

export function isUuid(x: unknown): x is null | string {
  return (
    x === null || (typeof x == 'string' && !!x.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i))
  );
}

export function isArr(x: unknown, minLen?: number): x is null | any[] {
  return (x === null && minLen === undefined) || (Array.isArray(x) && (minLen === undefined || x.length >= minLen));
}

export function isStr(x: unknown, minLen?: number): x is null | string {
  return (x === null && minLen === undefined) || (typeof x == 'string' && (minLen === undefined || x.length >= minLen));
}

export function isEmail(x: unknown): x is null | string {
  return (
    x === null ||
    (typeof x == 'string' && !!x.match(/^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+\.[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i))
  );
}

import {Button} from 'antd';
import React, {ReactNode} from 'react';
import {FormyI} from '../../../src/Formy';
import {Formy} from '../../../src/Formy/Formy';
import {useFormyValue} from '../../../src/Formy/hooks';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import './Formy.css';

// Render a formy array section as a table.
// This expects the array to contain objects.
export const FormyTable = <A extends {}, F extends {[P in Fk]: A[]}, Fk extends keyof F>({
  formy,
  field,
  renderRowCells,
  iconAdd,
  iconRemove,
  labelAdd,
  labelRemove,
  defaultValue,
  headers,
}: {
  formy: Formy<F>;
  field: Fk;
  renderRowCells: (formy: FormyI<A>) => ReactNode[];
  iconAdd?: ReactNode;
  iconRemove?: ReactNode;
  labelAdd?: I18nSimpleKey;
  labelRemove?: I18nSimpleKey;
  defaultValue: (values: A[]) => A;
  headers: I18nSimpleKey[];
}) => {
  const sectionValue = useFormyValue(formy, field);
  const sectionFormy = formy.getSectionFormy(field);
  return (
    <div>
      <table className="formy-table">
        <thead>
          <tr>
            {headers.map((h, hi) => (
              <th key={hi}>{formy.t(h)}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sectionValue.map((_, ix) => {
            return (
              <tr key={ix}>
                {renderRowCells(sectionFormy.getSectionFormy(ix)).map((cell, ci) => (
                  <td key={ci}>{cell}</td>
                ))}
                <td>
                  <Button
                    type="dashed"
                    onClick={() => {
                      const newValue =
                        // Replace the last item with the default values, instead of having an empty list.
                        (
                          sectionValue.length > 1 ? sectionValue.filter((_, i) => i !== ix) : [defaultValue([])]
                        ) as F[keyof F];
                      formy.getChangeHandler(field)(newValue);
                    }}
                    icon={iconRemove}>
                    {labelRemove && formy.t(labelRemove)}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Button
        style={{marginTop: '24px'}}
        type="dashed"
        onClick={() => {
          const newRow = defaultValue(formy.getValue(field));
          formy.getChangeHandler(field)(formy.getValue(field).concat(newRow) as F[keyof F]);
        }}
        icon={iconAdd}>
        {labelAdd && formy.t(labelAdd)}
      </Button>
    </div>
  );
};

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl, {
  GeoJSONSource,
  MapSourceDataEvent,
  MapStyleDataEvent,
  MapboxEvent,
  MapboxGeoJSONFeature,
  MapboxOptions,
} from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import {Root, createRoot} from 'react-dom/client';
import {MIN_FIELD_LAYER_ZOOM} from '../../../src/constants/colors';
import equal from '../../../src/fast-deep-equal';
import {Bbox, LngLat, fromArrBbox, toMinMaxBbox} from '../../../src/geo';
import {isEntityGeoPropsFeature} from '../../../src/models/geojson';
import {filterArr, filterNulls} from '../../../src/util/arr-util';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import {setLastMapViewport} from '../redux/map';
import {getMarkerComponent} from './MapMarkers';
import {Layers, Sources} from './layers-sources';
import {fieldBgShapeLayer, fieldLayer, fieldShapeLayerBase, fieldShapeLayerSat} from './layers-specs';

export interface Marker {
  location: LngLat;
  type: 'farm' | 'field' | 'sample' | 'lnglat' | 'address';
  color?: string;
}

mapboxgl.accessToken = 'pk.eyJ1Ijoic2F2dm9wb3Vsb3MiLCJhIjoiY2tjZjA5anZvMGRlbjJ6bm55bGhib2ZvdiJ9.6gJoEJYXT26zkpcZM39ENA';

export interface MapboxProps {
  centerCoords?: LngLat;
  zoomLevel?: number;
  bounds?: Bbox;
  styleUrl: string;
  sources: Sources;
  layers: Layers;
  marker: null | Marker;
  hideControls?: boolean;
  extraOptions?: Partial<MapboxOptions>;

  // Can be used to receive all field shape features that are visible on the map.
  fieldFeaturesCb?: (fieldIds: Set<string>) => void;

  // A function that's called shortly after the Map has been created. It's called once during
  // the lifetime of this component. This will be called before any useEffect functions for the
  // parent components, so its presence can generally be assumed.
  onMapboxMapInstance: (map: mapboxgl.Map) => void;
}

type MapboxState = {
  // The latest set of features that we got.
  fieldIds: Set<string>;
};

export default class Mapbox extends React.PureComponent<MapboxProps, MapboxState> {
  static contextType = ApisContext;
  context!: Apis;

  map?: mapboxgl.Map;
  sourcesToRemove: Set<string> = new Set();
  layersToRemove: Set<string> = new Set();
  private mapContainer = React.createRef<HTMLDivElement>();
  private styledataLoading?: boolean;
  private marker: null | [mapboxgl.Marker, Root] = null;

  componentDidMount() {
    if (!this.mapContainer.current) {
      throw new Error("mapContainer element didn't initialize properly.");
    }

    const options: MapboxOptions = {
      container: this.mapContainer.current,
      style: this.props.styleUrl,
      dragRotate: false,
      pitchWithRotate: false,
      projection: {name: 'mercator'},
      ...this.props.extraOptions,
    };
    if (this.props.bounds) {
      options.bounds = this.props.bounds && toMinMaxBbox(this.props.bounds);
    } else {
      options.center = this.props.centerCoords;
      options.zoom = this.props.zoomLevel;
    }

    this.map = new mapboxgl.Map(options);
    this.props.onMapboxMapInstance(this.map);
    if (!this.props.hideControls) {
      this.map.addControl(new mapboxgl.NavigationControl({showCompass: false}));
    }
    // This hack allows the map to fill the screen, in cases when the map is the first thing that loads.
    setTimeout(() => this.map!.resize(), 0);

    // styledata is fired whenever the style object changes and finishes loading - at which point,
    // we have to re-add all client-side layers and sources.
    this.styledataLoading = true;
    this.map.on('styledataloading', () => {
      this.styledataLoading = true;
    });

    this.map.on('styledata', () => {
      this.styledataLoading = false;
      this.updateLayersSources();
    });

    this.map.on('moveend', this.handleViewportFeaturesCb);
    this.map.on('data', this.handleViewportFeaturesCb);

    this.updateMarker();
  }

  componentWillUnmount() {
    this.map?.remove();
  }

  handleViewportFeaturesCb = (
    e: MapSourceDataEvent | MapStyleDataEvent | MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>,
  ) => {
    const location: undefined | LngLat = this.map?.getCenter()
      ? [this.map.getCenter().lng, this.map.getCenter().lat]
      : undefined;
    const bounds = this.map?.getBounds().toArray() as [LngLat, LngLat],
      zoom = this.map?.getZoom();
    const bbox = fromArrBbox(bounds);
    if (location && zoom && bbox) {
      this.context.store.dispatch(setLastMapViewport(location, zoom, bbox));
    }

    if ((this.map?.getZoom() || 0) < MIN_FIELD_LAYER_ZOOM) {
      // Clear out set of fields if we are below the intrafield zoom level.
      this.props.fieldFeaturesCb && this.props.fieldFeaturesCb(new Set());
      return;
    }

    const layers: string[] = [];
    const fieldShapeLayer =
      this.map?.getLayer(fieldBgShapeLayer.id) ||
      this.map?.getLayer(fieldShapeLayerBase.id) ||
      this.map?.getLayer(fieldShapeLayerSat.id);
    const fieldShapeLayerId: undefined | string = fieldShapeLayer?.id;
    if (this.map?.getLayer(fieldLayer.id)) {
      layers.push(fieldLayer.id);
    }
    if (fieldShapeLayerId) {
      layers.push(fieldShapeLayerId);
    }
    if (layers.length == 0) {
      return; // Skip this callback if none of the field shape layers is present.
    }

    if (
      e.type === 'sourcedata' &&
      (e as MapSourceDataEvent).dataType === 'source' &&
      (e as MapSourceDataEvent).sourceId != 'entity-features'
    ) {
      return; // Skip this callback if this was a source event that didn't affect *any* of the layers.
    }

    // Get all visible fields on the map, and pass them to component above via fieldFeaturesCb. We use both the field
    // circles and the shapes; given that the shapes are loaded with a delay, this enables the layers to start
    // loading before the shapes are available.
    const res: undefined | MapboxGeoJSONFeature[] = this.map?.queryRenderedFeatures(undefined, {layers});
    const features = res && filterArr(res, isEntityGeoPropsFeature);
    const fieldIds = new Set(
      filterNulls(
        (features ?? []).map(x => (x.properties.type == 'field' ? ((x.id as string) ?? x.properties.field_id) : null)),
      ),
    );
    this.props.fieldFeaturesCb && this.props.fieldFeaturesCb(fieldIds);
  };

  componentDidUpdate(prevProps: Readonly<MapboxProps>) {
    try {
      if (!equal(this.props.marker, prevProps.marker)) {
        this.updateMarker();
      }

      const newCenter = this.props.centerCoords;
      if (
        newCenter &&
        (!prevProps.centerCoords ||
          prevProps.centerCoords[0] !== newCenter[0] ||
          prevProps.centerCoords[1] !== newCenter[1])
      ) {
        this.map!.setCenter(newCenter);
      }

      const newZoom = this.props.zoomLevel;
      if (newZoom && prevProps.zoomLevel !== newZoom) {
        this.map!.setZoom(newZoom);
      }

      if (prevProps.styleUrl !== this.props.styleUrl) {
        // Note that changing the map style will trigger the styledata event,
        // which will then re-add our data sources.
        this.map!.setStyle(this.props.styleUrl);
      }

      if (!equal(prevProps.layers, this.props.layers) || !equal(prevProps.sources, this.props.sources)) {
        for (const prevSourceName in prevProps.sources) {
          if (!(prevSourceName in this.props.sources)) {
            this.sourcesToRemove.add(prevSourceName);
          }
        }

        let allNames = [];
        for (const i of this.props.layers) {
          allNames.push(i.layer.id);
        }

        for (const prevLayerName of prevProps.layers) {
          if (!allNames.includes(prevLayerName.layer.id)) {
            this.layersToRemove.add(prevLayerName.layer.id);
          }
        }

        // If the layers got changed, updating procedure (i.e. adding/removing sources/layers) will get called,
        // even if there were no new layersToRemove or sourcesToRemove.
        this.updateLayersSources();
      }
    } catch (e) {
      console.error('Mapbox.componentDidUpdate:', e);
    }
  }

  updateMarker() {
    if (!this.map) {
      return;
    }

    if (!this.props.marker) {
      this.marker?.[1].unmount();
      this.marker?.[0].remove();
      this.marker = null;
      return;
    }

    if (!this.props.marker.location) {
      console.warn('Marker without location set', this.props.marker);
      return;
    }

    // TODO(sebastian): Potential refactoring to move most/all of Map.getMapViewport here
    // Control by extending Marker with {
    //   centerOnMount: 'always' | 'if-out-of-viewport' | false,
    //   changeZoomOnMount: number | false,
    //   changeBoundsOnMount: MapboxBbox | false, // will overwrite zoom
    // }
    // re-center if marker would be rendered outside the viewport
    //if (!this.viewportContainsPoint(this.props.marker.location)) {
    //  this.map.setCenter(this.props.marker.location);
    //}

    if (!this.marker) {
      // see: https://docs.mapbox.com/help/tutorials/custom-markers-gl-js/#create-a-mapbox-gl-js-map
      const markerElement = document.createElement('div');
      const root = createRoot(markerElement);
      this.marker = [new mapboxgl.Marker(markerElement), root];
      this.marker[0].setLngLat(this.props.marker.location).addTo(this.map);
    } else {
      this.marker[0].setLngLat(this.props.marker.location);
    }

    // on create && update

    // make sure we use the elements color for the marker
    // else we'll see overlapping element within the rendered ring
    // reset to empty string if no color supplied, using 'none' will not work
    const markerElement = this.marker[0].getElement();
    markerElement.style.backgroundColor = this.props.marker.color || '';
    markerElement.className = `marker-type-${this.props.marker.type}`;

    const reactMarker = getMarkerComponent(this.props.marker);
    if (reactMarker) {
      this.marker[1].render(reactMarker);
    } else {
      this.marker[1].unmount();
    }
  }

  updateLayersSources = () => {
    if (this.styledataLoading) {
      return;
    }

    // 1. removeMapLayers
    for (const layerToRemove of this.layersToRemove) {
      this.map!.removeLayer(layerToRemove);
      if (layerToRemove == 'flood-fill-line' && this.map!.hasImage('diagonal-stripes')) {
        // Remove diagonal-stripes image.
        this.map!.removeImage('diagonal-stripes');
      }
    }
    this.layersToRemove = new Set();

    // 2. removeSources
    for (const sourceToRemove of this.sourcesToRemove) {
      try {
        this.map!.removeSource(sourceToRemove);
      } catch (e) {
        console.error('Failed to remove source', sourceToRemove, ':', e);
      }
    }
    this.sourcesToRemove = new Set();

    for (const sourceName in this.props.sources) {
      const source = this.props.sources[sourceName];
      const curSource = this.map!.getSource(sourceName);
      if (curSource) {
        if (source.source.type === 'geojson') {
          if (typeof source.source.data == 'string' || source.source.data?.type != 'FeatureCollection') {
            console.error('Invalid data for source', sourceName, ':', source.source.data);
            return;
          }
          (curSource as GeoJSONSource).setData(source.source.data!);
        }
      } else {
        this.map!.addSource(sourceName, source.source);
      }
    }
    for (const layer of this.props.layers) {
      const curLayer = this.map!.getLayer(layer.layer.id);
      if (curLayer === undefined) {
        this.map!.addLayer(layer.layer, layer.displayBefore);
      } else {
        // TODO(savv): We should detect changes to layer.filter or layer.layout and use map.setFilter and map.setLayout.
      }
    }
  };

  render() {
    return <div data-testid="Mapbox" ref={this.mapContainer} style={{width: '100%', height: '100%'}} />;
  }
}

export type Timeout = NodeJS.Timeout;

export interface ClockI {
  now(): number;

  setTimeout(handler: () => void, timeoutMs: number): any;
  setInterval(handler: () => void, intervalMs: number): any;

  clearTimeout(t: any): void;
}

export class Clock implements ClockI {
  now(): number {
    return new Date().getTime();
  }

  setTimeout(handler: () => void, timeoutMs: number): any {
    return setTimeout(handler, timeoutMs);
  }

  setInterval(handler: () => void, intervalMs: number): any {
    return setInterval(handler, intervalMs);
  }

  clearTimeout(t: any): void {
    clearTimeout(t);
  }
}

import {Feature, Position} from 'geojson';
import proj4 from 'proj4';
import {FetchFn} from '../../../src/CommonApis';
import {INTERFIELD_COLORS, InterfieldZones} from '../../../src/constants/colors';
import {Bbox, getBoundingBox} from '../../../src/geo';
import {HarvestMapFeatures} from '../../../src/report/generate-report';
import {sampleShapeLayer} from '../map/layers-specs';

const convertCoordinates = (x: Position) => proj4('EPSG:4326', 'EPSG:3857', x);

async function fetchSquareMap(fetchFn: FetchFn, features: Feature[], size: number) {
  const bbox = getBoundingBox({type: 'FeatureCollection', features})!;
  if (!bbox) {
    return;
  }
  let {
    sw: [x1, y1],
    ne: [x2, y2],
  } = bbox;
  // add some margin
  const dx = x2 != x1 ? x2 - x1 : 0.00001;
  const dy = y2 != y1 ? y2 - y1 : 0.00001;
  x1 = x1 - dx * 0.1;
  x2 = x2 + dx * 0.1;
  y1 = y1 - dy * 0.1;
  y2 = y2 + dy * 0.1;

  const prefix = 'https://api.mapbox.com/styles/v1/savvopoulos/cjvibgz50068b1cphkt6m1iuj/static';
  const [X1, Y1] = convertCoordinates([x1, y1]);
  const [X2, Y2] = convertCoordinates([x2, y2]);

  const [width, height] = [size, size];

  const token = 'pk.eyJ1Ijoic2F2dm9wb3Vsb3MiLCJhIjoiY2tjZjA5anZvMGRlbjJ6bm55bGhib2ZvdiJ9.6gJoEJYXT26zkpcZM39ENA';
  const url = `${prefix}/[${x1},${y1},${x2},${y2}]/${width}x${height}?access_token=${token}&attribution=false&logo=false&padding=0,0,0,0`;

  const response = await fetchFn(url);
  const image = await response.blob();
  return {image, bbox: {sw: [X1, Y1], ne: [X2, Y2]} as Bbox};
}

function getHarvestMapSVGLayer(features: HarvestMapFeatures, bbox: Bbox, size: number) {
  const {
    sw: [X1, Y1],
    ne: [X2, Y2],
  } = bbox;

  const w = X2 - X1;
  const h = Y2 - Y1;

  const transform = ([x, y]: Position): Position => {
    if (h > w) {
      const offset = (h / w - 1) * w;
      return [(size * (x - X1 + 0.5 * offset)) / (w + offset), (size * (Y2 - y)) / h];
    } else {
      const offset = (w / h - 1) * h;
      return [(size * (x - X1)) / w, (size * (Y2 - y + 0.5 * offset)) / (h + offset)];
    }
  };

  const coordsToPoints = (coords: Position[]) =>
    coords
      .map(convertCoordinates)
      .map(transform)
      .map(([x, y]: Position) => `${x},${y}`)
      .join(' ');

  const interfield = [];

  for (const feature of features.interfield.features) {
    for (const coords of feature.geometry.coordinates) {
      if (feature.properties) {
        const color = INTERFIELD_COLORS[feature.properties.shape_type as InterfieldZones];
        const points = coordsToPoints(coords);
        interfield.push(`<polygon points="${points}" fill="${color}" stroke="none"></polygon>`);
      }
    }
  }

  // Add field shape if no interfield layer
  if (features.field && interfield.length == 0) {
    for (const coords of features.field.geometry.coordinates) {
      const points = coordsToPoints(coords);
      interfield.push(
        `<polygon points="${points}" fill="none" stroke="white" stroke-dasharray="4" stroke-width="2"></polygon>`,
      );
    }
  }

  const fontSize = (15 * size) / 240;
  const textStyle = 'paint-order: stroke; stroke: #FFFFFF; stroke-width: 4px';
  const samples = [];
  for (const feature of features.samples.features) {
    if (!!feature.geometry) {
      const [x, y] = transform(convertCoordinates(feature.geometry.coordinates));
      const points = [
        [0, 0],
        [-fontSize / 2, (-fontSize * 3) / 4],
        [fontSize / 2, (-fontSize * 3) / 4],
      ].map(([dx, dy]) => `${x + dx} ${y + dy}`);
      samples.push(`<polygon points="${points}" fill="black" stroke="none"></polygon>`);
      samples.push(`<polygon points="${points}" fill="none" stroke="#808080"></polygon>`);
      samples.push(
        `<text font-family="sans-serif" font-size="${fontSize}" style="${textStyle}" x="${x - fontSize / 2}" y="${
          y + fontSize
        }">${feature.properties.label}</text>`,
      );
    }
  }

  const sampleShapeColor = sampleShapeLayer.paint!['fill-color'];
  const sampleShapeOpacity = sampleShapeLayer.paint!['fill-opacity'];
  for (const feature of features.sampleShapes.features) {
    for (const coords of feature.geometry.coordinates) {
      const points = coordsToPoints(coords);
      interfield.push(
        `<polygon points="${points}" fill="${sampleShapeColor}" fill-opacity="${sampleShapeOpacity}" stroke="none"></polygon>`,
      );
    }
  }

  const innerHTML = interfield.join('\n') + '\n' + samples.join('\n');
  return `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}">${innerHTML}</svg>`;
}

export async function getHarvestMap(fetchFn: FetchFn, features: HarvestMapFeatures, size: number) {
  const {interfield, samples, sampleShapes, field: fieldShape}: HarvestMapFeatures = features;
  const mapFeatures = [...samples.features] as Feature[];
  mapFeatures.push(...interfield.features);
  mapFeatures.push(...sampleShapes.features);
  if (!!fieldShape) {
    // add field shape to the bbox calculation just in case no interfield is available
    mapFeatures.push(fieldShape);
  }

  const map = await fetchSquareMap(fetchFn, mapFeatures, size);
  if (!!map) {
    const {image, bbox} = map;
    const layer = getHarvestMapSVGLayer(features, bbox, size);
    return {image, layer};
  }
}

import React from 'react';
import {CommonViewStyle} from '../../../src/components/CommonViewStyle';
import {
  Anomaly,
  FieldSeries,
  FieldSeriesItem,
  FieldSeriesPlot,
  boxPlotZones,
  dateLabelStyle,
  getFieldSeriesItemDesc,
  getItemAggregateValue,
  groupFieldTimeseries,
  hasInterfield,
  normalizeInterfield,
} from '../../../src/components/FieldSeriesPlot';
import {I18nFunction} from '../../../src/i18n/i18n';
import {Field} from '../../../src/models/interfaces';

const shadowStyle = {filter: 'drop-shadow(2px 2px 5px rgba(0,0,0,0.5))'};
export type FieldChartsProps = {
  fieldSeries: FieldSeries;
  groupFn: (date: string) => string;
  showLabels: boolean;
  showSelectedItem: boolean;
  selectedDate: string | null;
  onDateSelected: (date: string) => void;
  anomalies: {[P in Anomaly]?: boolean};
  bandWidth: number;
  bandHeight: number;
  gapHeight: number;
  t: I18nFunction;
  formatDate?: (date: string) => null | string;
  expanded: boolean;
  field: Field | null;
  enableBiomassLabelHack?: boolean;
};

class FieldCharts extends React.PureComponent<FieldChartsProps> {
  scroll = () => {
    const element = document.getElementById('fieldcharts-scrollable');
    if (!element) {
      console.error(`FieldCharts: couldn't find #fieldcharts element.`);
      return;
    }

    const selected = document.getElementById('fieldcharts-selected');
    if (selected) {
      // Scroll to show selected week
      selected.scrollIntoView({inline: 'center'});
    } else {
      // Scroll all the way to the right
      element.scrollLeft = element.scrollWidth;
    }
  };

  componentDidMount() {
    if (this.props.expanded) {
      this.scroll();
    }
  }

  componentDidUpdate(prevProps: Readonly<FieldChartsProps>) {
    if (this.props.expanded && prevProps.expanded != this.props.expanded) {
      this.scroll();
    }
  }

  renderSelectedItem(data: FieldSeries[]) {
    const date = this.props.selectedDate;
    if (!date || !this.props.showSelectedItem) {
      return null;
    }

    const groupItem = data.find(x => Object.keys(x).some(y => date == y));
    const item = groupItem && groupItem[date];
    if (!item) {
      return null;
    }

    const lines = getFieldSeriesItemDesc(
      this.props.t,
      this.props.anomalies,
      date,
      item,
      this.props.field?.field_area ?? null,
    );
    return (
      <>
        {lines.map((l, i) =>
          i == 0 ? (
            <b key={`${date}-${i}`}>
              <span className="field-series-desc-item">{l}</span>
            </b>
          ) : (
            <span className="field-series-desc-item" key={`${date}-${i}`}>
              {l}
            </span>
          ),
        )}
      </>
    );
  }

  renderAreaLayerLabels(interfield: boolean, data: FieldSeries[]) {
    if (!this.props.showLabels) {
      return null;
    }

    const labelStyle: CommonViewStyle = {
      height: (this.props.bandWidth * 9) / 20 + this.props.gapHeight /* total height of circles */,
    };

    let interfieldLabelMarginTop = 0;
    let interfieldLabelHeight =
      (interfield ? this.props.bandHeight * 4 : 0) +
      10 /* container padding + margin */ +
      dateLabelStyle.height +
      this.props.gapHeight;
    if (this.props.enableBiomassLabelHack) {
      // HACK: find the first item with interfield and check how high it's going to be; if it's above a threshold, then the the box plot
      // may overlap with the label "biomass". In that case, adjust the label so that it appears underneath the boxplot, so that the
      // chart is readable.
      let firstInterfield: null | FieldSeriesItem = null;
      for (const series of data) {
        for (const item of Object.values(series)) {
          if (hasInterfield(item)) {
            firstInterfield = item;
            break;
          }

          if (firstInterfield) {
            break;
          }
        }
      }
      data.find(x => Object.values(x).find(y => hasInterfield(y)));
      if (firstInterfield && getItemAggregateValue(firstInterfield) < 0.35) {
        interfieldLabelHeight -= 50;
        interfieldLabelMarginTop = 50;
      }
    }

    return (
      <span id="fieldcharts-region-labels" style={{top: -this.props.gapHeight}}>
        <span
          className="fieldseries-label"
          style={{
            ...labelStyle,
            height: interfieldLabelHeight,
            marginTop: interfieldLabelMarginTop,
          }}>
          {interfield ? this.props.t('Biomass') : ''}
        </span>
        {(!this.props.anomalies || this.props.anomalies['soil-moisture']) && (
          <span className="fieldseries-label" style={labelStyle}>
            {this.props.t('soil-moisture')}
          </span>
        )}
        {(!this.props.anomalies || this.props.anomalies['temperature']) && (
          <span className="fieldseries-label" style={labelStyle}>
            {this.props.t('temperature')}
          </span>
        )}
        {(!this.props.anomalies || this.props.anomalies['surface-temperature']) && (
          <span className="fieldseries-label" style={labelStyle}>
            {this.props.t('surface-temperature')}
          </span>
        )}
        {(!this.props.anomalies || this.props.anomalies['vegetation']) && (
          <span className="fieldseries-label" style={labelStyle}>
            {this.props.t('vegetation')}
          </span>
        )}
        {(!this.props.anomalies || this.props.anomalies['hail']) && (
          <span className="fieldseries-label" style={labelStyle}>
            {this.props.t('hail')}
          </span>
        )}
        {(!this.props.anomalies || this.props.anomalies['precipitation']) && (
          <span className="fieldseries-label" style={labelStyle}>
            {this.props.t('precipitation')}
          </span>
        )}
        {(!this.props.anomalies || this.props.anomalies['rainstorm']) && (
          <span className="fieldseries-label" style={labelStyle}>
            {this.props.t('rainstorm')}
          </span>
        )}
        {(!this.props.anomalies || this.props.anomalies['wind']) && (
          <span className="fieldseries-label" style={labelStyle}>
            {this.props.t('wind')}
          </span>
        )}
      </span>
    );
  }

  render() {
    const data = groupFieldTimeseries(normalizeInterfield(this.props.fieldSeries), this.props.groupFn);
    let interfield = false;
    for (const series of data) {
      for (const d in series) {
        const val = series[d]!;
        if (Object.keys(val).some(x => x in boxPlotZones)) {
          interfield = true;
          break;
        }
      }
    }

    if (!data || data.length == 0) {
      return null;
    }

    return (
      <span id="fieldcharts" className={this.props.expanded ? 'with-scrollbars margin-top' : undefined}>
        <span id="fieldcharts-with-labels">
          <div id="fieldcharts-scrollable">
            <span className="field-charts-inner-container">
              {data.map(x => (
                <FieldSeriesPlot
                  Box="span"
                  Text="span"
                  Touchable="span"
                  formatDate={this.props.formatDate}
                  selectedItemStyle={shadowStyle}
                  {...this.props}
                  hasInterfield={interfield}
                  series={x}
                  key={(Object.keys(x) || [])[0]}
                />
              ))}
            </span>
          </div>
          {this.renderAreaLayerLabels(interfield, data)}
        </span>
        {this.renderSelectedItem(data)}
      </span>
    );
  }
}

export default FieldCharts;

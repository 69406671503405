import {Alert, Card, List, Space} from 'antd';
import React, {FC, ReactNode} from 'react';
import {useApis} from '../apis/ApisContext';
import {MembershipUpdate, MembershipUpdates} from '../util/admin-util';
import {MembershipTag} from './AdminMembershipTags';

interface UserEntityUpdate {
  email: string;
  added: MembershipUpdate[];
  removed: MembershipUpdate[];
}

export const UserEntityUpdates: FC<{updates: MembershipUpdates}> = ({updates}) => {
  const dataSource: UserEntityUpdate[] = [];

  updates.grant.forEach(update => {
    const existing = dataSource.find(x => x.email == update.email);
    if (existing) {
      existing.added.push(update);
    } else {
      dataSource.push({email: update.email, added: [update], removed: []});
    }
  });

  updates.revoke.forEach(update => {
    const existing = dataSource.find(x => x.email == update.email);
    if (existing) {
      existing.removed.push(update);
    } else {
      dataSource.push({email: update.email, added: [], removed: [update]});
    }
  });

  return (
    <List<UserEntityUpdate>
      dataSource={dataSource.sort((a, b) => a.email.localeCompare(b.email))}
      grid={{
        gutter: 5,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        xl: 1,
        xxl: 1,
      }}
      renderItem={item => <UserEntityUpdate item={item} renderTitle={true} />}
    />
  );
};

export const InlineList: React.FC<{items: ReactNode[]}> = ({items}) => {
  return <span className="inline-list">{items}</span>;
};

export const UserEntityUpdate: React.FC<{item: UserEntityUpdate; renderTitle: boolean}> = ({item, renderTitle}) => {
  const {t} = useApis();
  return (
    <List.Item>
      <Card title={renderTitle ? item.email : undefined}>
        <Space direction="vertical">
          {item.added.length > 0 && (
            <Alert
              type="success"
              message={t('AddedGroups')}
              description={
                <InlineList
                  items={item.added.map(i => (
                    <MembershipTag key={i.user_group.user_group + i.membership_type} {...i} />
                  ))}
                />
              }
            />
          )}
          {item.removed.length > 0 && (
            <Alert
              type="warning"
              message={t('RemovedGroups')}
              description={
                <InlineList
                  items={item.removed.map(i => (
                    <MembershipTag key={i.user_group.user_group + i.membership_type} {...i} />
                  ))}
                />
              }
            />
          )}
        </Space>
      </Card>
    </List.Item>
  );
};

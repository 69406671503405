import {List} from 'antd';
import React from 'react';
import './Card.css';
import {LocalizedEmptyImage} from './Dashboard';
import {DashboardCard} from './DashboardCard';

interface ItemValue {
  color?: string;
  title: string;
  value: number | string;
}

class ListOfValuesCard extends React.Component<{title: string; data: ItemValue[]; loading: boolean}> {
  render() {
    const {data, loading, title} = this.props;
    return (
      <DashboardCard long title={title} loading={loading} hasData={data.length > 0}>
        <div className="card-content best-averages">
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={this.renderItem}
            locale={{emptyText: <LocalizedEmptyImage />}}
          />
        </div>
      </DashboardCard>
    );
  }

  renderItem = (it: ItemValue) => (
    <List.Item>
      <div className="list__item">
        <div className="list__item-title">
          {!it.color ? null : <div className="chart-dot" style={{backgroundColor: it.color}} />}
          <span>{it.title}</span>
        </div>
        <p>{it.value}</p>
      </div>
    </List.Item>
  );
}

export default ListOfValuesCard;

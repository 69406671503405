import * as d3 from 'd3';
import React from 'react';
import {PALETTE_COLORS} from '../../../src/constants/colors';
import {splitText} from '../../../src/text/split';

export interface BarChartDatum {
  key: string;
  value: number;
  color?: string;
}

interface BarChartProps {
  width: number;
  height: number;
  id: string;
  data: BarChartDatum[];
  formatValue: (value: number) => string;
}

class BarChart extends React.PureComponent<BarChartProps> {
  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.drawChart();
  }

  drawChart() {
    const {data} = this.props;
    const margin = {top: 10, right: 10, bottom: 20, left: 10},
      innerHeight = this.props.height - margin.top - margin.bottom;
    const el = document.getElementById(this.props.id);
    if (el) {
      el.innerHTML = '';
    }
    const svg = d3
      .select(`#${this.props.id}`)
      .append('svg')
      .attr('viewBox', `0 0 ${this.props.width} ${this.props.height}`)
      .append('g');
    let maxLabelWidth = 0;
    const yScale = d3
      .scaleBand()
      .range([0, innerHeight])
      .domain(data.map(d => d.key))
      .padding(0.1);
    svg
      .append('g')
      .call(d3.axisLeft(yScale))
      .selectAll('text')
      .each(function (textElement: any) {
        const textElementNode = d3.select(this);
        textElementNode.text(''); // clear current node text
        splitText(textElement.toString()).forEach((part: string, index: number) => {
          textElementNode
            .append('tspan')
            .text(part.trim())
            .attr('x', -10)
            .attr('dy', index === 0 ? '0em' : '1em');
        });
        const labelWidth = (this as SVGTextElement).getBBox?.().width ?? 10;
        maxLabelWidth = Math.max(maxLabelWidth, labelWidth);
      });

    maxLabelWidth = Math.min(maxLabelWidth, (this.props.width * 2) / 3);

    svg.attr('transform', `translate(${margin.left + maxLabelWidth},${margin.top})`);

    let maxValueLabelWidth = 0;
    var bars = svg
      .selectAll('text.valueLabel')
      .data(data)
      .enter()
      .append('text')
      .attr('attr', 'white')
      .attr('y', d => yScale(d.key)! + yScale.bandwidth() / 2)
      .attr('dy', '.35em') //vertical align middle
      .attr('text-anchor', 'begin')
      .text(d => this.props.formatValue(d.value))
      .each(function () {
        const labelWidth = (this as SVGTextElement).getBBox?.().width ?? 30;
        maxValueLabelWidth = Math.max(maxValueLabelWidth, labelWidth);
      });

    const innerWidth = this.props.width - margin.left - margin.right - maxLabelWidth - maxValueLabelWidth;

    const x = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.value) as number])
      .range([0, innerWidth]);

    svg
      .selectAll()
      .data(data)
      .enter()
      .append('rect')
      .attr('x', x(0))
      .attr('y', d => yScale(d.key)!)
      .attr('width', d => x(d.value)!)
      .attr('height', yScale.bandwidth())
      .attr('fill', d => d.color || PALETTE_COLORS.baseGrey);

    bars.attr('x', d => x(d.value) + 5);
  }

  render() {
    return <div id={this.props.id}></div>;
  }
}

export default BarChart;

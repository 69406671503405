import {Spin} from 'antd';
import React, {ChangeEvent} from 'react';
import {deepCopy} from '../../../src/deepCopy';
import equal from '../../../src/fast-deep-equal';
import {CropMonCountryCode, LayerParams} from '../../../src/layers/layer-params';
import {CropMonType, MonitoredCrop} from '../../../src/models/crop-mon';
import {HarvestSeason, HarvestYear} from '../../../src/models/interfaces';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import BoxPlot from './BoxPlot';
import {DateSelector} from './DateSelector';
import {MapLegend} from './MapLegend';
import {BoxPlotDatum, fetchCropMonSeries} from './crop-mon-util';

interface CropMonAvailability {
  country_code: CropMonCountryCode;
  harvest_crop: MonitoredCrop;
  harvest_season: HarvestSeason;
  harvest_year: HarvestYear;
}

interface CropMonToolboxProps {
  canonical_date: null | string;
  layerParams: null | LayerParams;
  onCanonicalDateChanged: (date: null | string) => void;
  onLayerParamsChanged: (layerParams: LayerParams) => void;
}

interface CropMonToolboxState {
  available?: CropMonAvailability[];
  series?: BoxPlotDatum[];
  curDate?: string;
}

export default class CropMonToolbox extends React.PureComponent<CropMonToolboxProps, CropMonToolboxState> {
  static contextType = ApisContext;
  context!: Apis;
  state = {curDate: this.props.canonical_date} as CropMonToolboxState;

  async componentDidMount() {
    let available: CropMonAvailability[];
    try {
      available = await this.context.authedFetcher({
        method: 'GET',
        path: 'api/crop_monitoring_availability',
      });

      this.setState({available});

      if (this.props.layerParams) {
        await this.maybeFetchNationalSeries();
      } else {
        this.props.onLayerParamsChanged({
          layer_type: 'crop-mon',
          params: [
            available[0].country_code,
            'predicted-yield',
            available[0].harvest_crop,
            available[0].harvest_season,
            available[0].harvest_year,
          ],
        });
      }
    } catch (e) {
      console.error(`CropMonToolbox: Couldn't load availability info for crop-mon:`, e, '; got:', available!);
    }
  }

  componentDidUpdate(prevProps: Readonly<CropMonToolboxProps>) {
    if (this.props.canonical_date && this.props.canonical_date != prevProps.canonical_date) {
      this.setState({curDate: this.props.canonical_date});
    } else {
      const dates = this.availableDates();
      if (dates?.length && dates.indexOf(this.state.curDate!) == -1) {
        this.setDate(dates[dates.length - 1]);
      }
    }

    if (!equal(prevProps.layerParams, this.props.layerParams)) {
      this.maybeFetchNationalSeries();
    }
  }

  maybeFetchNationalSeries() {
    this.setState({series: undefined});
    if (this.props.layerParams?.layer_type != 'crop-mon' || this.props.layerParams.params[1] != 'predicted-yield') {
      return;
    }

    fetchCropMonSeries(
      this.context,
      this.props.layerParams.params[2],
      this.props.layerParams.params[3],
      this.props.layerParams.params[4],
      `GADM.${this.props.layerParams.params[0]}.`,
    )
      .then(series => {
        let maxDate = null;
        for (const datum of series) {
          if (datum.date && (!maxDate || datum.date.getTime() > maxDate?.getTime())) {
            maxDate = datum.date;
          }
        }
        this.props.onCanonicalDateChanged(maxDate ? maxDate.toISOString().slice(0, 10) : null);
        this.setState({series});
      })
      .catch(e =>
        console.error(
          `CropMonToolbox: Couldn't load national timeseries for`,
          JSON.stringify(this.props.layerParams),
          ':',
          e,
        ),
      );
  }

  setDate = (curDate: null | string) => {
    this.setState({curDate: curDate || undefined});
    this.props.onCanonicalDateChanged(curDate);
  };

  selectCropMonType = (x: ChangeEvent<HTMLSelectElement>) => {
    const layerParams = deepCopy(this.props.layerParams!);
    layerParams.params[1] = x.target.value as CropMonType;
    this.setDate(null);
    this.props.onLayerParamsChanged(layerParams);
  };

  selectCropMonCountry = (x: ChangeEvent<HTMLSelectElement>) => {
    const layerParams = deepCopy(this.props.layerParams);
    if (!layerParams || !this.state.available) {
      console.error(
        'CropMonToolbox/selectBestCandidate: invalid state reached:',
        this.props.layerParams,
        this.state.available,
      );
      return;
    }

    const country_code = x.target.value as CropMonCountryCode;
    const available = this.state.available.filter(x => !country_code || country_code == x.country_code);
    if (available.length == 0) {
      console.error('CropMonToolbox/selectBestCandidate: no availability for', country_code, this.state.available);
      return;
    }

    // Try to keep the same crop type.
    let bestAvailable = available.find(x => x.harvest_crop == layerParams.params[2]);
    if (!bestAvailable) {
      bestAvailable = available[0];
    }

    layerParams.params[0] = bestAvailable.country_code;
    layerParams.params[2] = bestAvailable.harvest_crop;
    layerParams.params[3] = bestAvailable.harvest_season;
    layerParams.params[4] = bestAvailable.harvest_year;

    this.setDate(null);
    this.props.onLayerParamsChanged(layerParams);
  };

  selectMonitoredCrop = (x: ChangeEvent<HTMLSelectElement>) => {
    const layerParams = deepCopy(this.props.layerParams);
    if (!layerParams || !this.state.available) {
      console.error(
        'CropMonToolbox/selectBestCandidate: invalid state reached:',
        this.props.layerParams,
        this.state.available,
      );
      return;
    }

    const harvest_crop = x.target.value as MonitoredCrop;
    const available = this.state.available.filter(x => !harvest_crop || harvest_crop == x.harvest_crop);
    if (available.length == 0) {
      console.error('CropMonToolbox/selectBestCandidate: no availability for', harvest_crop, this.state.available);
      return;
    }

    // Try to keep the same country.
    let bestAvailable = available.find(x => x.country_code == layerParams.params[0]);
    if (!bestAvailable) {
      bestAvailable = available[0];
    }

    layerParams.params[0] = bestAvailable.country_code;
    layerParams.params[2] = bestAvailable.harvest_crop;
    layerParams.params[3] = bestAvailable.harvest_season;
    layerParams.params[4] = bestAvailable.harvest_year;

    this.setDate(null);
    this.props.onLayerParamsChanged(layerParams);
  };

  selectMonitoredSeason = (x: ChangeEvent<HTMLSelectElement>) => {
    const layerParams = deepCopy(this.props.layerParams);
    if (!layerParams || !this.state.available) {
      console.error(
        'CropMonToolbox/selectBestCandidate: invalid state reached:',
        this.props.layerParams,
        this.state.available,
      );
      return;
    }

    const harvest_season = x.target.value as HarvestSeason;
    const available = this.state.available.filter(x => !harvest_season || harvest_season == x.harvest_season);
    if (available.length == 0) {
      console.error('CropMonToolbox/selectBestCandidate: no availability for', harvest_season, this.state.available);
      return;
    }

    // Keep the same country and crop.
    let bestAvailable = available.find(
      x =>
        x.country_code == layerParams.params[0] &&
        x.harvest_crop == layerParams.params[2] &&
        x.harvest_season == harvest_season,
    );
    if (!bestAvailable) {
      console.error('CropMonToolbox/selectBestCandidate: no availability for', harvest_season, this.state.available);
      return;
    }

    layerParams.params[0] = bestAvailable.country_code;
    layerParams.params[2] = bestAvailable.harvest_crop;
    layerParams.params[3] = bestAvailable.harvest_season;
    layerParams.params[4] = bestAvailable.harvest_year;

    this.setDate(null);
    this.props.onLayerParamsChanged(layerParams);
  };

  availableDates = () => {
    const params = this.props.layerParams?.params;
    if (params && params[1] == 'predicted-yield') {
      const dates = this.state.series?.map(x => x.date?.toISOString().slice(0, 10) || '').filter(x => !!x);
      dates?.sort();
      return dates;
    }
    if (params && params[1] == 'historical-yield') {
      // Show historical yield ratio years up until the year before the currently available one.
      const availability = this.state.available
        ?.filter(a => a.country_code == params[0] && a.harvest_crop == params[2] && a.harvest_season == params[3])
        .map(a => Number(a.harvest_year));
      const lastAvailYear =
        availability && availability.length > 0
          ? Math.max(...availability)
          : new Date(this.context.clock.now()).getUTCFullYear();
      const dates = [];
      for (let year = 2011; year < lastAvailYear; ++year) {
        dates.push(year + '-01-01');
      }

      return dates;
    }

    return null;
  };

  render() {
    const {available} = this.state;
    const params = this.props.layerParams?.params;
    if (!available?.length || !params) {
      return <Spin className="half-padding" />;
    }

    const countryCodes = Array.from(new Set(available.map(x => x.country_code)));
    const crops = Array.from(new Set(available.filter(x => x.country_code == params[0]).map(x => x.harvest_crop)));
    const cur = available.find(
      x =>
        x.country_code == params[0] &&
        x.harvest_crop == params[2] &&
        x.harvest_season == params[3] &&
        x.harvest_year == params[4],
    );
    const harvestSeasons = available
      .filter(x => x.country_code == params[0] && x.harvest_crop == params[2])
      .map(x => x.harvest_season);
    const dates = this.availableDates();
    return (
      <div id="map-toolbox" className="card">
        <h2>{this.context.t('crop-mon')}</h2>
        <span className="crop-mon-sel-container">
          <select
            id="country-select"
            className="crop-mon-selector"
            value={params[0]}
            onChange={this.selectCropMonCountry}>
            {countryCodes.map(v => (
              <option value={v} key={v}>
                {this.context.t(v)}
              </option>
            ))}
          </select>
          <select
            id="crop-mon-select"
            className="crop-mon-selector"
            value={params[1]}
            onChange={this.selectCropMonType}>
            {CropMonType.map(v => (
              <option value={v} key={v}>
                {this.context.t(v)}
              </option>
            ))}
          </select>
          <select
            id="crop-type-select"
            className="crop-mon-selector"
            value={params[2]}
            onChange={this.selectMonitoredCrop}>
            {crops.map(v => (
              <option value={v} key={v}>
                {this.context.t(v)}
              </option>
            ))}
          </select>
          {harvestSeasons.length <= 1 ? null : (
            <select
              id="crop-season-select"
              className="crop-mon-selector"
              value={params[3]}
              onChange={this.selectMonitoredSeason}>
              {harvestSeasons.map(v => (
                <option value={v} key={v}>
                  {this.context.t(v)}
                </option>
              ))}
            </select>
          )}
        </span>
        {this.state.series && (
          <>
            <span>
              {this.context.t('NationalYield')} {cur?.harvest_year || null}
            </span>
            <BoxPlot t={this.context.t} height={300} id="crop-mon-box-plot" data={this.state.series} />
          </>
        )}
        {dates && this.state.curDate && (
          <DateSelector
            layerDates={dates}
            curDate={this.state.curDate}
            onChange={this.setDate}
            layer="crop-mon"
            yearOnly={params[1] == 'historical-yield'}
          />
        )}
        <MapLegend layer="crop-mon" layerParams={this.props.layerParams} />
      </div>
    );
  }
}

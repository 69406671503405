import React, {useContext} from 'react';
import {formatMonthYear} from '../../../src/text/date';
import {parseDate} from '../../../src/util/date-util';
import {ApisContext} from '../apis/ApisContext';
import LineChart from '../components/LineChart';
import {DashboardCard} from './DashboardCard';
import {useSampleCountsByAdjuster} from './queries';

export const SampleTimeseries: React.FC = () => {
  const {t} = useContext(ApisContext);
  const {data = [], isFetching} = useSampleCountsByAdjuster();
  const countByDate: Record<string, number> = {};
  for (const el of data) {
    const {sample_date, count} = el;
    if (!sample_date) {
      continue;
    }
    countByDate[sample_date] = (countByDate[sample_date] ?? 0) + (count ?? 0);
  }
  const sampleCountTimeseries = Object.entries(countByDate)
    .map(e => ({x: e[0], y: e[1]}))
    .sort((a, b) => (parseDate(a.x)?.getTime() ?? 0) - (parseDate(b.x)?.getTime() ?? 0));

  let sum = 0;
  for (const el of sampleCountTimeseries) {
    sum += el.y;
    el.y = sum;
  }

  return (
    <DashboardCard title={t('NumberOfSamples')} loading={isFetching} hasData={data.length > 0}>
      <LineChart
        width={300}
        height={300}
        id="sample-count-chart"
        formatDate={i => formatMonthYear(t, i)}
        formatValue={formatNumWithSIPrefix}
        data={sampleCountTimeseries.map(x => ({x: new Date(x.x), y: x.y}))}
      />
    </DashboardCard>
  );
};

function formatNumWithSIPrefix(x: number) {
  if (x < 1000) {
    return String(x);
  } else if (x < 1000000) {
    return (x / 1000).toFixed(x % 1000 ? 1 : 0) + 'k';
  } else {
    return (x / 1000000).toFixed(x % 1000000 ? 1 : 0) + 'm';
  }
}

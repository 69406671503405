export function isObjEmpty(x: Record<string, any>): boolean {
  // noinspection LoopStatementThatDoesntLoopJS
  for (const k in x) {
    return false;
  }

  return true;
}

export function clearNullsInPlace<T extends {}>(v: T) {
  for (const k in v) {
    if (v[k] == null) {
      delete v[k];
    }
  }
}

export type ValidateShape<T, Shape> = T extends Shape
  ? Exclude<keyof T, keyof Shape> extends never
    ? T
    : never
  : never;

export function getSelectedKeys<T extends string>(obj: Record<T, undefined | boolean>): T[] {
  return Object.entries(obj)
    .filter(([_, selected]) => selected)
    .map(([key]) => key as T);
}

// Returns true if the supplied object has a non-null value for any of the specified keys.
export function hasNonNull<O extends {}>(values: O, ...checkKeys: (keyof O)[]) {
  return checkKeys.some(k => values[k] != null);
}

import {Harvest} from '../models/interfaces';
import cmp from '../util/cmp';
import {PostgrestQuery, PostgrestQueryAnd} from '../util/postgrest-query';
import {isGrapes} from './harvest';

export const HarvestKeyComponents = ['crop_id', 'harvest_year', 'irrigated', 'organic', 'variety'] as const;
export type HarvestKeyComponent = (typeof HarvestKeyComponents)[number];

// Note that we do not force these types to be non-nullable; if a Harvest can have a null value for a column,
// we should be able to handle that.
export type HarvestKey = Pick<Harvest, HarvestKeyComponent>;

export const nullHarvestKey = {
  crop_id: null,
  harvest_year: null,
  irrigated: null,
  organic: null,
  variety: null,
} as const;

export function getHarvestKey(harvest: HarvestKey) {
  // NOTE: it's important to construct this object in a way where stringifying it will give the same result for the same input.
  // For example, the keys should always be in one order.
  return {
    // Do not return unknown here, this should be done when presenting null to the user.
    crop_id: harvest.crop_id,
    harvest_year: harvest.harvest_year,
    irrigated: harvest.irrigated,
    organic: harvest.organic,
    // Consider empty and null to be the same, for the aggregation key.
    variety: isGrapes(harvest.crop_id) ? null : harvest.variety?.trim() || null,
  };
}

// An ordering over harvests:
// - by REVERSE harvest year (2024 first, before 2023)
// - by crop_id & variety
// - by irrigated & organic (order: null, false, true)
export function compareHarvestAggregationKeys(a: HarvestKey, b: HarvestKey) {
  // Normalize to a HarvestAggregationKey.
  a = getHarvestKey(a);
  b = getHarvestKey(b);
  const boolToStr = (x: boolean | null) => (x === true ? '2' : x === false ? '1' : '0');
  return (
    cmp(b.harvest_year ?? '', a.harvest_year ?? '') ||
    cmp(a.crop_id ?? '', b.crop_id ?? '') ||
    cmp(a.variety ?? '', b.variety ?? '') ||
    cmp(boolToStr(a.irrigated), boolToStr(b.irrigated)) ||
    cmp(boolToStr(a.organic), boolToStr(b.organic))
  );
}

export function harvestAggregationKeyEq(a: null | undefined | HarvestKey, b: null | undefined | HarvestKey) {
  if (a == null || b == null) {
    return a == null && b == null;
  }
  return compareHarvestAggregationKeys(a, b) === 0;
}

export function harvestKeyToStr(key: HarvestKey) {
  return JSON.stringify(getHarvestKey(key));
}

export function strToHarvestKey(str: string): HarvestKey {
  return JSON.parse(str);
}

export type HarvestTemplate = HarvestKey & Partial<Harvest>;

// NOTE: this query should NOT be used on its own, as it will return all harvests matching the key, not just for a given
// farm/field.
export function harvestKeyToPostgrestQuery(x: HarvestKey): PostgrestQueryAnd {
  const and: PostgrestQuery[] = [
    x.harvest_year == null
      ? {column: 'harvest_year', operator: 'is', value: null}
      : {column: 'harvest_year', operator: 'eq', value: x.harvest_year},
    x.crop_id == null
      ? {column: 'crop_id', operator: 'is', value: null}
      : {column: 'crop_id', operator: 'eq', value: x.crop_id},
    x.irrigated == null
      ? {column: 'irrigated', operator: 'is', value: null}
      : {column: 'irrigated', operator: 'eq', value: x.irrigated},
    x.organic == null
      ? {column: 'organic', operator: 'is', value: null}
      : {column: 'organic', operator: 'eq', value: x.organic},
    x.variety == null
      ? {column: 'variety', operator: 'is', value: null}
      : {column: 'variety', operator: 'eq', value: x.variety},
  ];
  return {and};
}

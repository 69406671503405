import {useQuery} from '@tanstack/react-query';
import {round, sum} from 'lodash';
import mapboxgl, {FillLayer} from 'mapbox-gl';
import percentile from 'percentile';
import {RefObject, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {
  LinearBrownScale,
  MIN_ENTITY_FEATURES_ZOOM,
  getColorSteps,
  mapStatsSourceLayer,
  mapStatsSourceName,
} from '../../../src/constants/colors';
import {MapLayers} from '../../../src/layers/map-layers';
import {GetMapStatsRow, HarvestYear, getMapStats} from '../../../src/models/interfaces';
import {getFilters} from '../../../src/selectors/filters';
import {filterNulls} from '../../../src/util/arr-util';
import {filtersToRequest} from '../../../src/util/req-util';
import {PD} from '../../../src/util/types';
import {useApis} from '../apis/ApisContext';
import {getLastZoom} from '../redux/selectors';
import {layerConfMapping} from './layers-sources';

// TODO(savv): add a selector for other variables (num_fields, etc) in the MapToolbox, and wire it through to here.

// TODO(savv): The map can be hard to read if there's not a lot of data on it; consider disabling it if the sum of
//  fields in all regions is smaller than 10 or so.
//  (Seb) Some if this might be mitigatable by providing an easy option to zoom in on the filtered data.

export type SelectableMapStatValues =
  | keyof Omit<MapStat, 'region_id' | 'estimated_yields'>
  | 'weighted_estimated_yield_t_ha'
  | 'straight_estimated_yield_t_ha';

// This hook controls commune (aka regional) maps; these work by:
// * using a vector tile source, showing boundaries for all communes of interest, globally
// * downloading the stats per commune from get_map_stats();
// * joining the data client-side using mapbox's setFeatureState.
export function useMapStats(showEntityFeatures: boolean) {
  const {authedFetcher} = useApis();
  const filters = useSelector(getFilters),
    lastZoom = useSelector(getLastZoom);
  const enabled = lastZoom < MIN_ENTITY_FEATURES_ZOOM && showEntityFeatures;
  const {data, isLoading} = useQuery(
    ['getMapStats', filters],
    () => getMapStats(authedFetcher, filtersToRequest(filters)).then(processMapStats),
    {enabled},
  );
  return {enabled, isLoading: enabled && isLoading, ...data};

  // Reaggregates map stats by region_id (in case of multiple crops or years), and returns the 10th and 90th percentile
  // which is used to calculate the color scale.
  // IMPORTANT: Make sure that processMapStats is called within useQuery to make use of the caching provided by
  // react-query.
  function processMapStats(stats: GetMapStatsRow[] = []) {
    const statsByRegion: PD<MapStat> = {};
    for (const stat of stats) {
      if (!stat.region_id) {
        continue;
      }

      const regionStat = statsByRegion[stat.region_id];
      if (regionStat) {
        regionStat.total_area_ha = sumValue(regionStat, stat, 'total_area_ha');
        regionStat.num_samples = sumValue(regionStat, stat, 'num_samples');
        regionStat.num_fields = sumValue(regionStat, stat, 'num_fields');
        regionStat.estimated_yields.push({
          harvest_year: stat.harvest_year,
          crop_id: stat.crop_id,
          weighted_estimated_yield_t_ha: stat.weighted_estimated_yield_t_ha,
          straight_estimated_yield_t_ha: stat.straight_estimated_yield_t_ha,
        });
      } else {
        statsByRegion[stat.region_id] = {
          ...stat,
          avg_field_area_ha: null,
          estimated_yields: [
            {
              crop_id: stat.crop_id,
              harvest_year: stat.harvest_year,
              weighted_estimated_yield_t_ha: stat.weighted_estimated_yield_t_ha,
              straight_estimated_yield_t_ha: stat.straight_estimated_yield_t_ha,
            },
          ],
        };
      }
    }

    // Calculate the average field size for each region.
    for (const stat of Object.values(statsByRegion)) {
      if (stat?.total_area_ha && stat?.num_fields != null && stat?.num_fields > 0) {
        stat.avg_field_area_ha = round(stat.total_area_ha / stat?.num_fields, 2);
      }
    }

    function sumValue(
      regionStat: MapStat,
      stat: GetMapStatsRow,
      value: keyof Pick<GetMapStatsRow, 'total_area_ha' | 'num_fields' | 'num_samples'>,
    ) {
      // Keep null regions null, until we encounter a value, so that we hide them from the map.
      return regionStat[value] == null && stat[value] == null ? null : (regionStat[value] ?? 0) + (stat[value] ?? 0);
    }

    const localStats = Object.values(statsByRegion) as MapStat[];
    const lowMapStats: LowHighMapStat = {
      region_id: null,
      total_area_ha: 0,
      num_fields: 0,
      num_samples: 0,
      avg_field_area_ha: 0,
      weighted_estimated_yield_t_ha: 0,
      straight_estimated_yield_t_ha: 0,
    };
    const highMapStats: LowHighMapStat = {
      region_id: null,
      total_area_ha: 0,
      num_fields: 0,
      num_samples: 0,
      avg_field_area_ha: 0,
      weighted_estimated_yield_t_ha: 0,
      straight_estimated_yield_t_ha: 0,
    };
    const sumMapStats: SumMapStat = {
      region_id: null,
      total_area_ha: 0,
      num_fields: 0,
      num_samples: 0,
    };

    // We are taking the 10th and 90th percentile here, mapping them and all percentiles in between to our 9 item color
    // scale.
    const total_area_ha = percentile([10, 90], filterNulls(localStats.map(x => x.total_area_ha))) as number[];
    lowMapStats.total_area_ha = total_area_ha[0];
    highMapStats.total_area_ha = total_area_ha[1];
    sumMapStats.total_area_ha = sum(localStats.map(x => x.total_area_ha));

    // We simply pick the first entry from *_estimated_yields. If there is more than one entry, this stat won't be
    // selectable for the user. And if the user sets exactly one year + crop_id each, there will only be one entry left.
    const weighted_estimated_yields = percentile(
      [10, 90],
      filterNulls(localStats.map(x => x.estimated_yields[0].weighted_estimated_yield_t_ha)),
    ) as number[];
    lowMapStats.weighted_estimated_yield_t_ha = weighted_estimated_yields[0];
    highMapStats.weighted_estimated_yield_t_ha = weighted_estimated_yields[1];

    const straight_estimated_yields = percentile(
      [10, 90],
      filterNulls(localStats.map(x => x.estimated_yields[0].straight_estimated_yield_t_ha)),
    ) as number[];
    lowMapStats.straight_estimated_yield_t_ha = straight_estimated_yields[0];
    highMapStats.straight_estimated_yield_t_ha = straight_estimated_yields[1];

    const avg_field_area_ha = percentile([10, 90], filterNulls(localStats.map(x => x.avg_field_area_ha))) as number[];
    lowMapStats.avg_field_area_ha = avg_field_area_ha[0];
    highMapStats.avg_field_area_ha = avg_field_area_ha[1];

    const num_fields = percentile([10, 90], filterNulls(localStats.map(x => x.num_fields))) as number[];
    lowMapStats.num_fields = num_fields[0];
    highMapStats.num_fields = num_fields[1];
    sumMapStats.num_fields = sum(localStats.map(x => x.num_fields));

    const num_samples = percentile([10, 90], filterNulls(localStats.map(x => x.num_samples))) as number[];
    lowMapStats.num_samples = num_samples[0];
    highMapStats.num_samples = num_samples[1];
    sumMapStats.num_samples = sum(localStats.map(x => x.num_samples));
    return {stats: localStats, lowMapStats, highMapStats, sumMapStats};
  }
}

export function useCanShowMapStats(selected: SelectableMapStatValues) {
  const filters = useSelector(getFilters);
  return selected === 'weighted_estimated_yield_t_ha' || selected === 'straight_estimated_yield_t_ha'
    ? filters.harvest_year.length === 1 && filters.crop_id.length === 1
    : true;
}

export function useMapStatsLayer(
  map: RefObject<undefined | mapboxgl.Map>,
  styledataSignal: string,
  layer: MapLayers,
  showEntityFeatures: boolean,
  selectedMapStat: SelectableMapStatValues,
) {
  const {enabled, stats, isLoading, lowMapStats, highMapStats, sumMapStats} = useMapStats(showEntityFeatures);
  const canShow = useCanShowMapStats(selectedMapStat);

  useEffect(() => {
    if (!map.current || !styledataSignal) {
      return;
    }
    const curSource = map.current.getSource(mapStatsSourceName);
    const curLayer = map.current.getLayer(mapStatsLayerId);
    if (!enabled || !canShow) {
      if (curSource || curLayer) {
        map.current.removeFeatureState({source: mapStatsSourceName, sourceLayer: mapStatsSourceLayer});
        map.current.removeLayer(mapStatsLayerId);
        map.current.removeSource(mapStatsSourceName);
      }
      return;
    }

    if (!curSource) {
      map.current.addSource(mapStatsSourceName, {
        type: 'vector',
        tiles: [location.origin + '/tiles/granular-regions-3/{z}/{x}/{y}.pbf'],
        promoteId: 'region_id',
      });
    }
    const mapStatsLayer = getMapStatsLayer(
      lowMapStats?.[selectedMapStat] ?? 0,
      highMapStats?.[selectedMapStat] ?? 1,
      selectedMapStat,
    );

    // Place before the currently selected layer (e.g. soil moisture) or otherwise before the sample points layer.
    let beforeLayerId = getSelectedLayerId(map, layer) ?? 'samplePoints';
    if (beforeLayerId) {
      // See: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#case for documentation.
      mapStatsLayer.paint!['fill-opacity'] = ['case', ['==', ['feature-state', selectedMapStat], null], 0, 0.3];
    }
    if (curLayer) {
      map.current.setPaintProperty(mapStatsLayerId, 'fill-color', mapStatsLayer.paint!['fill-color']);
      map.current.setPaintProperty(mapStatsLayerId, 'fill-opacity', mapStatsLayer.paint!['fill-opacity']);
      map.current?.moveLayer(mapStatsLayerId, beforeLayerId);
    } else {
      mapStatsLayer.source = mapStatsSourceName;
      mapStatsLayer['source-layer'] = mapStatsSourceLayer;
      map.current.addLayer(mapStatsLayer, beforeLayerId);
    }

    if (!stats) {
      return;
    }

    map.current.removeFeatureState({source: mapStatsSourceName, sourceLayer: mapStatsSourceLayer});
    for (const mapStat of stats) {
      if (!mapStat.region_id) {
        continue;
      }
      map.current.setFeatureState(
        {
          source: mapStatsSourceName,
          sourceLayer: mapStatsSourceLayer,
          id: mapStat.region_id,
        },
        mapStat,
      );
    }
  }, [
    styledataSignal,
    layer,
    showEntityFeatures,
    enabled,
    stats,
    lowMapStats,
    highMapStats,
    selectedMapStat,
    canShow,
    map,
  ]);

  return {isLoading, sumMapStats};
}

export const mapStatsLayerId = 'regional-stats-fill';

// Returns [label, color, opacity]
export function getLinearLabels(min: number, max: number): [string, string, number][] {
  if (min === 0) {
    // Do not use 0 as lower bound, as this will result in the first legend item to be <0,
    // which does not make sense for our use cases.
    min = 0.01;
  }
  if (max < 1) {
    max = 1;
  }
  const steps = getColorSteps(LinearBrownScale, min, max);
  const res: [string, string, number][] = [];
  let prevStep = 0;
  for (let i = 0; i < steps.length - 1; i += 2) {
    const step = round(steps[i + 1] as number, 2),
      color = steps[i] as string;
    const label = i == 0 ? `< ${step}` : `${prevStep} - ${step}`;
    prevStep = step;
    res.push([label, color, 1]);
  }
  res.push([`>= ${round(steps[steps.length - 2] as number, 2)}`, steps[steps.length - 1] as string, 1]);

  return res;
}

function getMapStatsLayer(min: number, max: number, selectedMapStat: SelectableMapStatValues): FillLayer {
  if (max == min) {
    max = min + 0.01;
  }
  return {
    id: mapStatsLayerId,
    type: 'fill',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': ['step', ['feature-state', selectedMapStat], ...getColorSteps(LinearBrownScale, min, max)],
      'fill-antialias': true,
      // Ideally we would use filters instead of opacity, but it doesn't work:
      // https://github.com/mapbox/mapbox-gl-js/issues/8487
      // This adds a lot of complexity in terms changing the cursor based on hover.
      'fill-opacity': ['case', ['==', ['feature-state', selectedMapStat], null], 0, 0.7],
      'fill-outline-color': 'black',
    },
  };
}

export interface MapStat extends Pick<GetMapStatsRow, 'region_id' | 'num_fields' | 'num_samples' | 'total_area_ha'> {
  avg_field_area_ha: number | null;
  estimated_yields: {
    crop_id: string | null;
    harvest_year: HarvestYear | null;
    straight_estimated_yield_t_ha: number | null;
    weighted_estimated_yield_t_ha: number | null;
  }[];
}
export type SumMapStat = Omit<MapStat, 'estimated_yields' | 'avg_field_area_ha'>;
export type LowHighMapStat = Omit<MapStat, 'estimated_yields'> & {
  weighted_estimated_yield_t_ha: number;
  straight_estimated_yield_t_ha: number;
};

// Returns the Mapbox layer id of the currently selected layer, if it's already shown.
function getSelectedLayerId(map: RefObject<undefined | mapboxgl.Map>, layer: MapLayers) {
  if (layer == 'base' || layer == 'satellite') {
    return null;
  }
  if (layer == 'crop-mon') {
    if (map.current?.getLayer('benchmark-yield')) {
      return 'benchmark-yield';
    }
    for (const x of ['predicted-yield', 'historical-yield', 'expected-loss'] as const) {
      const layer = map.current?.getLayer(layerConfMapping[x]?.id);
      if (layer) {
        return layer.id;
      }
    }
  } else {
    return map.current?.getLayer(layerConfMapping[layer]?.id)?.id;
  }
}

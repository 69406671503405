import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {CROP_COLORS} from '../../../src/constants/colors';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {convertFromHectares, getUnitSystem} from '../../../src/selectors/units';
import {ApisContext} from '../apis/ApisContext';
import BarChart from '../report/BarChart';
import {useIsVisible} from '../util/hooks';
import {DashboardCard} from './DashboardCard';
import {groupByCrop} from './SamplesByCrop';
import {useStatsByHarvest} from './queries';

export const HectaresByCrop: React.FC = () => {
  const apis = useContext(ApisContext),
    crops = useSelector(getCrops),
    units = useSelector(getUnitSystem);
  const ref = React.useRef(null);
  const enabled = useIsVisible(ref);
  const {data = [], isFetching} = useStatsByHarvest({enabled});
  const hectaresByCropType = groupByCrop(apis, data, x => x.total_area_ha)
    .map(x => ({
      color: CROP_COLORS[getBaseCrop(crops, x[0].crop_id) ?? 'unknown'],
      key: x[1],
      value: convertFromHectares(x[2], units.areaUnit) ?? 0,
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 7);

  return (
    <div ref={ref}>
      <DashboardCard long title={apis.t('AreaCropTitle')} loading={isFetching} hasData={data.length > 0}>
        <BarChart
          width={300}
          height={300}
          id="area-crop-chart"
          data={hectaresByCropType}
          formatValue={x => apis.t({type: 'AreaUnit', unit: units.areaUnit, val: x})}
        />
      </DashboardCard>
    </div>
  );
};

import {createSelector} from 'reselect';
import {Flags, getEnabledFlags} from '../feature-flags';
import {IndexedCrops} from '../redux/reducers/crops';
import {getCustomers} from '../util/user-group-util';
import {getCrops} from './crops';
import {getCurUserEmail, getTodaysDate} from './dbMeta';
import {StateI} from './stateI';
import {UnitSystem, getCountryCodeGroups, getUnitSystem} from './units';
import {getUserGroups} from './userGroups';

// Note: we use arrays instead of sets because they are JSON-serializable.
export interface UserConfig {
  flags: Flags[];
  units: UnitSystem;
  countryGroups: string[];
  crops: IndexedCrops;
  todaysDateUtc: string;
  customers: string[];
}

const getCustomerSelector = createSelector([getCurUserEmail, getUserGroups], (email, groups) => [
  ...getCustomers(email, groups),
]);

export const getUserConfig: (state: Pick<StateI, 'dbMeta' | 'crops'>) => UserConfig = createSelector(
  [getEnabledFlags, getUnitSystem, getCountryCodeGroups, getCrops, getTodaysDate, getCustomerSelector],
  ([...flags], units, countryGroups, crops, todaysDateUtc, customers) => ({
    flags,
    units,
    countryGroups,
    crops,
    todaysDateUtc,
    customers,
  }),
);

import {BranchesOutlined} from '@ant-design/icons';
import {Space, Tag, Tooltip} from 'antd';
import React, {FC} from 'react';
import {useApis} from '../apis/ApisContext';
import {AdminUserEntity, toName, useAdminUserGroupFilter, useTransitiveUserGroups} from '../util/admin-util';

export const AdminMembershipTags: FC<{user: AdminUserEntity; highlight?: string[]}> = ({user, highlight}) => {
  const transitiveAdminGroups = useAdminUserGroupFilter([user]);

  return (
    <span>
      {user.memberships.map(m => (
        <MembershipTag
          key={m.user_group.user_group + m.membership_type}
          {...m}
          highlight={(highlight ?? []).some(
            h =>
              h.trim().length > 0 &&
              (toName(m.user_group).includes(h) ||
                m.user_group.user_group.includes(h) ||
                m.membership_type.includes(h)),
          )}
          // Make it visually clear that you can't modify user-admin rights, and groups you have full but not user-admin rights.
          readonly={
            transitiveAdminGroups.every(g => g.user_group != m.user_group.user_group) ||
            m.membership_type == 'user-admin'
          }
        />
      ))}
    </span>
  );
};

export const MembershipTag: FC<
  AdminUserEntity['memberships'][number] & {
    highlight?: boolean;
    readonly?: boolean;
  }
> = ({user_group, membership_type, highlight, readonly}) => {
  const {t} = useApis();
  const {grantors} = useTransitiveUserGroups();
  const transitiveGroups = grantors[user_group.user_group] ?? [];
  return (
    <Tooltip
      title={
        transitiveGroups.length > 1
          ? transitiveGroups
              .map(g => toName(g))
              .sort()
              .join(', ')
          : null
      }>
      <Tag color={highlight ? 'green' : 'default'} style={{color: readonly ? '#bbb' : 'initial'}}>
        <Space>
          <span>
            {toName(user_group)}: <i>{t(membership_type)}</i>
          </span>
          {transitiveGroups.length > 1 ? <BranchesOutlined /> : null}
        </Space>
      </Tag>
    </Tooltip>
  );
};

// An element for selecting values out of an enum field.
import {Select} from 'antd';
import React, {ChangeEvent} from 'react';
import {FormyComponent} from '../../../src/Formy/FormyComponent';
import {FormyEnumProps, FormyMultiEnumProps} from '../../../src/Formy/FormyEnum';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';

export class FormyEnum<O extends string, F extends {[P in Fk]: null | O}, Fk extends keyof F> extends FormyComponent<
  F,
  Fk,
  FormyEnumProps<O, F, Fk>
> {
  static contextType = ApisContext;
  context!: Apis;

  onChange = (x: ChangeEvent<HTMLSelectElement>) => {
    const value = x.target.value as O;
    this.handleChange(value as F[Fk]);
    this.handleBlur();
  };

  render() {
    const {selectMsg, options} = this.props;
    return (
      <select
        data-testid={this.props.field}
        className={`formy-item-style ${this.error ? 'formy-item-error' : ''}`}
        disabled={this.mode === 'view'}
        placeholder={this.context.t(selectMsg || '-')}
        value={this.value != null ? this.value : ('select' as any)}
        onChange={this.onChange}
        style={this.props.style}
        onBlur={this.handleBlur}>
        <option key="select" value="">
          {this.context.t(selectMsg || '-')}
        </option>
        {options.map(([k, v]) => (
          <option key={k} value={k}>
            {v}
          </option>
        ))}
      </select>
    );
  }
}

export class FormyMultiEnum<O, F extends {[P in Fk]: O[]}, Fk extends keyof F> extends FormyComponent<
  F,
  Fk,
  FormyMultiEnumProps<O, F, Fk>
> {
  static contextType = ApisContext;
  context!: Apis;

  render() {
    const {selectMsg, style} = this.props;
    return (
      <Select<string>
        style={style}
        data-testid={`Select-${String(this.props.field)}`}
        className={`formy-input formy-item-style ${this.error ? 'formy-item-error' : ''}`}
        mode="multiple"
        maxTagCount={this.props.maxTagCount ?? 1}
        disabled={this.mode === 'view'}
        placeholder={this.context.t(selectMsg)}
        value={this.value != null ? this.value : ('select' as any)}
        onChange={x => {
          this.handleChange(x as any);
          this.handleBlur();
        }}>
        {(this.props.options ?? []).map(([k, v]) => (
          <Select.Option key={`option-${k}`} value={String(k)}>
            {v}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

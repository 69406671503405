import {Feature, Geometry, Point, Polygon} from 'geojson';
import {AnomalyZones, InterfieldZones, IntrafieldZones} from '../constants/colors';
import {HarvestCrop} from './interfaces';

export interface EntityGeoProps {
  type: 'farm' | 'field' | 'sample';

  // TODO(savv): Use feature.id instead of the following, as the type implies adequately the type of id. Unfortunately,
  //  this is difficult due to https://github.com/mapbox/mapbox-gl-js/issues/2716 which doesn't retain feature.id.
  //  Note that FormyLocationSelector::onPressFeatures depends on these, so removing them will require changing that
  //  logic.
  farm_id?: string;
  field_id?: string;
  sample_id?: string;
  crop?: HarvestCrop;
  cropColor?: string;
  label?: string;
}

export function isEntityGeoProps(x: any): x is EntityGeoProps {
  return typeof x == 'object' && (x?.type == 'farm' || x?.type == 'field' || x?.type == 'sample');
}

export function isPointFeature(x: Feature): x is Feature<Point> {
  return x.geometry.type == 'Point';
}

export function isPolygonFeature(x: Feature): x is Feature<Polygon> {
  return x.geometry.type == 'Polygon';
}

export function isPointOrPolygonFeature(x: Feature): x is Feature<Point | Polygon> {
  return isPointFeature(x) || isPolygonFeature(x);
}

export function isEntityGeoPropsFeature(x: Feature): x is Feature<Geometry, EntityGeoProps> {
  return isEntityGeoProps(x.properties);
}

export interface LayerGeoProps {
  shape_type: IntrafieldZones | InterfieldZones | AnomalyZones;
}

export interface CropMonGeoProps {
  shape_type: string;
  value: null | number;
  benchmark_yield_t_ha: null | number;
}

import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {GRADIENT_PALETTE} from '../../../src/constants/colors';
import {CropCondition} from '../../../src/models/interfaces';
import {getUnitSystem} from '../../../src/selectors/units';
import {ApisContext} from '../apis/ApisContext';
import BarChart from '../report/BarChart';
import {useIsVisible} from '../util/hooks';
import {DashboardCard} from './DashboardCard';
import {useStatsByCondition} from './queries';

export const HectaresByCondition: React.FC = () => {
  const {t} = useContext(ApisContext),
    units = useSelector(getUnitSystem);
  const ref = React.useRef(null);
  const enabled = useIsVisible(ref);
  const {data = [], isFetching} = useStatsByCondition({enabled});
  const cropConditionBreakdown = data
    .filter(x => x.condition != null)
    .map(x => ({
      ...x,
      index: CropCondition.indexOf(x.condition!),
    }))
    .sort((a, b) => a.index - b.index)
    .map(x => ({
      key: t(x.condition!),
      color: GRADIENT_PALETTE[x.index],
      value: x.area_ha ?? 0,
    }));

  return (
    <div ref={ref}>
      <DashboardCard
        long
        title={t('CropConditionBreakdown')}
        loading={isFetching}
        hasData={cropConditionBreakdown.length > 0}>
        <BarChart
          width={300}
          height={300}
          id="crop-cond-chart"
          data={cropConditionBreakdown}
          formatValue={x => t({type: 'AreaUnit', unit: units.areaUnit, val: x})}
        />
      </DashboardCard>
    </div>
  );
};

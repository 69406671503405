import {CaretDownOutlined, CaretRightOutlined, DeleteOutlined} from '@ant-design/icons';
import {Button, Tag} from 'antd';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {resetAllDbFilters} from '../../../src/redux/actions/filters';
import {areFiltersEmpty, getFilters} from '../../../src/selectors/filters';
import {ApisContext, useApis} from '../apis/ApisContext';
import {
  AdjusterFilter,
  CauseFilter,
  ClaimFilter,
  ConditionFilter,
  CropFilter,
  FarmFilter,
  NDVIValueFilter,
  PolicyFilter,
  RegionFilter,
  UserGroupFilter,
  VisitTypeFilter,
  YearFilter,
} from './DataFilter';
import DateRangeFilter from './DateRangeFilter';
import './Filters.css';

export default function Filters() {
  const {t} = useContext(ApisContext);
  const [minimized, setMinimized] = useState(true);
  const dispatch = useDispatch();
  useFilterLogging();
  const toggleMinimized = useCallback(() => setMinimized(s => !s), []);
  const hasFilters = !areFiltersEmpty(useSelector(getFilters));
  const handleReset = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation(); // Else we also trigger toggleMinimized from parent node.
      return dispatch(resetAllDbFilters());
    },
    [dispatch],
  );

  return (
    <div className={'filters' + (minimized ? ' minimized' : '')}>
      <div className="filters-expand">
        <Button
          type="text"
          data-testid="filter-toggle"
          icon={minimized ? <CaretRightOutlined /> : <CaretDownOutlined />}
          onClick={toggleMinimized}></Button>
      </div>
      <div className="filters-list" onClick={minimized ? toggleMinimized : () => null}>
        {minimized && !hasFilters && <Tag>{t('Filters')}</Tag>}
        <RegionFilter minimized={minimized} />
        <PolicyFilter minimized={minimized} />
        <FarmFilter minimized={minimized} />
        <CropFilter minimized={minimized} />
        <YearFilter minimized={minimized} />
        <ConditionFilter minimized={minimized} />
        <CauseFilter minimized={minimized} />
        <AdjusterFilter minimized={minimized} />
        <UserGroupFilter minimized={minimized} />
        <DateRangeFilter label={t('Sample')} field="added_on" minimized={minimized} />
        <DateRangeFilter
          label={t('Emergence')}
          field="emergence_date"
          minimized={minimized}
          allowEmpty={[true, true]}
        />
        <DateRangeFilter label="NDVI" field="max_ndvi_date" minimized={minimized} allowEmpty={[true, true]} />
        <NDVIValueFilter minimized={minimized} />
        <ClaimFilter minimized={minimized} />
        <VisitTypeFilter minimized={minimized} />
        {hasFilters && <Button type="text" icon={<DeleteOutlined />} title={t('Reset')} onClick={handleReset}></Button>}
      </div>
    </div>
  );
}

function useFilterLogging() {
  const {analytics, clock} = useApis();
  const filters = useSelector(getFilters);
  useEffect(() => {
    const id = clock.setTimeout(() => {
      analytics.logEvent({event_name: 'global-filters', props: filters});
    }, 3000);
    return () => {
      clock.clearTimeout(id);
    };
  }, [analytics, clock, filters]);
}

import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {WithChildren} from '../../../src/util/react-util';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import Filters from './Filters';

interface AppHeaderProps extends RouteComponentProps {
  className?: string;
  showFilters: boolean;
}

class AppHeader extends React.PureComponent<WithChildren & AppHeaderProps> {
  static contextType = ApisContext;
  context!: Apis;

  render() {
    return (
      <header className={`header no-print ${this.props.className || ''}`}>
        {this.props.showFilters && <Filters />}
        {this.props.children}
      </header>
    );
  }
}

export default withRouter(AppHeader);

import {FormyErrors} from '../Formy';
import {isFormyErroneous} from '../Formy/Formy';
import {HarvestYear} from '../models/interfaces';

function hasAtleastOne(v: {[k: string]: undefined | boolean}) {
  for (const k in v) {
    if (v[k]) {
      return true;
    }
  }

  return false;
}

export function validateReportConfig(x: ReportConfig): FormyErrors<ReportConfig> {
  return {
    farm_id: !x.farm_id,
    harvest_year: !x.harvest_year,
    sampleDates: !x.sampleDates,
    cropIds: !hasAtleastOne(x.cropIds),
    sendToUser: x.requireEmail && !x.sendToFarmer && !x.sendToUser,
    sendToFarmer: x.requireEmail && !x.sendToFarmer && !x.sendToUser,
  };
}

export class ReportConfig {
  // The farm, year, and dates for which this report will be prepared.
  farm_id: null | string = null;
  harvest_year: null | HarvestYear = null;
  sampleDates: {[sample_date: string]: boolean} = {};
  cropIds: {[crop_id: string]: boolean} = {};
  requireEmail: boolean = true;
  sendToUser: boolean = false;
  sendToFarmer: boolean = false;

  // The following variables are filled by updateReportWithOptions or updateReportFromDb.
  farmer_email: null | string = null;
  // numFields is only dependent on the farm_id & harvest_year; deselecting cropIds will not change it.
  numFields: number = 0;

  // Throws error if called for an invalid ReportConfig.
  getUrlParams(): [string, string][] {
    if (!this.farm_id) {
      throw new Error('no farm_id');
    }
    if (!this.harvest_year) {
      throw new Error('no harvest_year');
    }

    const params: [string, string][] = [
      ['farm_id', this.farm_id],
      ['harvest_year', this.harvest_year],
    ];
    for (const k in this.sampleDates) {
      if (this.sampleDates[k]) {
        params.push(['sample_date', k]);
      }
    }
    for (const k in this.cropIds) {
      if (this.cropIds[k]) {
        params.push(['crop_id', k]);
      }
    }

    return params;
  }

  isValid(): boolean {
    return !isFormyErroneous(validateReportConfig(this));
  }
}

import queryString from 'query-string';

export function parseQueryParams(s: string): [string, string][] {
  const queryParams = queryString.parse(s);
  const res: [string, string][] = [];
  for (const key in queryParams) {
    const paramValues = queryParams[key];
    const values: string[] = typeof paramValues == 'string' ? [paramValues] : paramValues == null ? [''] : paramValues;
    for (const value of values) {
      res.push([key, value]);
    }
  }

  return res;
}

import {TeamOutlined} from '@ant-design/icons';
import {Col, PageHeader, Row} from 'antd';
import React from 'react';
import {useApis} from '../apis/ApisContext';
import {SubsidyConfig} from '../components/SubsidyConfig';
import './UserAdminPage.css';

export const SettingsPage: React.FC = () => {
  const {t} = useApis();
  return (
    <div className="admin-page">
      <Row>
        <Col span={24}>
          <PageHeader title={t('Settings')} avatar={{icon: <TeamOutlined />}} />
        </Col>
      </Row>
      <SubsidyConfig />
    </div>
  );
};

import * as Sentry from '@sentry/browser';
import {FailedToFetch} from '../../../src/FetcherFunc';

export function reportErr(err: unknown, section?: string, extra?: Record<string, string>) {
  extra = extra || {};
  if (typeof err == 'object' && typeof (err as any).extra == 'object') {
    extra = {...(err as any).extra, ...extra};
  }

  if (!(err instanceof FailedToFetch)) {
    Sentry.captureException(err, {tags: {section}, extra});
  }

  console.error(`(${section})`, err);
}

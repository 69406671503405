import {useCallback} from 'react';
import {BadStatusResponse} from '../../../src/FetcherFunc';
import {KeycloakPublicServerApiUser} from '../../../src/models/keycloak';
import {remove as arrayRemove} from '../../../src/util/arr-util';
import {useApis} from '../apis/ApisContext';

// TODO(seb): Move this to hooks.ts and make sure test_UserAdminPage.tsx works with it. For some reason I would get
//  test (but not runtime) issues when this hook was imported from hooks.ts.
export function useKeycloak(): {
  add: (user: KeycloakPublicServerApiUser) => Promise<true | {message: string}>;
  remove: (user_id: string) => Promise<true | {message: string}>;
} {
  const {authedFetcher, t} = useApis();
  const add = useCallback(
    async (user: KeycloakPublicServerApiUser) => {
      try {
        await authedFetcher({
          method: 'POST',
          path: 'api2/keycloak/user',
          request_body: JSON.stringify(user),
        });
        return true;
      } catch (err) {
        const message: string = [t('UnknownErrorOccurred'), err instanceof BadStatusResponse && err.json?.message]
          .filter(arrayRemove.nulls)
          .join(': ');
        return {message};
      }
    },
    [authedFetcher, t],
  );
  const remove = useCallback(
    async (user_id: string) => {
      try {
        await authedFetcher({
          method: 'DELETE',
          path: 'api2/keycloak/user/' + user_id,
        });
        return true;
      } catch (err) {
        const message: string = [t('UnknownErrorOccurred'), err instanceof BadStatusResponse && err.json?.message]
          .filter(arrayRemove.nulls)
          .join(': ');
        return {message};
      }
    },
    [authedFetcher, t],
  );
  return {add, remove};
}

import {AutoComplete} from 'antd';
import React from 'react';
import {FormyI} from '../../../src/Formy';
import {PlaceAutocompleteResult, getLocationByPlaceId, placeAutocomplete} from '../../../src/address-completion';
import {LngLat} from '../../../src/geo';
import {getCountryCodeGroups} from '../../../src/selectors/units';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';

interface AddressOption {
  value: string;
  item: PlaceAutocompleteResult;
}

interface AddressAutoCompleteProps<F extends {address: null | string; farm_location: null | LngLat}> {
  // As the user is typing, this callback will be called with the user's entry as the reference.
  // If the user makes a selection, or if the user leave the box and a match is found, a policy_id will also be
  // "returned".
  formy: FormyI<F>;
}

interface AddressAutoCompleteState {
  shownOptions: AddressOption[];
}

export class FormyAddress<F extends {address: null | string; farm_location: null | LngLat}> extends React.PureComponent<
  AddressAutoCompleteProps<F>,
  AddressAutoCompleteState
> {
  static contextType = ApisContext;
  context!: Apis;
  get_address = this.props.formy.watchValue('address', this);
  get_address_error = this.props.formy.watchError('address', this);

  ref: null | {blur: () => void} = null;
  state: AddressAutoCompleteState = {shownOptions: []};

  onSelect = async (_: string, x: AddressOption) => {
    await this.props.formy.getChangeHandler('address')(x.value as F['address']);
    try {
      if (x.item.place_id) {
        const location = await getLocationByPlaceId(this.context.authedFetcher, x.item.place_id);
        this.props.formy.getChangeHandler('farm_location')(location as F['farm_location']);
      }
    } catch (e) {
      console.warn("Couldn't fetch location for place", x.item, '; error:', e);
    }
    this.ref?.blur();
  };

  onChange = (q: string) => {
    this.props.formy.getChangeHandler('address')(q as F['address']);
    this.props.formy.getChangeHandler('farm_location')(null as F['farm_location']);

    if (!q) {
      this.setState({shownOptions: []});
    }

    const countries = getCountryCodeGroups(this.context.store.getState());
    placeAutocomplete(this.context.authedFetcher, q, this.context.locale, undefined, countries)
      .then(res => this.setState({shownOptions: res.map(item => ({value: item.description, item}))}))
      .catch(e => console.warn("Couldn't fetch address for", q, '; error:', e));
  };

  onBlur = () => {
    // Implicitly accept singular address, so as to set the farm location.
    if (this.state.shownOptions.length == 1) {
      this.onSelect('', this.state.shownOptions[0]).catch(console.error);
    }
    this.props.formy.getBlurHandler('address')();
  };

  render() {
    let className = 'formy-item-style';
    if (this.get_address_error()) {
      className += ' formy-item-error';
    }
    return (
      <span className="formy-text-str-deduping">
        <AutoComplete
          data-testid="FormyAddress"
          ref={ref => (this.ref = ref)}
          className={className}
          options={this.state.shownOptions}
          onSelect={this.onSelect as any}
          onChange={this.onChange}
          onBlur={this.onBlur}
          showSearch
          allowClear
          value={this.get_address() ?? ''}
          placeholder={this.context.t('FarmAddress')}
        />
      </span>
    );
  }
}

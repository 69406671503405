import {message} from 'antd';
import {AnyAction} from 'redux';
import {CommonApis} from '../../../src/CommonApis';
import {StateOrm, getFetcherStateOrm} from '../../../src/gt-pack/stateOrm';
import {MutationQueueItem} from '../../../src/redux/reducers/db';
import {ThunkStore} from '../../../src/redux/types';
import {StateI} from '../../../src/selectors/stateI';
import {reportErr} from '../util/err';

export function getStateOrm<S extends Omit<StateI, 'db'>, A extends AnyAction>(
  store: ThunkStore<S, A>,
  apis: CommonApis,
): StateOrm {
  async function onCommitError(item: MutationQueueItem, err: unknown) {
    reportErr(err, 'onCommitError');
    console.error('Unable to sync mutations:', err);
    message.error(apis.t('UnableToUpload'));
  }

  return getFetcherStateOrm(store, apis, onCommitError);
}

import equal from '../../../src/fast-deep-equal';
import {Bbox, LngLat} from '../../../src/geo';

interface ChangeMapLocationAction {
  type: 'CHANGE_MAP_LOCATION';
  lastLocation: LngLat;
  lastZoom: number;
  lastBounds: Bbox;
}

// MapState tracks the user as they pan around, so that we can provide continuity in the map viewport, across different
// screens. It is meant as a way to make a map set its location, when it initializes, but it does not affect
// existing maps, as this can lead to jarring behavior.
export type MapState = {
  lastLocation: null | LngLat;
  lastZoom: number;
  lastBounds: null | Bbox;
};

const initialState: MapState = {
  lastLocation: null,
  lastZoom: 12,
  lastBounds: null,
};

export default function map(state = initialState, {type, lastLocation, lastZoom, lastBounds}: MapActions): MapState {
  switch (type) {
    case 'CHANGE_MAP_LOCATION':
      if (
        !equal(state.lastLocation, lastLocation) ||
        state.lastZoom != lastZoom ||
        !equal(state.lastBounds, lastBounds)
      ) {
        return {lastLocation, lastZoom, lastBounds};
      } else {
        return state;
      }
    default:
      return state;
  }
}

export type MapActions = ChangeMapLocationAction;

export function setLastMapViewport(lastLocation: LngLat, lastZoom: number, lastBounds: Bbox): ChangeMapLocationAction {
  return {type: 'CHANGE_MAP_LOCATION', lastLocation: lastLocation, lastZoom: lastZoom, lastBounds};
}

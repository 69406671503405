import {useQuery} from '@tanstack/react-query';
import React, {ChangeEvent, useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import equal from '../../../src/fast-deep-equal';
import {interyieldCropUserGroups} from '../../../src/feature-flags';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {getInteryieldDate} from '../../../src/layers/canonical-date';
import {
  LayerParams,
  LayerParamsInteryield,
  interyieldComboDesc,
  parseInteryieldCombo,
} from '../../../src/layers/layer-params';
import {HarvestYear, getStatsByHarvest} from '../../../src/models/interfaces';
import {INTERYIELD_CROP_IDS, InteryieldCropIds} from '../../../src/models/interyield';
import {dbFiltersInitialState} from '../../../src/redux/reducers/filters';
import {getCrops} from '../../../src/selectors/crops';
import {getTodaysDate} from '../../../src/selectors/dbMeta';
import {getUserGroupSet} from '../../../src/selectors/userGroups';
import {cropDesc} from '../../../src/text/desc';
import cmp from '../../../src/util/cmp';
import {parseDate} from '../../../src/util/date-util';
import {filtersToRequest} from '../../../src/util/req-util';
import {useApis} from '../apis/ApisContext';
import {MapLegend} from './MapLegend';
import './MapToolbox.css';

interface InteryieldMapToolboxProps {
  canonical_date: null | string;
  layerParams: null | LayerParamsInteryield;
  onCanonicalDateChanged: (date: null | string) => void;
  onLayerParamsChanged: (layerParams: null | LayerParams) => void;
}

export default function InteryieldMapToolbox({
  canonical_date,
  layerParams,
  onCanonicalDateChanged,
  onLayerParamsChanged,
}: InteryieldMapToolboxProps) {
  const {t, clock, store, authedFetcher} = useApis();
  const crops = useSelector(getCrops),
    todaysDateUtc = useSelector(getTodaysDate);
  const {data} = useQuery(['get_stats_by_harvest', dbFiltersInitialState], () =>
    getStatsByHarvest(authedFetcher, filtersToRequest(dbFiltersInitialState)),
  );
  const userGroups = useSelector(getUserGroupSet);

  const cropYearMapping = useMemo(() => {
    const interyieldCombos = new Set<string>(); // A set containing crop/season/year strings, e.g. 'wheat/winter/2021'.
    for (const {crop_id, harvest_year} of data ?? []) {
      if (
        crop_id &&
        INTERYIELD_CROP_IDS.includes(crop_id as InteryieldCropIds) &&
        harvest_year &&
        todaysDateUtc &&
        getInteryieldDate(parseDate(todaysDateUtc)!, crop_id, harvest_year) != null &&
        interyieldCropUserGroups[crop_id as InteryieldCropIds].filter(value => userGroups.has(value)).length > 0
      ) {
        interyieldCombos.add(`${crop_id}+${harvest_year}`);
      }
    }
    const res = Array.from(interyieldCombos);
    res.sort((a, b) => {
      const comboA = parseInteryieldCombo(a),
        comboB = parseInteryieldCombo(b);
      if (comboA && comboB && comboA[1] != comboB[1]) {
        return cmp(comboB[1], comboA[1]); // reverse chronological order
      } else {
        return cmp(interyieldComboDesc(t, crops, a), interyieldComboDesc(t, crops, b)); // alphabetical order
      }
    });

    const cropYearMapping: Record<string, string[]> = {};
    for (const combo of res) {
      const [cropId, year] = parseInteryieldCombo(combo) || [];
      if (cropId && year) {
        if (!cropYearMapping[cropId]) {
          cropYearMapping[cropId] = [];
        }
        cropYearMapping[cropId].push(year);
      }
    }

    return cropYearMapping;
  }, [crops, data, t, todaysDateUtc, userGroups]);

  const availableCropIds = Object.keys(cropYearMapping);
  const availableYears = layerParams?.params[0] ? cropYearMapping[layerParams?.params[0]] || [] : [];

  const updateInteryieldCombo = useCallback(() => {
    const combo = layerParams?.params;
    if (combo) {
      store.dispatch({
        type: 'SET_DB_FILTER',
        filters: {
          crop_id: [combo[0]],
          harvest_year: [combo[1]],
        },
      });
    }
    const date = combo && getInteryieldDate(new Date(clock.now()), ...combo);
    if (date != canonical_date) {
      onCanonicalDateChanged(date ?? null);
    }
  }, [canonical_date, clock, layerParams?.params, onCanonicalDateChanged, store]);

  useEffect(() => {
    if (layerParams == null) {
      const crop_id = availableCropIds && availableCropIds[0];
      const newAvailableYears = availableCropIds && cropYearMapping[availableCropIds[0]];
      const year = newAvailableYears && (newAvailableYears[0] as HarvestYear);

      if (year && crop_id) {
        const date = getInteryieldDate(new Date(clock.now()), crop_id, year);
        if (date != canonical_date) {
          onCanonicalDateChanged(date ?? null);
        }
        onLayerParamsChanged({layer_type: 'interyield', params: [crop_id, year]});
      }
    } else {
      updateInteryieldCombo();
    }
    // We stringify the layer params so that when we set them in the above function, we don't retrigger useEffect.
  }, [
    availableCropIds,
    canonical_date,
    clock,
    cropYearMapping,
    layerParams,
    onCanonicalDateChanged,
    onLayerParamsChanged,
    updateInteryieldCombo,
  ]);

  const switchInteryieldCrop = useCallback(
    (x: ChangeEvent<HTMLSelectElement>) => {
      const newAvailableYears = cropYearMapping[x.target.value];
      // Switch to the latest available year if there are no harvests for the new crop with the current year.
      const year =
        layerParams?.params[1] && newAvailableYears.includes(layerParams.params[1])
          ? layerParams.params[1]
          : newAvailableYears[0];

      const combo: LayerParamsInteryield = {
        layer_type: 'interyield',
        params: [x.target.value, year as HarvestYear],
      };
      if (!equal(combo, layerParams)) {
        onLayerParamsChanged(combo);
      }
    },
    [cropYearMapping, layerParams, onLayerParamsChanged],
  );

  const switchInteryieldYear = useCallback(
    (x: ChangeEvent<HTMLSelectElement>) => {
      const combo: LayerParamsInteryield = {
        layer_type: 'interyield',
        params: [layerParams?.params[0] ?? availableCropIds[0], x.target.value as HarvestYear],
      };
      if (!equal(combo, layerParams)) {
        onLayerParamsChanged(combo);
      }
    },
    [availableCropIds, layerParams, onLayerParamsChanged],
  );

  return (
    <div id="map-toolbox" className="card">
      <h2>{t('interyield')}</h2>
      <span className="interyield-selector-container">
        <select
          id="interyield-crop-select"
          className="interyield-selector"
          value={layerParams?.params[0]}
          onChange={switchInteryieldCrop}>
          {availableCropIds.map(v => (
            <option value={v} key={v}>{`${cropDesc(t, crops, v)}`}</option>
          ))}
        </select>
        <select
          id="interyield-year-select"
          className="interyield-selector"
          value={layerParams?.params[1]}
          onChange={switchInteryieldYear}>
          {availableYears.map(v => (
            <option value={v} key={v}>{`${t(v as I18nSimpleKey)}`}</option>
          ))}
        </select>
      </span>
      <MapLegend layer="interyield" layerParams={null} />
    </div>
  );
}

import {FilePdfOutlined} from '@ant-design/icons';
import {useQuery} from '@tanstack/react-query';
import {Col, Row, Select, message} from 'antd';
import * as d3 from 'd3';
import {saveAs} from 'file-saver';
import React, {CSSProperties, useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {getI18nOptions} from '../../../src/Formy/FormyEnum';
import {useFormyValue, useSyncFormy} from '../../../src/Formy/hooks';
import {CommonViewStyle} from '../../../src/components/CommonViewStyle';
import {CROP_COLORS} from '../../../src/constants/colors';
import {I18nFunction, I18nSimpleKey} from '../../../src/i18n/i18n';
import {
  GetPortfolioStatsRow,
  HarvestYear,
  RegionInfo,
  RegionLevel,
  getPortfolioStats,
} from '../../../src/models/interfaces';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {PortfolioReportPackage} from '../../../src/report/report-types';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {getSelectableHarvestYears} from '../../../src/selectors/harvest';
import {getPreferredUserGroups} from '../../../src/selectors/userGroups';
import {cropDesc} from '../../../src/text/desc';
import {filterNulls} from '../../../src/util/arr-util';
import {nullFilters} from '../../../src/util/req-util';
import {FormyEnum} from '../Formy/FormyEnum';
import {FormySubmit} from '../Formy/FormySubmit';
import {ApisContext} from '../apis/ApisContext';
import {CropTag, useCropTags} from '../components/CropTags';
import SpinningDots from '../components/SpinningDots';
import {reportErr} from '../util/err';
import StackedBarChart, {StackedBarChartData} from './StackedBarChart';
import {ReportApis} from './StandaloneReport';

const columnStyle: CSSProperties = {
  marginTop: '25px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const PortfolioReportCharts: React.FC<
  PortfolioReportPackage &
    ReportApis & {
      viewType: 'web' | 'pdf';
    }
> = props => {
  const PORTFOLIO_REPORT_CHART_TYPES = [
    {type: 'insured_area' as keyof GetPortfolioStatsRow, title: props.t('InsuredArea'), unit: 'hectares'},
    {
      type: 'insured_production' as keyof GetPortfolioStatsRow,
      title: props.t('InsuredProduction'),
      unit: 'tons',
    },
    {
      type: 'estimated_yield' as keyof GetPortfolioStatsRow,
      title: props.t('EstimatedYield'),
      unit: 'tons-per-hectare',
    },
    {type: 'total_loss' as keyof GetPortfolioStatsRow, title: props.t('TotalLoss'), unit: 'tons-per-hectare'},
  ];

  const charts = PORTFOLIO_REPORT_CHART_TYPES.flatMap(chartType => {
    return getChartsForColumn(
      props.t,
      props.portfolioStats?.filter(
        (row: GetPortfolioStatsRow) =>
          row[chartType.type] && row.crop_id && row.region_info && row.region_info.length > 0,
      ) ?? [],
      chartType,
      props.crops,
    );
  });

  const rows = [];
  let row = [];
  let regionsPerRow = 0;

  for (let i = 0; i < charts.length; i++) {
    const chart = charts[i];
    const isPageOverflow = regionsPerRow + charts[i].estimatedHeight > 28 && row.length % 2 == 1;

    // group columns by rows only for pdf export where we need page breaks
    if (props.viewType === 'pdf' && isPageOverflow && row.length > 0) {
      rows.push(
        <Row key={`row-${rows.length}`} style={{pageBreakAfter: 'always'}}>
          {row}
        </Row>,
      );
      row = [];
      regionsPerRow = 0;
    }
    row.push(
      <Col style={columnStyle} key={`chart-${i}`} span={chart.type === 'insured_area' ? 24 : 12}>
        <div>
          <h3>{chart.title}</h3>
          <h4>{chart.subtitle}</h4>
        </div>
        {charts[i].chart}
      </Col>,
    );
    regionsPerRow += charts[i].estimatedHeight;
  }
  rows.push(<Row key={`row-${rows.length}`}>{row}</Row>);
  return <>{rows}</>;
};

function getSubtitleForChartType(
  t: I18nFunction,
  data: GetPortfolioStatsRow[],
  chartType: {
    type: keyof GetPortfolioStatsRow;
    unit: string;
  },
  cropId?: string,
) {
  switch (chartType.type) {
    case 'insured_area':
    case 'insured_production':
      return (
        <span style={{fontStyle: 'italic'}}>
          {t('Total')}: {getTotalForChartType(data, chartType, cropId).toFixed(2)} {t(chartType.unit as I18nSimpleKey)}
        </span>
      );
    case 'estimated_yield':
    case 'total_loss':
      return (
        <span style={{fontStyle: 'italic'}}>
          {t('WeightedAverage')}: {getTotalForChartType(data, chartType, cropId).toFixed(2)}{' '}
          {t(chartType.unit as I18nSimpleKey)}
        </span>
      );
  }

  return null;
}

function getTotalForChartType(
  data: GetPortfolioStatsRow[],
  chartType: {
    type: keyof GetPortfolioStatsRow;
    unit: string;
  },
  cropId?: string,
) {
  const filteredData = data.filter((row: GetPortfolioStatsRow) => !cropId || row.crop_id === cropId);
  switch (chartType.type) {
    case 'insured_area':
    case 'insured_production':
      return filteredData.reduce((acc, curr) => acc + (curr[chartType.type] as number), 0);
    case 'estimated_yield':
    case 'total_loss':
      return (
        filteredData.reduce((acc, curr) => acc + (curr[chartType.type] as number) * (curr.yield_area ?? 0), 0) /
        Math.max(
          filteredData.reduce((acc, curr) => acc + (curr.yield_area ?? 0), 0),
          1,
        )
      );
  }
  return 0;
}

function getChartsForColumn(
  t: I18nFunction,
  data: GetPortfolioStatsRow[],
  chartType: {type: keyof GetPortfolioStatsRow; title: string; unit: string},
  crops: Readonly<IndexedCrops>,
): {
  estimatedHeight: number;
  chart: React.JSX.Element;
  type: keyof GetPortfolioStatsRow;
  title: React.JSX.Element;
  subtitle: React.JSX.Element | null;
  total: number;
}[] {
  switch (chartType.type) {
    case 'insured_area':
      const insuredAreaData = data
        .map(row => {
          return {
            key: getLabelFromRegionInfo(row.region_info!),
            category: cropDesc(t, crops, row.crop_id),
            value: row[chartType.type],
            color: CROP_COLORS[getBaseCrop(crops, row.crop_id) ?? 'unknown'],
          } as StackedBarChartData;
        })
        .sort((a, b) => a.key.localeCompare(b.key));

      return [
        {
          chart: (
            <StackedBarChart
              id={`chart-${chartType.type}`}
              data={insuredAreaData}
              unit={t(chartType.unit as I18nSimpleKey)}
              formatValue={String}
            />
          ),
          type: chartType.type,
          title: <>{chartType.title}</>,
          subtitle: getSubtitleForChartType(t, data, chartType),
          estimatedHeight: new Set(insuredAreaData.map(value => value.key)).size + 2, // + 2 is for title height
          total: getTotalForChartType(data, chartType),
        },
      ];
    case 'insured_production':
    case 'estimated_yield':
    case 'total_loss':
      const maxXAxisDomain = d3.max(data.map(value => value[chartType.type] as number));
      return Object.entries(
        data.reduce<Record<string, GetPortfolioStatsRow[]>>((result, currentRow) => {
          const {crop_id} = currentRow;
          if (!crop_id) {
            return result;
          }
          if (!result[crop_id]) {
            result[crop_id] = [];
          }
          result[crop_id].push(currentRow);
          return result;
        }, {}),
      )
        .sort((a, b) => a[1].length - b[1].length)
        .map(([key, value], index: number) => {
          const stackedBarChartData: StackedBarChartData[] = value
            .map((row: GetPortfolioStatsRow) => {
              return {
                key: getLabelFromRegionInfo(row.region_info!),
                category: cropDesc(t, crops, key),
                value: row[chartType.type],
                color: CROP_COLORS[getBaseCrop(crops, row.crop_id) ?? 'unknown'],
              } as StackedBarChartData;
            })
            .filter((x: StackedBarChartData) => x.value)
            .sort((a: StackedBarChartData, b: StackedBarChartData) => a.key.localeCompare(b.key));
          return {
            chart: (
              <StackedBarChart
                id={`chart-${chartType.type}-${index}`}
                data={stackedBarChartData}
                unit={t(chartType.unit as I18nSimpleKey)}
                formatValue={String}
                xAxisDomain={maxXAxisDomain}
              />
            ),
            title: (
              <>
                {chartType.title} <span style={{fontWeight: 'bold'}}>{cropDesc(t, crops, key)}</span>
              </>
            ),
            subtitle: getSubtitleForChartType(t, data, chartType, key),
            type: chartType.type,
            estimatedHeight: new Set(stackedBarChartData.map(value => value.key)).size + 2, // + 2 is for title height
            total: getTotalForChartType(value, chartType),
          };
        })
        .sort((a, b) => b.total - a.total);
  }
  return [];
}

function getLabelFromRegionInfo(regionInfo: (RegionInfo | null)[]): string {
  return filterNulls(regionInfo)
    .slice(-2)
    .map(value => value.region_name)
    .join(', ');
}

const buttonStyle: CommonViewStyle = {width: '250px', marginTop: 10};
const selectStyle = {...buttonStyle, width: 'auto', maxWidth: '75%'};

export const PortfolioReport: React.FC = () => {
  const apis = useContext(ApisContext),
    crops = useSelector(getCrops),
    [selectedCrops, setSelectedCrops] = useState<string[]>([]),
    cropTags = useCropTags(Object.keys(crops));

  const formy = useSyncFormy(
    'new',
    () => ({
      harvest_year: new Date().getFullYear().toString() as HarvestYear,
      user_group: '',
      region_level: 'country' as RegionLevel,
    }),
    apis.t,
    () => new Promise(() => {}),
    () => ({}),
    null,
  );

  const userGroup = useFormyValue(formy, 'user_group')!;
  const harvestYear = useFormyValue(formy, 'harvest_year')!;
  const regionLevel: RegionLevel = useFormyValue(formy, 'region_level')!;

  const {data: portfolioData, isLoading} = useQuery(['getPortfolioStats', harvestYear, userGroup, regionLevel], () =>
    getPortfolioStats(apis.authedFetcher, {
      region_level: regionLevel,
      f: {...nullFilters, years: [harvestYear], user_groups: [userGroup]},
    }).then(data => {
      // When receiving new data, reset the crop selection.
      const cropsByYieldArea = getCropsByYieldArea(data);
      if (cropsByYieldArea.length > 0) {
        setSelectedCrops(cropsByYieldArea.slice(0, 10).map(c => c.crop_id));
      }
      return data;
    }),
  );

  const cropsByYieldArea = getCropsByYieldArea(portfolioData);

  const onDownload = async (): Promise<boolean> => {
    message.loading({
      content: apis.t('Downloading') + '...',
      duration: 0,
      key: 'download',
      icon: <SpinningDots size={16} />,
    });

    try {
      const res = await apis.authedFetcher({
        method: 'GET',
        path: `api2/portfolio-report`,
        params: [...Object.entries(formy.getValues()), ['crops', selectedCrops.join(',')], ['locale', apis.locale]],
        responseType: 'response',
      });
      const blob = await res.blob();
      const contentDisposition = res.headers.get('content-disposition');
      const match = contentDisposition && contentDisposition.match(`filename=(.+\\.pdf)`);
      const filename = match ? match[1] : 'report.pdf';
      saveAs(blob, filename);
      message.success({content: apis.t('Done'), duration: 2, key: 'download'});
      return true;
    } catch (e) {
      reportErr(e, 'ReportRouter::download');
      message.error({content: apis.t('Error'), duration: 5, key: 'download'});
      return false;
    }
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', margin: '50px 100px'}}>
      <h1>{apis.t('PortfolioReport')}</h1>

      <FormyEnum
        style={{width: '250px'}}
        selectMsg={'SelectPortfolio'}
        field="user_group"
        formy={formy}
        options={getPreferredUserGroups(apis.store.getState()).map(value => [value, value])}
      />

      <FormyEnum
        style={buttonStyle}
        selectMsg={'SelectYear'}
        field="harvest_year"
        formy={formy}
        options={getI18nOptions(apis.t, getSelectableHarvestYears(apis.clock))}
      />

      <FormyEnum
        style={buttonStyle}
        selectMsg={null}
        field="region_level"
        formy={formy}
        options={RegionLevel.map(value => [value, apis.t(('RegionLevel_' + value) as `RegionLevel_${typeof value}`)])}
      />

      <Select
        style={selectStyle}
        value={selectedCrops}
        onChange={setSelectedCrops}
        options={cropsByYieldArea.map(value => {
          const cropTag = cropTags.find(c => c.cropId == value.crop_id);
          return {
            value: value.crop_id,
            label: cropTag && (
              <CropTag {...cropTag} label={cropTag.label + ` (${value.total_yield_area.toFixed(2)} ha)`} />
            ),
          };
        })}
        allowClear={true}
        disabled={isLoading}
        mode={'multiple'}
      />
      <p style={{color: 'darkgrey'}}>{apis.t('TopNCropsByHarvestArea')}</p>

      <FormySubmit
        style={buttonStyle}
        icon={<FilePdfOutlined />}
        type="primary"
        label="Download"
        formy={formy}
        onSubmitOverride={onDownload}
        doNotConfirmSubmissionToUser={true}
      />

      <PortfolioReportCharts
        t={apis.t}
        clock={apis.clock}
        fetchFn={apis.fetchFn}
        authedFetcher={apis.authedFetcher}
        portfolioStats={
          selectedCrops.length == 0
            ? portfolioData
            : portfolioData?.filter(row => row.crop_id && selectedCrops.includes(row.crop_id))
        }
        crops={crops}
        user_group={userGroup}
        type={'PortfolioReport'}
        harvest_year={harvestYear}
        region_level={regionLevel}
        viewType={'web'}
      />
    </div>
  );
};

// Returns the top n crops by yield area, sorted in descending order.
function getCropsByYieldArea(portfolioData: GetPortfolioStatsRow[] | undefined): {
  crop_id: string;
  total_yield_area: number;
}[] {
  const cropStats = portfolioData?.reduce(
    (acc, curr) => {
      if (!curr.crop_id) {
        return acc;
      }
      if (!acc[curr.crop_id]) {
        acc[curr.crop_id] = 0;
      }
      acc[curr.crop_id] += curr.yield_area ?? 0;
      return acc;
    },
    {} as Record<string, number>,
  );

  return Object.entries(cropStats ?? {})
    .sort((a, b) => b[1] - a[1])
    .map(c => ({crop_id: c[0], total_yield_area: c[1]}));
}

export default PortfolioReport;

import {useQuery} from '@tanstack/react-query';
import {FetcherFunc} from '../../../src/FetcherFunc';
import {MapNavFocus} from '../../../src/map/map-focus';
import {Farm, Field, Harvest, Policy, Sample} from '../../../src/models/interfaces';
import {filterNulls} from '../../../src/util/arr-util';
import {fetchEntitiesBy, fetchEntity} from '../../../src/util/fetchEntity';
import {useApis} from '../apis/ApisContext';
import {Marker} from './Mapbox';

export interface SelectedEntity {
  farm?: Farm;
  field?: Field;
  harvests: (Harvest & {samples: Sample[]; policy: null | Policy})[];
  sample?: Sample;
  policy?: Policy;
}

export async function fetchSelectedEntity(
  authedFetcher: FetcherFunc,
  focus: null | MapNavFocus,
): Promise<SelectedEntity> {
  let farm: undefined | Farm, field: undefined | Field, sample: undefined | Sample;
  let harvests: (Harvest & {samples: Sample[]; policy: null | Policy})[] = [];

  if (focus?.type == 'sample') {
    const sample_id = focus.id;
    sample = await fetchEntity(authedFetcher, 'sample', sample_id);
    const harvest = await fetchEntity(authedFetcher, 'harvest', sample.harvest_id);
    harvests = [{...harvest, samples: [], policy: null}];
    harvests[0].samples = await fetchEntitiesBy(authedFetcher, 'sample', {
      column: 'harvest_id',
      operator: 'eq',
      value: harvest.harvest_id,
    });
    if (harvest.field_id) {
      field = await fetchEntity(authedFetcher, 'field', harvest.field_id);
      farm = await fetchEntity(authedFetcher, 'farm', field.farm_id);
    } else {
      farm = await fetchEntity(authedFetcher, 'farm', harvest.farm_id!);
    }
  } else if (focus?.type == 'field') {
    const field_id = focus.id;
    field = await fetchEntity(authedFetcher, 'field', field_id);
    farm = await fetchEntity(authedFetcher, 'farm', field.farm_id);
    const fieldHarvests = await fetchEntitiesBy(authedFetcher, 'harvest', {
      column: 'field_id',
      operator: 'eq',
      value: field_id,
    });
    const [harvestsSamples, harvestsPolicies] = await Promise.all([
      fetchEntitiesBy(authedFetcher, 'sample', {
        column: 'harvest_id',
        operator: 'in',
        value: fieldHarvests.map(x => x.harvest_id),
      }),
      fetchEntitiesBy(authedFetcher, 'policy', {
        column: 'policy_id',
        operator: 'in',
        value: filterNulls(fieldHarvests.map(x => x.policy_id)),
      }),
    ]);
    harvests = fieldHarvests.map(h => ({
      ...h,
      samples: harvestsSamples.filter(s => s.harvest_id == h.harvest_id),
      policy: harvestsPolicies.find(p => p.policy_id == h.policy_id) || null,
    }));
  } else if (focus?.type == 'farm') {
    const farm_id = focus.id;
    farm = await fetchEntity(authedFetcher, 'farm', farm_id);
  }

  return {farm, field, sample, harvests};
}

export function useSelectedEntity(focus: null | MapNavFocus) {
  const {authedFetcher} = useApis();
  const {data} = useQuery(['fetchSelectedEntity', focus], () => fetchSelectedEntity(authedFetcher, focus));
  return data;
}

export function useCurrentMarker(focus: null | MapNavFocus): null | Marker {
  const selectedEntities = useSelectedEntity(focus);
  if (!focus) {
    return null;
  }

  // handle 'lnglat' && 'address'
  // as we know everything we need already
  if (focus.type === 'address' || focus.type === 'lnglat') {
    return {
      type: focus.type,
      location: focus.location,
    };
  }

  if (!selectedEntities) {
    return null;
  }

  // Order is important for the following conditions:
  // A selected entity might not contain a location (e.g. field/harvest level samples) which we can compensate for by
  // using getSelectedEntity which will provide us with the whole hierarchy for any selected entity as in:
  //   sample -> field -> farm.
  // To compensate for a missing location, we will successively fall back on the next hierarchy level if the current
  // level has no location attached.
  if (selectedEntities.sample?.sample_location) {
    return {
      type: 'sample',
      location: selectedEntities.sample.sample_location,
    };
  }

  if (selectedEntities.field?.field_location) {
    const color = '#fff0'; // Set the color to transparent, to let the layer's color come through.
    return {
      type: 'field',
      location: selectedEntities.field.field_location,
      color: color,
    };
  }

  if (selectedEntities.farm?.farm_location) {
    return {
      type: 'farm',
      location: selectedEntities.farm.farm_location,
    };
  }

  // give a warning for (new) unhandled focus types
  console.warn('Unknown entity type %s or no location could be identified: %o', focus.type, focus, new Error());
  return null;
}

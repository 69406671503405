import {useQuery} from '@tanstack/react-query';
import {useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {getStatsByHarvest} from '../../../src/models/interfaces';
import {dbFiltersInitialState} from '../../../src/redux/reducers/filters';
import {getCrops} from '../../../src/selectors/crops';
import {getCropIdAggregationKey} from '../../../src/selectors/harvest';
import {cropDesc} from '../../../src/text/desc';
import {filtersToRequest} from '../../../src/util/req-util';
import {ApisContext} from '../apis/ApisContext';

export function useCropOptions(enabled: boolean, includeAll: boolean) {
  const {t, authedFetcher} = useContext(ApisContext);
  const crops = useSelector(getCrops);
  const {data: usedCropIds, isFetching} = useQuery(
    ['cropIds'],
    () =>
      getStatsByHarvest(authedFetcher, filtersToRequest(dbFiltersInitialState)).then(value => {
        const fieldsByAggCrop: Record<string, number> = {};
        for (const {crop_id, num_fields} of value) {
          if (!crop_id) {
            continue;
          }
          const crop = getCropIdAggregationKey(crop_id);
          fieldsByAggCrop[crop] = (fieldsByAggCrop[crop] ?? 0) + (num_fields ?? 0);
        }
        return Object.entries(fieldsByAggCrop)
          .sort((a, b) => b[1] - a[1])
          .map(x => x[0]);
      }),
    {keepPreviousData: true, enabled},
  );

  const cropIds = useMemo(() => {
    const allCropIds = Object.keys(crops);
    if (!usedCropIds) {
      return allCropIds;
    }
    if (includeAll) {
      const usedCropIdsSet = new Set(usedCropIds);
      const missingCropIds = allCropIds.filter(x => !usedCropIdsSet.has(x));
      return [...usedCropIds, ...missingCropIds];
    } else {
      return usedCropIds;
    }
  }, [crops, usedCropIds, includeAll]);

  const options = useMemo(() => cropIds.map(x => ({value: x, label: cropDesc(t, crops, x)})), [cropIds]);
  return {options, isFetching};
}

import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {convertArea, getUnitSystem} from '../../../src/selectors/units';
import {ApisContext} from '../apis/ApisContext';
import {useIsVisible} from '../util/hooks';
import ListOfValuesCard from './ListOfValuesCard';
import {useSampleCountsByAdjuster, useStatsByHarvest} from './queries';

export const SummaryStats: React.FC = () => {
  const units = useSelector(getUnitSystem),
    {t} = useContext(ApisContext);
  const ref = React.useRef(null);
  const isVisible = useIsVisible(ref);
  const {data: sampleCountsByAdjuster = [], isFetching: loadingSamples} = useSampleCountsByAdjuster({
    enabled: isVisible,
  });
  const {data: statsByHarvest = [], isFetching: loadingHarvests} = useStatsByHarvest({enabled: isVisible});
  const adjusters = new Set();
  let numSamples = 0;
  for (const {added_by, count} of sampleCountsByAdjuster) {
    adjusters.add(added_by);
    numSamples += count ?? 0;
  }
  let numFields = 0,
    fieldAreaHa = 0;
  for (const {num_fields, total_area_ha} of statsByHarvest) {
    numFields += num_fields ?? 0;
    fieldAreaHa += total_area_ha ?? 0;
  }

  const totalArea = convertArea(units.areaUnit, {unit: 'hectares', val: fieldAreaHa});

  const summaryStats =
    adjusters.size === 0 && totalArea?.val === 0 && numFields === 0 && numSamples === 0
      ? []
      : [
          {title: t('NumberOfLossAdjusters'), value: adjusters.size},
          {title: t('TotalCultivatedArea'), value: totalArea ? t({type: 'AreaUnit', ...totalArea}) : '-'},
          {title: t('NumberOfFields'), value: numFields},
          {title: t('NumberOfSamples'), value: numSamples},
          // TODO(savv): re-add the number of policies here.
        ];

  // We need to wrap ListOfValuesCard in a div to get an HtmlElement ref instead of a React node as ref.current.
  return (
    <div ref={ref}>
      <ListOfValuesCard title={t('Summary')} data={summaryStats} loading={loadingSamples || loadingHarvests} />
    </div>
  );
};

export const INTERYIELD_CROP_IDS = [
  'wheat-winter',
  'barley-winter',
  'wheat-hard-winter',
  'triticale-winter',
  'corn-grain-spring',
  'soybeans-first-crop',
] as const;
export type InteryieldCropIds = (typeof INTERYIELD_CROP_IDS)[number];

export const interyieldYieldThresholds: {[P in InteryieldCropIds]: number[]} = {
  // Thresholds must be consistent with the values defined in get_crop_zone_thresholds() (pipeline/interyield_utils.py).
  'wheat-winter': [0, 8],
  'barley-winter': [0, 8],
  'wheat-hard-winter': [0, 8],
  'triticale-winter': [0, 8],
  'corn-grain-spring': [0, 12],
  'soybeans-first-crop': [0, 4],
};

export const interyieldSeasonDoys: {[P in InteryieldCropIds]: number[]} = {
  // Season start and end day-of-year (DOY). Values must be consistent with those defined for interyield_combos
  // (pipeline/type_utils.py).
  'wheat-winter': [274, 196],
  'barley-winter': [274, 196],
  'wheat-hard-winter': [274, 196],
  'triticale-winter': [274, 196],
  'corn-grain-spring': [90, 315],
  'soybeans-first-crop': [274, 196],
};

export const interyieldSeasonWeeks: {[P in InteryieldCropIds]: number[]} = {
  // Season week numbers indicating the first and the last weeks when the prediction should be available. Values must be
  // consistent with those defined for interyield_combos (pipeline/type_utils.py).
  // Note: While interyieldSeasonDoys describes the start and end of the entire season, interyieldSeasonWeeks indicate
  // the weeks between which the prediction should be available (e.g. too little data at the beginning of the season).
  'wheat-winter': [10, 42],
  'barley-winter': [10, 42],
  'wheat-hard-winter': [10, 42],
  'triticale-winter': [10, 42],
  'corn-grain-spring': [13, 29],
  'soybeans-first-crop': [10, 42],
};

import {Farm, Field, FieldSeriesData, GetFieldScoringRowsRow} from './interfaces';

export interface FieldScoringItem {
  field: Pick<Field, 'field_id' | 'field_location' | 'field_area' | 'external_field_id' | 'added_on'>;
  farm: Farm;
  cropId: string;
  regional?: {
    region: string | null;
    relative_productivity: number | null;
    relative_stability: number | null;
    percentile_productivity: number | null;
    percentile_stability: number | null;
  };
  productivity: {
    score: number | null;
    history: Record<string, number | null>;
  };
  stability: {
    score: number | null;
    volatility: number | null;
  };
  homogeneity: {
    score: number | null;
    history: Record<string, number | null>;
  };
  cropCoverage?: Record<string, number | null>;
  cloudCover: Record<string, number | null>;
}

export function toFieldScoreItem(row: GetFieldScoringRowsRow): FieldScoringItem[] {
  const hasCoverage = row.field_series_data?.map(fsd => fsd?.crop_cover_pct).some(cc => cc !== null);
  return (row.field_data || []).map(fd => {
    const hasRegional =
      !!fd &&
      (fd.relative_productivity_score !== null ||
        fd.relative_stability_score !== null ||
        fd.percentile_productivity_score ||
        fd.percentile_stability_score);
    return {
      field: row.field!,
      farm: row.farm!,
      cropId: fd?.crop_id ?? 'unknown',
      regional: hasRegional
        ? {
            region: fd.region_name,
            relative_productivity: fd.relative_productivity_score,
            relative_stability: fd.relative_stability_score,
            percentile_productivity: fd.percentile_productivity_score,
            percentile_stability: fd.percentile_stability_score,
          }
        : undefined,
      productivity: {
        score: fd?.productivity_score ?? null,
        history: getHistogram(row.field_series_data, 'max_ndvi'),
      },
      stability: {
        score: fd?.stability_score ?? null,
        volatility: fd?.vol_ndvi ?? null,
      },
      homogeneity: {
        score: fd?.homogeneity_score ?? null,
        history: getHistogram(row.field_series_data, 'avg_homogeneity'),
      },
      cropCoverage: hasCoverage ? getHistogram(row.field_series_data, 'crop_cover_pct') : undefined,
      cloudCover: getHistogram(row.field_series_data, 'cloudy_weeks_pct'),
    };
  });
}

function getHistogram(
  fieldSeriesData: (FieldSeriesData | null)[] | null,
  key: keyof FieldSeriesData,
): Record<string, number> {
  return Object.fromEntries(
    (fieldSeriesData ?? [])
      .filter(fsd => !!fsd && fsd[key] !== null)
      .map(fsd => [fsd?.harvest_year, fsd?.[key] as number]),
  );
}

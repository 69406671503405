import {CloseSquareOutlined} from '@ant-design/icons';
import React from 'react';
import {ClockI} from '../../../src/Clock';
import {CROP_COLORS} from '../../../src/constants/colors';
import {I18nFunction, I18nSimpleKey} from '../../../src/i18n/i18n';
import {Harvest, HarvestCrop, Sample, SampleImage} from '../../../src/models/interfaces';
import {FarmReportPackage, HarvestDesc} from '../../../src/report/report-types';
import {getReportStyling} from '../../../src/report/report-util';
import {AggregatedHarvest, HarvestAnalytics, getSum} from '../../../src/selectors/aggregators';
import {getBaseCrop} from '../../../src/selectors/crops';
import {getTotalSampleLoss} from '../../../src/selectors/yield';
import {formatDate, formatDateRange, formatMonthYear} from '../../../src/text/date';
import {fieldDesc, harvestDesc} from '../../../src/text/desc';
import {getSampleDetails} from '../../../src/text/line';
import {filterNulls} from '../../../src/util/arr-util';
import SpinningDots from '../components/SpinningDots';
import FieldCharts from '../map/FieldCharts';
import {reportErr} from '../util/err';
import {AuthedImage} from './AuthedImage';
import BarChart, {BarChartDatum} from './BarChart';
import './Report.css';
import {ReportApis} from './StandaloneReport';
import {getHarvestMap} from './harvest-map-images';

type FarmReportState = {
  maps: {[harvest_id: string]: {image: Blob; layer: string} | undefined} | null;
};

interface ReportHeaderProps {
  t: I18nFunction;
  clock: ClockI;
  type: I18nSimpleKey;
  color: string;
  logo: string;
  title?: string;
  subtitle?: string;
  extra?: {
    title: string;
    subtitle?: string;
  };
}

export function ReportHeader(props: ReportHeaderProps) {
  const {t, type, title, subtitle, logo, color, extra} = props;
  return (
    <div style={{borderBottom: `3px solid ${color}`}}>
      <span className="gt-report-header">
        <span className="gt-report-header-date">
          {t(type)}
          <br />
          {formatDate(t, new Date(props.clock.now()))}
        </span>
        {extra && (
          <span className="gt-report-header-date">
            {extra.title}
            {extra.subtitle && (
              <>
                <br />
                {extra.subtitle}
              </>
            )}
          </span>
        )}
        <span className="gt-report-header-title gt-long-val">
          {title}
          {subtitle && (
            <>
              <br />
              {subtitle}
            </>
          )}
        </span>
        <span className="gt-report-header-logo">
          <img className="gt-report-header-logo" src={'data:image/png;base64,' + logo} />
        </span>
      </span>
    </div>
  );
}

export function ReportFooter({color}: {color: string}) {
  return (
    <div className="gt-report-footer" style={{borderTop: `3px solid ${color}`}}>
      <span className="gt-report-footer-text">Powered by</span>
      <img className="footer-logo" src="/report-gt.png" />
    </div>
  );
}

// noinspection HtmlRequiredAltAttribute
export class FarmReport extends React.PureComponent<FarmReportPackage & ReportApis, FarmReportState> {
  state: FarmReportState = {maps: null};

  componentDidMount() {
    this.loadHarvestMaps().catch(e => reportErr(e, 'FarmReport.componentDidMount'));
  }

  async loadHarvestMaps() {
    const size = this.props.numFields > 50 ? 160 : 240;
    const features = this.props.harvestMapFeatures;

    const promises = [];
    const harvest_ids: string[] = [];
    for (const harvest_id in features) {
      const harvestFeatures = features[harvest_id];
      promises.push(getHarvestMap(this.props.fetchFn, harvestFeatures, size));
      harvest_ids.push(harvest_id);
    }

    const maps = Object.fromEntries((await Promise.all(promises)).map((x, idx) => [harvest_ids[idx], x]));
    this.setState({maps});
  }
  renderFarmHeader() {
    const {farm, sampleDateMinMax, experts} = this.props;
    if (!farm) {
      return null;
    }
    const t = this.props.t;
    const dates = sampleDateMinMax ? formatDateRange(t, sampleDateMinMax[0], sampleDateMinMax[1]) : '-';
    const policies = [
      ...new Set<string>(
        filterNulls(this.props.summaryByHarvest.map(s => s.harvests.map(h => h.policy?.policy_number)).flat()),
      ),
    ];

    return (
      <span className="gt-report-farm-summary gt-report-card">
        <span className="gt-page-section-subheader">{t('ExpertReport')}</span>
        <span className="gt-page-section-header">{farm.farm_name}</span>
        <span className="gt-section-col-1">{t('UserGroup')}</span>
        <span className="gt-section-col-2-5">{farm.user_group}</span>
        <span className="gt-section-col-1">{t('FarmAddress')}</span>
        <span className="gt-section-col-2-5">{farm.address}</span>
        <span className="gt-section-col-1">{t('Policies')}</span>
        <span className="gt-section-col-2-5 gt-long-val">{Array.from(policies).join(', ')}</span>
        <span className="gt-section-col-1">{t('ReportDate')}</span>
        <span className="gt-section-col-2-5">{formatDate(t, this.props.generationDate)}</span>
        <span className="gt-section-col-1">{t('Expert-s')}</span>
        <span className="gt-section-col-2-5">{Array.from(experts).join(', ')}</span>
        <span className="gt-section-col-1">{t('SamplingDate-s')}</span>
        <span className="gt-section-col-2-5">{dates}</span>
      </span>
    );
  }

  renderSummary() {
    const t = this.props.t;
    const areaStr = this.props.totalSurface == null ? '-' : t({type: 'AreaUnit', ...this.props.totalSurface});
    return (
      <span className="gt-left-card gt-flex-card gt-report-card">
        <span className="gt-card-header">{t('Summary')}</span>
        <span className="gt-card-line">
          <span>{t('TotalCultivatedArea')}</span>
          <span className="gt-report-value">{areaStr}</span>
        </span>
        <span className="gt-card-line">
          <span>{t('NumberOfFields')}</span>
          <span className="gt-report-value">{this.props.numFields}</span>
        </span>
        <span className="gt-card-line">
          <span>{t('NumberOfSamples')}</span>
          <span className="gt-report-value">{this.props.numSamples}</span>
        </span>
      </span>
    );
  }

  renderEstimatedCrop = (x: HarvestAnalytics & HarvestDesc, idx: number) => {
    if (x.stats.estimated == null) {
      return null;
    }

    return (
      <span className="gt-card-line" key={idx}>
        <span>
          <span
            className="gt-report-crop-circle"
            style={{borderColor: CROP_COLORS[getBaseCrop(this.props.crops, x.crop_id) ?? 'unknown']}}
          />
          {harvestDesc(this.props.t, this.props.crops, x, null, this.props.countryGroups)}
        </span>
        <span className="gt-report-value">
          {this.props.t({
            type: 'YieldUnit',
            unit: x.stats.unit,
            val: x.stats.estimated,
          })}
        </span>
      </span>
    );
  };

  renderCropByArea() {
    const unit = this.props.totalSurface ? this.props.totalSurface.unit : this.props.units.areaUnit;
    const data: BarChartDatum[] = this.props.summaryByHarvest
      .map(x => ({
        color: CROP_COLORS[getBaseCrop(this.props.crops, x.crop_id) ?? 'unknown'],
        key: harvestDesc(this.props.t, this.props.crops, x, null, this.props.countryGroups),
        value: getSum(
          filterNulls(
            x.harvests.map(x => (x.field ? x.field.field_area : x.samples[0] ? x.samples[0].sample_area : null)),
          ),
          unit,
          getBaseCrop(this.props.crops, x.crop_id),
        )?.val!,
      }))
      .filter(x => x.value != null && !isNaN(x.value));
    data.sort((a, b) => b.value - a.value);

    const formatArea = (x: number) => this.props.t({type: 'AreaUnit', val: x, unit});
    return (
      <span className="gt-center-card gt-report-card gt-chart-card">
        <span className="gt-card-header">{this.props.t('AreaCropTitle')}</span>
        <BarChart
          height={this.props.summaryByHarvest.length * 20 + 50}
          width={300}
          id="report-crop-chart"
          data={data}
          formatValue={formatArea}
        />
      </span>
    );
  }

  renderSampleLine = (s: Sample, idx: number) => {
    const t = this.props.t;
    const estLoss = getTotalSampleLoss(s.losses);
    const lossCauseStr = filterNulls(s.losses.map(x => x.loss_cause))
      .map(x => t(x))
      .join(', ');

    return (
      <React.Fragment key={idx}>
        <span className="gt-harvest-col-1">
          <span>{'#' + (idx + 1)}</span>
          <span className="gt-harvest-after-idx">{s.sample_date ? formatDate(t, s.sample_date) : '-'}</span>
        </span>
        <span className="gt-harvest-col-2">{s.crop_condition ? t(s.crop_condition) : '-'}</span>
        <span className="gt-harvest-col-3">
          {s.estimated_yield ? t({type: 'YieldUnit', ...s.estimated_yield}) : '-'}
        </span>
        <span className="gt-harvest-col-4">{estLoss ? t({type: 'YieldUnit', ...estLoss}) : '-'}</span>
        <span className="gt-harvest-col-5">{lossCauseStr}</span>
      </React.Fragment>
    );
  };

  renderHarvestMap = (harvest: Harvest, harvestPeak: undefined | null | string) => {
    const {maps} = this.state;
    const map = maps && maps[harvest.harvest_id];
    const mapComponent = !maps ? (
      <div className="authed-image-placeholder">
        <SpinningDots className="spinning-dots" size={32} />
      </div>
    ) : !map ? (
      <div className="authed-image-placeholder">
        <CloseSquareOutlined style={{color: '#8B0000', fontSize: 48}} />
      </div>
    ) : (
      <div style={{position: 'relative'}}>
        <img alt="" width={240} src={URL.createObjectURL(map.image)} />
        <img
          alt=""
          width={240}
          style={{position: 'absolute', top: 0, left: 0}}
          src={`data:image/svg+xml;base64,${btoa(map.layer)}`}
        />
      </div>
    );

    return (
      <>
        {mapComponent}
        {harvestPeak && (
          <span>
            {this.props.t('Date')}: {formatDate(this.props.t, harvestPeak)}
          </span>
        )}
      </>
    );
  };

  renderFieldReport = (f: AggregatedHarvest, idx: number) => {
    const {crops, countryGroups} = this.props;
    const t = this.props.t;

    const harvestData = this.props.harvestData[f.harvest.harvest_id];

    const fieldLabel = f.field ? fieldDesc(t, crops, countryGroups, f.field) : t('DetachedSample-s');
    const harvestLabel = harvestDesc(t, crops, f.harvest, null, countryGroups, undefined, false);
    const detailedHarvestLabel = harvestDesc(t, crops, f.harvest, null, countryGroups, undefined, true);
    const fieldLinkElements = ['-', fieldLabel];
    const nSamples = f.samples.length;

    // farm-level harvest and no attached samples
    if (!f.field && nSamples == 0) {
      return;
    }

    if (nSamples > 0) {
      fieldLinkElements.push(`| ▼ (${nSamples})`);
      const nPhotos = f.samples.map(y => y.images.length).reduce((a, b) => a + b, 0);
      if (nPhotos > 0) {
        fieldLinkElements.push(`| 📷 (${nPhotos})`);
      }
    }
    const fieldLink = fieldLinkElements.join('');

    const feasibleYieldLabel: I18nSimpleKey = this.props.flags.includes('startingYield')
      ? 'StartingYield'
      : 'FeasibleYield';

    return (
      <React.Fragment key={idx}>
        {idx > 0 && this.renderPageBreak()}
        <tr>
          <td>
            <span className="gt-harvest-report">
              <span className="gt-page-section-subheader" id={fieldLink}>
                <span className="gt-report-crop-circle" />
                <span className="bold capitalized">{fieldLabel}</span>
                {this.renderHiddenLink(fieldLink)}
                {detailedHarvestLabel != harvestLabel && (
                  <>
                    <br />
                    {detailedHarvestLabel}
                  </>
                )}
              </span>
              <span className="gt-harvest-right gt-harvest-field-map">
                {this.renderHarvestMap(f.harvest, harvestData?.harvestPeak)}
              </span>
              <span className="gt-harvest-col-1 bold">{t('Policy')}</span>
              <span className="gt-harvest-col-2 bold">{t('HarvestYear')}</span>
              <span className="gt-harvest-col-3 bold">{t('Area')}</span>
              <span className="gt-harvest-col-4 bold">{t('Reference')}</span>
              <span className="gt-harvest-col-1">{f.policy?.policy_number || '-'}</span>
              <span className="gt-harvest-col-2">{f.harvest.harvest_year || '-'}</span>
              <span className="gt-harvest-col-3">
                {f.field && f.field.field_area ? t({type: 'AreaUnit', ...f.field.field_area}) : '-'}
              </span>
              <span className="gt-harvest-col-4">{f.field && f.field.external_field_id}</span>
              <span className="gt-harvest-col-1 bold">{t('EstimatedYield')}</span>
              <span className="gt-harvest-col-2 bold">{t(feasibleYieldLabel)}</span>
              <span className="gt-harvest-col-3 bold">{t('EstimatedYieldLoss')}</span>
              <span className="gt-harvest-col-4 bold">{t('EmergenceDate')}</span>
              <span className="gt-harvest-col-1">
                {f.stats.estimated == null
                  ? '-'
                  : t({
                      type: 'YieldUnit',
                      unit: f.stats.unit,
                      val: f.stats.estimated,
                    })}
              </span>
              <span className="gt-harvest-col-4">
                {harvestData?.emergenceDate ? formatDate(t, harvestData.emergenceDate) : '-'}
              </span>
              {harvestData?.fieldSeries && (
                <span className="gt-harvest-ts">
                  <FieldCharts
                    field={f.field}
                    t={t}
                    groupFn={date => date.slice(0, 7)}
                    formatDate={date => formatMonthYear(this.props.t, date)}
                    selectedDate={harvestData.harvestPeak ?? null}
                    enableBiomassLabelHack
                    anomalies={this.props.anomalies}
                    bandWidth={20}
                    bandHeight={20}
                    gapHeight={20}
                    onDateSelected={() => {}}
                    fieldSeries={harvestData.fieldSeries}
                    expanded={true}
                    showSelectedItem={true}
                    showLabels={true}
                  />
                </span>
              )}
            </span>
          </td>
        </tr>
        {f.samples.length == 0 ? null : (
          <tr>
            <td>
              <span className="gt-harvest-report">
                <span className="gt-harvest-left bold capitalized">
                  <span className="gt-report-triangle" />
                  {t('Samples')}
                </span>
                <span className="gt-harvest-col-1 bold">
                  <span>#</span>
                  <span className="gt-harvest-after-idx">{t('Date')}</span>
                </span>
                <span className="gt-harvest-col-2 bold">{t('Condition')}</span>
                <span className="gt-harvest-col-3 bold">{t('EstimatedYieldAbbr')}</span>
                <span className="gt-harvest-col-4 bold">{t('EstimatedLossAbbr')}</span>
                <span className="gt-harvest-col-5 bold">{t('LossCause')}</span>
                {f.samples.map(this.renderSampleLine)}
              </span>
            </td>
          </tr>
        )}
        {f.samples.map((sample, index) =>
          this.renderSampleDetails(sample, index, getBaseCrop(crops, f.harvest.crop_id)),
        )}
      </React.Fragment>
    );
  };

  renderHarvestReport = (x: HarvestAnalytics & HarvestDesc, idx: number) => {
    const t = this.props.t;
    const harvestLabel = harvestDesc(t, this.props.crops, x, null, this.props.countryGroups);
    const harvestColor = CROP_COLORS[getBaseCrop(this.props.crops, x.crop_id) ?? 'unknown'];

    return (
      <React.Fragment key={idx}>
        {this.renderPageBreak()}

        <tr>
          <td>
            <span className="gt-harvest-section">
              <span className="gt-page-section-header capitalized" id={harvestLabel}>
                <span
                  className="gt-report-crop-circle"
                  style={{borderColor: harvestColor, backgroundColor: harvestColor}}
                />
                {harvestLabel}
                {this.renderHiddenLink(harvestLabel)}
              </span>
              <span className="gt-section-left gt-section-title">{t('EstimatedYield')}</span>
              <span className="gt-section-right gt-section-title">{t('EstimatedYieldLoss')}</span>
              <span className="gt-section-left">
                {x.stats.estimated == null
                  ? '-'
                  : t({
                      type: 'YieldUnit',
                      unit: x.stats.unit,
                      val: x.stats.estimated,
                    })}
              </span>
              <span className="gt-section-right">
                {x.stats.totalLoss == null
                  ? null
                  : t({
                      type: 'YieldUnit',
                      unit: x.stats.unit,
                      val: x.stats.totalLoss,
                    })}
              </span>
              {x.overrideSample && (
                <>
                  <span className="gt-section-left gt-section-title">{t('FarmHarvestLevelObservation')}</span>
                  <span className="gt-section-left">
                    {getSampleDetails(t, this.props.units, x.overrideSample).map((d, idx) => (
                      <li key={idx}>{d}</li>
                    ))}
                  </span>
                </>
              )}
            </span>
          </td>
        </tr>
        {x.harvests.map(this.renderFieldReport)}
      </React.Fragment>
    );
  };

  renderPhoto = (path: SampleImage, idx: number) => {
    // TODO(savv): show more info about this picture, like the distance to sample and the compass bearing.
    const width = this.props.numPhotos > 40 ? 256 : 512;
    const classNames = ['gt-harvest-middle', 'gt-harvest-right', 'gt-harvest-left'];
    return (
      <span key={idx} className={classNames[idx % 3]}>
        <AuthedImage authedFetcher={this.props.authedFetcher} src={'photo/resize' + path.uri + '?width=' + width} />
      </span>
    );
  };

  renderSampleDetails = (s: Sample, idx: number, baseCrop: HarvestCrop | null) => {
    const {t} = this.props;

    const details = getSampleDetails(t, this.props.units, s, baseCrop);
    const nImages = s.images.length;
    if (details.length == 0 && nImages == 0) {
      return null;
    }

    const imageEls = s.images.map(this.renderPhoto);

    const nRows = Math.floor(nImages / 3);

    return (
      <React.Fragment key={idx}>
        <tr>
          <td className="gt-sample-details">
            {idx == 0 && (
              <span className="gt-harvest-col-all">
                <span className="bold">
                  <span className="gt-report-triangle" />
                  {[t('Comments'), t('AdditionalInfo')].join(' / ')}
                </span>
              </span>
            )}
            <span className={!imageEls.length ? 'gt-harvest-col-all' : 'gt-harvest-left'}>
              <span>{'#' + (idx + 1)}</span>
              <span className="gt-harvest-after-idx">
                {details.map((d, idx) => (
                  <li key={idx}>{d}</li>
                ))}
              </span>
            </span>
            {imageEls.slice(0, 2)}
            {nRows == 0 && <span className="gt-sample-separator" />}
          </td>
        </tr>
        {[...Array(nRows).keys()].map(x => (
          <tr>
            <td className="gt-sample-details">
              {imageEls.slice(2 + x * 3, 2 + x * 3 + 3)}
              {x == nRows - 1 && <span className="gt-sample-separator" />}
            </td>
          </tr>
        ))}
      </React.Fragment>
    );
  };

  renderPageBreak() {
    return (
      <React.Fragment>
        <tr className="gt-report-page-separator-before">
          <td />
        </tr>
        <tr className="no-print">
          <td className="gt-report-page-separator"></td>
        </tr>
        <tr className="gt-report-page-separator-after">
          <td />
        </tr>
      </React.Fragment>
    );
  }

  renderHiddenLink(link: string) {
    return (
      <a className="hidden-link" href={'#' + link}>
        X
      </a>
    );
  }

  render() {
    const {clock, t} = this.props;
    const {color, logo} = getReportStyling(this.props.farm!.user_group);

    const summaryLink = t('Summary');
    return (
      <div className="gt-report-page-center">
        <span>
          <table className="gt-report-container gt-report-layout">
            <thead>
              <tr>
                <th>
                  <ReportHeader
                    t={t}
                    clock={clock}
                    type="ExpertReport"
                    title={this.props.farm!.farm_name || t('Farm')}
                    logo={logo}
                    color={color}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="gt-report-summary" id={summaryLink}>
                    {this.renderFarmHeader()}
                    {this.renderHiddenLink(summaryLink)}
                    {this.renderSummary()}
                    <span className="gt-right-card gt-report-card">
                      <span className="gt-card-header">{t('EstimatedYield')}</span>
                      {this.props.summaryByHarvest.map(this.renderEstimatedCrop)}
                    </span>
                    {this.renderCropByArea()}
                  </div>
                </td>
              </tr>
              {this.props.summaryByHarvest.map(this.renderHarvestReport)}
            </tbody>
            <tfoot>
              <tr>
                <td className="gt-report-footer-placeholder">
                  {/*required in combination with div below for finicky display of footer on last printed page*/}
                </td>
              </tr>
            </tfoot>
          </table>
          <ReportFooter color={color} />
        </span>
      </div>
    );
  }
}

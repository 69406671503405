import {CropMonitoring} from './interfaces';

export const MonitoredCrop = [
  'corn-grain',
  'wheat',
  'rapeseed',
  'soybeans',
  'sunflower',
  'sugar-cane',
  'sugar-beet',
  'wheat-hard',
  'peas',
  'beans',
  'corn-silage',
  'barley',
  'barley-malting',
  'potatoes',
  'oats',
  'spelt',
  'triticale',
  'rye',
  'sorghum',
  'cotton',
  'rice',
  'chickpeas',
  'tomatoes',
] as const;
export type MonitoredCrop = (typeof MonitoredCrop)[number];

export const CropMonType = ['predicted-yield', 'benchmark-yield', 'expected-loss', 'historical-yield'] as const;
export type CropMonType = (typeof CropMonType)[number];

export type CropMonRes = CropMonitoring;

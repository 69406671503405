import * as Sentry from '@sentry/browser';
import {QueryClientProvider} from '@tanstack/react-query';
import 'antd/dist/antd.css';
import {History} from 'history';
import React from 'react';
import {Provider} from 'react-redux';
import {Store} from 'redux';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import {appQueryClient} from '../apis/appQueryClient';
import '../index.css';
// or 'antd/dist/antd.less'
import './App.css';
import Main from './Main';

export class App extends React.PureComponent<{apis: Apis; history: History}> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Main.componentDidCatch:', error, errorInfo);
    Sentry.captureException(error, {
      level: 'fatal',
      tags: {
        section: 'Main::componentDidCatch',
        componentStack: errorInfo.componentStack,
      },
    });
  }

  render() {
    return (
      <ApisContext.Provider value={this.props.apis}>
        <QueryClientProvider client={appQueryClient}>
          <Provider store={this.props.apis.store as Store}>
            <Main history={this.props.history} />
          </Provider>
        </QueryClientProvider>
      </ApisContext.Provider>
    );
  }
}

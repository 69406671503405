import {ValueUnit} from '../models/types';

export function roundDecimals(x: null, fractionDigits?: number): null;
export function roundDecimals(x: number, fractionDigits?: number): number;
export function roundDecimals(x: null | number, fractionDigits?: number): null | number;
export function roundDecimals(x: null | number, fractionDigits = 2): null | number {
  if (x == null) {
    return null;
  }
  if (isNaN(x)) {
    return NaN;
  }

  return Number(x.toFixed(fractionDigits));
}

export function roundedUnit<T extends ValueUnit>(obj: T, fractionDigits?: number): T;
export function roundedUnit<T extends ValueUnit>(obj: null, fractionDigits?: number): null;
export function roundedUnit<T extends ValueUnit>(obj: null | T, fractionDigits?: number): null | T;
export function roundedUnit<T extends ValueUnit>(obj: null | T, fractionDigits?: number): null | T {
  return obj == null
    ? null
    : {
        ...obj,
        val: roundDecimals(obj.val, fractionDigits),
      };
}

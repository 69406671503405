import {DatePicker} from 'antd';
import {RangePickerProps} from 'antd/lib/date-picker';
import moment, {Moment} from 'moment';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {resetDbFilter, setDbFilter} from '../../../src/redux/actions/filters';
import {DbFilterState} from '../../../src/redux/reducers/filters';
import {getFilters} from '../../../src/selectors/filters';
import {formatDate} from '../../../src/text/date';
import {useApis} from '../apis/ApisContext';
import {MinimizableFilter, MinimizableFilterProps} from './DataFilter';

const {RangePicker} = DatePicker;

type DateRangeFilterProp = {
  minimized: boolean;
  label: string;
  field: keyof Pick<DbFilterState, 'added_on' | 'emergence_date' | 'max_ndvi_date'>;
} & Partial<RangePickerProps> &
  Pick<MinimizableFilterProps, 'newRibbon'>;

const DateRangeFilter = (props: DateRangeFilterProp) => {
  const {minimized, label, field, newRibbon, ...pickerProps} = props;
  const apis = useApis();
  const dispatch = useDispatch();
  const {startDate, endDate} = useSelector(getFilters)[field];

  const handleChange = (range: null | [null | Moment, null | Moment], _: [string, string]): void => {
    if (!range) {
      dispatch(resetDbFilter(field));
      return;
    }

    const [startDate, endDate] = range;

    dispatch(
      setDbFilter({
        [field]: {
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
        },
      }),
    );
  };

  const minimizedLabel = useMemo(() => {
    if (minimized) {
      let minimizedValue = '';
      if (startDate) {
        minimizedValue += formatDate(apis.t, startDate);
      }
      if (startDate || endDate) {
        minimizedValue += ' - ';
      }
      if (endDate) {
        minimizedValue += formatDate(apis.t, endDate);
      }
      return minimizedValue ? `${label} → ${minimizedValue}` : undefined;
    }
  }, [minimized, startDate, endDate, label, apis.t]);

  return (
    <MinimizableFilter
      newRibbon={newRibbon}
      minimized={minimized}
      value={minimizedLabel}
      label={`${label} ${apis.t('DateRange')}`}
      onClose={() => {
        dispatch(resetDbFilter(field));
      }}>
      <RangePicker
        style={{minWidth: 'calc(400px + 10px)'}}
        defaultPickerValue={[moment(apis.clock.now()).subtract(3, 'months'), moment(apis.clock.now())]}
        placeholder={[`${label} ${apis.t('StartDate')}`, `${label} ${apis.t('EndDate')}`]}
        value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
        format={d => formatDate(apis.t, d.toDate())}
        onChange={handleChange}
        {...pickerProps}
        // TODO(seb): Add localization, see https://4x.ant.design/components/date-picker/#Localization
      />
    </MinimizableFilter>
  );
};

export default DateRangeFilter;

import {ClockI} from '../../Clock';
import {FailedToFetch, FetcherFunc} from '../../FetcherFunc';
import {I18nFunction} from '../../i18n/i18n';
import {Crop} from '../../models/interfaces';
import {StateI} from '../../selectors/stateI';
import {tsToStr} from '../../util/date-util';
import {ThunkAction} from '../types';

interface SetCropsAction {
  type: 'SET_CROPS';
  crops: Crop[];
  lastUpdated: string;
}

interface AddUsedCropIdAction {
  type: 'ADD_USED_CROP_ID';
  crop_id: string;
}

export type CropActions = SetCropsAction | AddUsedCropIdAction;

export function syncCrops(
  clock: ClockI,
  t: I18nFunction,
  authedFetcher: FetcherFunc,
): ThunkAction<Promise<void>, Pick<StateI, 'crops'>, SetCropsAction> {
  return async (dispatch, getState) => {
    const {lastUpdated, crops} = getState().crops;
    if (
      !lastUpdated ||
      clock.now() - Date.parse(lastUpdated) > 2 * 24 * 60 * 60 * 1000 ||
      Object.keys(crops).length == 0
    ) {
      try {
        const crops: Crop[] = await authedFetcher({method: 'GET', path: 'api/crop'});
        dispatch({type: 'SET_CROPS', crops, lastUpdated: tsToStr(clock.now())});
      } catch (e) {
        if (e instanceof FailedToFetch) {
          if (Object.keys(getState().crops.crops).length == 0) {
            throw new Error('Failed to fetch crops, and state.crops.crops was empty!');
          }
          return;
        }
        throw e;
      }
    }
  };
}

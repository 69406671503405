import {CircleLayer, FillLayer, LineLayer, SymbolLayer} from 'mapbox-gl';
import {
  CROP_COLORS,
  FIELD_BACKGROUND_GREY,
  HAIL_COLORS,
  HAIL_OPACITY,
  INTERFIELD_COLORS,
  INTERFIELD_OPACITY,
  INTERYIELD_OPACITY,
  INTRAFIELD_COLORS,
  INTRAFIELD_OPACITY,
  MDA_CUSTOM_A_COLOR,
  MDA_CUSTOM_A_OPACITY,
  MIN_FIELD_LAYER_ZOOM,
  PRECIPITATION_COLORS,
  PRECIPITATION_OPACITY,
  RAINSTORM_COLORS,
  RAINSTORM_OPACITY,
  SOIL_MOISTURE_COLORS,
  SOIL_MOISTURE_OPACITY,
  TEMPERATURE_COLORS,
  TEMPERATURE_OPACITY,
  VEGETATION_COLORS,
  VEGETATION_OPACITY,
  WIND_COLORS,
  WIND_OPACITY,
  benchmarkYieldColors,
  expectedLossColors,
  floodRiskSourceLayer,
  getInteryieldFillColor,
  yieldRatioColors,
} from '../../../src/constants/colors';
import {
  farmPointLayerFilter,
  fieldPointLayerFilter,
  fieldPolyLayerFilter,
  samplePointLayerFilter,
  samplePolyLayerFilter,
  shapeOpacity,
} from '../../../src/layers/map-layers';
import {MonitoredCrop} from '../../../src/models/crop-mon';

export const fieldLayer: CircleLayer = {
  id: 'fieldPoints',
  type: 'circle',
  filter: fieldPointLayerFilter,
  source: 'entity-features',
  paint: {
    'circle-opacity': 1,
    'circle-stroke-width': 0.5,
    'circle-radius': 10,
    'circle-color': {
      property: 'crop',
      type: 'categorical',
      stops: Object.entries(CROP_COLORS),
      default: '#ffffff',
    },
  },
};
export const fieldShapeLayerBase: FillLayer = {
  type: 'fill',
  id: 'field-shapes-base',
  source: 'entity-features',
  filter: fieldPolyLayerFilter,
  minzoom: MIN_FIELD_LAYER_ZOOM,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'crop',
      type: 'categorical',
      stops: Object.entries(CROP_COLORS),
      default: '#dddddd',
    },
    'fill-outline-color': 'black',
  },
};
export const fieldBgShapeLayer: FillLayer = {
  ...fieldShapeLayerBase,
  id: 'field-shapes-bg',
  paint: {'fill-color': FIELD_BACKGROUND_GREY, 'fill-outline-color': 'black'},
};
export const fieldShapeLayerSat: LineLayer = {
  type: 'line',
  id: 'field-shapes-sat',
  source: 'entity-features',
  filter: fieldPolyLayerFilter,
  minzoom: MIN_FIELD_LAYER_ZOOM,
  maxzoom: 22,
  paint: {
    'line-color': {
      property: 'crop',
      type: 'categorical',
      stops: Object.entries(CROP_COLORS),
      default: '#dddddd',
    },
    'line-width': 3,
  },
};
export const soilLayer: FillLayer = {
  id: 'soil-layer',
  type: 'fill',
  source: 'soil-moisture',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(SOIL_MOISTURE_COLORS),
      default: '#dddddd',
    },
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(SOIL_MOISTURE_OPACITY),
      default: 0.5,
    },
  },
};
export const floodRiskFillLayer: FillLayer = {
  id: 'flood-fill-layer',
  type: 'fill',
  source: 'flood-risk',
  'source-layer': floodRiskSourceLayer,
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-pattern': 'diagonal-stripes',
    'fill-antialias': false,
    'fill-opacity': 0.6,
  },
};
export const floodRiskLineLayer: LineLayer = {
  id: 'flood-line-layer',
  type: 'line',
  source: 'flood-risk',
  'source-layer': floodRiskSourceLayer,
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'line-color': '#000000',
    'line-width': 2,
    'line-opacity': 0.6,
  },
};
export const tempLayer: FillLayer = {
  id: 'temperature-layer',
  type: 'fill',
  source: 'temperature',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(TEMPERATURE_COLORS),
      default: '#dddddd',
    },
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(TEMPERATURE_OPACITY),
      default: 0.5,
    },
  },
};
export const surfaceTempLayer: FillLayer = {
  id: 'temperature-layer',
  type: 'fill',
  source: 'temperature',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(TEMPERATURE_COLORS),
      default: '#dddddd',
    },
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(TEMPERATURE_OPACITY),
      default: 0.5,
    },
  },
};
export const precipitationLayer: FillLayer = {
  id: 'precipitation-layer',
  type: 'fill',
  source: 'precipitation',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(PRECIPITATION_COLORS),
      default: '#dddddd',
    },
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(PRECIPITATION_OPACITY),
      default: 0.5,
    },
  },
};
export const rainstormLayer: FillLayer = {
  id: 'rainstorm-layer',
  type: 'fill',
  source: 'rainstorm',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(RAINSTORM_COLORS),
      default: '#dddddd',
    },
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(RAINSTORM_OPACITY),
      default: 0.5,
    },
  },
};
export const windLayer: FillLayer = {
  id: 'wind-layer',
  type: 'fill',
  source: 'wind',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(WIND_COLORS),
      default: '#dddddd',
    },
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(WIND_OPACITY),
      default: 0.5,
    },
  },
};

export const mdaCustomALayer: FillLayer = {
  id: 'custom-a-layer',
  type: 'fill',
  source: 'custom-a',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': MDA_CUSTOM_A_COLOR,
    'fill-antialias': true,
    'fill-opacity': MDA_CUSTOM_A_OPACITY,
    'fill-outline-color': 'black',
  },
};

export const hailLayer: FillLayer = {
  id: 'hail-layer',
  type: 'fill',
  source: 'hail',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(HAIL_COLORS),
      default: '#dddddd',
    },
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(HAIL_OPACITY),
      default: 0.5,
    },
  },
};
export const ndviLayer: FillLayer = {
  id: 'ndvi-layer',
  type: 'fill',
  source: 'vegetation',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(VEGETATION_COLORS),
      default: '#dddddd',
    },
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(VEGETATION_OPACITY),
      default: 0.5,
    },
  },
};
export const intrafieldLayer: FillLayer = {
  id: 'intrafield',
  type: 'fill',
  source: 'intrafield',
  minzoom: MIN_FIELD_LAYER_ZOOM,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(INTRAFIELD_COLORS),
      default: '#dddddd',
    },
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(INTRAFIELD_OPACITY),
      default: 1.0,
    },
  },
};
export const interfieldLayer: FillLayer = {
  id: 'interfield',
  type: 'fill',
  source: 'interfield',
  minzoom: MIN_FIELD_LAYER_ZOOM,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(INTERFIELD_COLORS),
      default: '#dddddd',
    },
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(INTERFIELD_OPACITY),
      default: 1.0,
    },
  },
};
export const interyieldLayer: FillLayer = {
  id: 'interyield',
  type: 'fill',
  source: 'interyield',
  minzoom: MIN_FIELD_LAYER_ZOOM,
  maxzoom: 22,
  paint: {
    'fill-color': getInteryieldFillColor(),
    'fill-antialias': false,
    'fill-opacity': {
      property: 'shape_type',
      type: 'categorical',
      stops: Object.entries(INTERYIELD_OPACITY),
      default: 1.0,
    },
  },
};
export const interyieldLabelLayer: SymbolLayer = {
  id: 'interyieldLabels',
  type: 'symbol',
  minzoom: MIN_FIELD_LAYER_ZOOM,
  maxzoom: 22,
  source: 'interyield',
  layout: {
    'text-size': 14,
    'text-padding': ['interpolate', ['linear'], ['zoom'], MIN_FIELD_LAYER_ZOOM, 20, 20, 45],
  },
  paint: {
    'text-color': 'black',
    'text-halo-color': 'white',
    'text-halo-width': 2,
  },
};
export const farmLayer: SymbolLayer = {
  id: 'farmPoints',
  type: 'symbol',
  filter: farmPointLayerFilter,
  source: 'entity-features',
  layout: {
    'icon-image': 'marker-15',
    'icon-allow-overlap': true,
    'icon-size': 1,
  },
};

function getBenchmarkYieldLayer(crop: MonitoredCrop): FillLayer {
  return {
    id: 'benchmark-yield',
    type: 'fill',
    source: 'crop-mon',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': {
        property: 'value',
        type: 'interval',
        default: '#ffffff',
        stops: benchmarkYieldColors[crop],
      },
      'fill-antialias': true,
      'fill-opacity': 0.7,
      'fill-outline-color': 'black',
    },
  };
}

export const benchmarkYieldLayers = {} as {[P in MonitoredCrop]: FillLayer};

MonitoredCrop.forEach(x => (benchmarkYieldLayers[x] = getBenchmarkYieldLayer(x)));

export const expectedLossLayer: FillLayer = {
  id: 'expected-loss',
  type: 'fill',
  source: 'crop-mon',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'value',
      type: 'interval',
      default: '#ffffff',
      stops: expectedLossColors,
    },
    'fill-antialias': true,
    'fill-opacity': 0.7,
    'fill-outline-color': 'black',
  },
};
export const yieldRatioLayer: FillLayer = {
  id: 'yield-ratio',
  type: 'fill',
  source: 'crop-mon',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    'fill-color': {
      property: 'value',
      type: 'interval',
      default: '#ffffff',
      stops: yieldRatioColors,
    },
    'fill-antialias': true,
    'fill-opacity': 0.7,
    'fill-outline-color': 'black',
  },
};
export const sampleLayer: SymbolLayer = {
  id: 'samplePoints',
  type: 'symbol',
  filter: samplePointLayerFilter,
  source: 'entity-features',
  layout: {
    'icon-image': 'triangle-15',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'text-allow-overlap': true,
    'icon-size': 1,
    'text-field': ['get', 'label'],
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-radial-offset': 0.5,
  },
  paint: {
    'text-color': 'black',
    'text-halo-color': 'white',
    'text-halo-width': 2,
  },
};
export const sampleShapeLayer: FillLayer = {
  id: 'sample-shapes-base',
  type: 'fill',
  filter: samplePolyLayerFilter,
  source: 'entity-features',
  minzoom: MIN_FIELD_LAYER_ZOOM + 2,
  maxzoom: 22,
  paint: {
    'fill-color': 'red',
    'fill-opacity': shapeOpacity,
    'fill-outline-color': 'white',
  },
};
export const entityFeatureLayers = new Set([
  farmLayer.id,
  fieldLayer.id,
  sampleLayer.id,
  fieldShapeLayerBase.id,
  fieldBgShapeLayer.id,
  fieldShapeLayerSat.id,
  sampleShapeLayer.id,
]);

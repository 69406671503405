import React from 'react';
import {WithChildren} from '../../../src/util/react-util';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import {isDev} from '../apis/env';
import {reportErr} from './err';

interface ErrorBoundaryState {
  message?: string;
  stacktrace?: string;
}

interface ErrorBoundaryProps extends WithChildren {
  hideErrorMessage?: boolean;
}

export class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  static contextType = ApisContext;
  context!: Apis;

  state: ErrorBoundaryState = {};

  static getDerivedStateFromError(error: any): ErrorBoundaryState {
    return {
      message: (typeof error == 'object' && error?.message) ?? 'Unspecified Error',
      stacktrace: error instanceof Error ? error.stack : '(No stacktrace)',
    };
  }

  componentDidCatch(error: any) {
    reportErr(error);
  }

  render() {
    if (!this.state.message) {
      // Render children if no error bubbled up to us.
      return <>{this.props.children}</>;
    }

    if (isDev) {
      // Render dev focused information, if dev environment.
      return (
        <div>
          <h1 style={{color: 'red'}}>{this.context.t('UnknownErrorOccurred')}</h1>
          <h2>{this.state.message}</h2>
          <pre>{this.state.stacktrace}</pre>
        </div>
      );
    }

    if (this.props.hideErrorMessage) {
      return null;
    }

    // Render message for the user otherwise.
    return (
      <div>
        <h1 style={{color: 'orange'}}>{this.context.t('UnknownErrorOccurred')}</h1>
      </div>
    );
  }
}

import {Select} from 'antd';
import React from 'react';
import {FormyComponent, FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {simplify} from '../../../src/util/cmp';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import {useCropOptions} from '../queries/useCropOptions';

interface HarvestI {
  crop_id: null | string;
}

interface FormyCropSelectorProps<F extends HarvestI> extends FormyComponentProps<F, 'crop_id'> {
  // A list of crop IDs to show in the dropdown, ordered with the most common first.
  options: {value: string; label: string}[];
}

export class FormyCropSelector<F extends HarvestI> extends FormyComponent<F, 'crop_id', FormyCropSelectorProps<F>> {
  static contextType = ApisContext;
  context!: Apis;

  onChange = (value: null | string) => {
    this.handleChange(value);
    if (value) {
      this.context.store.dispatch({type: 'ADD_USED_CROP_ID', crop_id: value});
    }
  };

  render() {
    return (
      <span>
        <Select<string>
          data-testid="FormyCropSelector"
          className={`formy-select-style ${this.error ? 'formy-item-error' : ''}`}
          onChange={this.onChange}
          options={this.props.options}
          showSearch
          filterOption={(input, option) =>
            typeof option?.label == 'string' && simplify(option.label).includes(simplify(input))
          }
          allowClear
          disabled={this.mode == 'view'}
          placeholder={this.context.t('SelectCropType')}
          value={this.value ?? undefined}
          onBlur={this.handleBlur}
        />
      </span>
    );
  }
}

export default function FormyCropSelector2<F extends HarvestI>(props: Omit<FormyCropSelectorProps<F>, 'options'>) {
  const {options} = useCropOptions(true, true);
  return <FormyCropSelector {...props} options={options} />;
}

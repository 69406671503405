import {FetcherFunc} from './FetcherFunc';
import alpha3to2 from './alpha-3-to-2.json';
import {LngLat} from './geo';
import {getUuid} from './get-uuid';

export interface PlaceAutocompleteResult {
  description: string;
  place_id: string;
}

const sessionToken = getUuid();

// https://developers.google.com/places/web-service/autocomplete
export async function placeAutocomplete(
  authedFetcher: FetcherFunc,
  q: string,
  language: string,
  userLocation?: LngLat,
  countriesAlpha3?: string[],
): Promise<PlaceAutocompleteResult[]> {
  const params: [string, string][] = [
    ['input', q],
    ['type', 'geocode'],
    ['language', language],
    ['sessiontoken', sessionToken],
  ];
  if (userLocation) {
    params.push(['location', `${userLocation[1]},${userLocation[0]}`]);
  }
  if (countriesAlpha3?.length && countriesAlpha3.length <= 5) {
    // components — A grouping of places to which you would like to restrict your results. Currently, you can use
    // components to filter by up to 5 countries. Countries must be passed as a two character, ISO 3166-1 Alpha-2
    // compatible country code. For example: components=country:fr would restrict your results to places within France.
    // Multiple countries must be passed as multiple country:XX filters, with the pipe character (|) as a separator.
    // For example: components=country:us|country:pr|country:vi|country:gu|country:mp would restrict your results to
    // places within the United States and its unincorporated organized territories.
    const components = countriesAlpha3
      .map(alpha3 => alpha3to2[alpha3 as keyof typeof alpha3to2])
      .filter(x => !!x)
      .map(alpha2 => `country:${alpha2}`)
      .join('|');
    params.push(['components', components]);
  }

  const path = 'maps-googleapis/maps/api/place/autocomplete/json';
  const res: {predictions: PlaceAutocompleteResult[]; status: string} = await authedFetcher({
    method: 'GET',
    path,
    params,
  });
  if (res.status != 'OK') {
    return [];
  } else {
    return res.predictions;
  }
}

interface GoogLatlng {
  lat: number;
  lng: number;
}

interface GoogGeometry {
  location: GoogLatlng;
  viewport: {northeast: GoogLatlng; southwest: GoogLatlng};
}

export async function getLocationByPlaceId(authedFetcher: FetcherFunc, place_id: string): Promise<null | LngLat> {
  const params: [string, string][] = [
    ['place_id', place_id],
    ['sessiontoken', sessionToken],
  ];
  const path = 'maps-googleapis/maps/api/place/details/json';
  const res = await authedFetcher({method: 'GET', path, params});
  const geom: null | GoogGeometry = res?.result?.geometry ?? null;
  return geom && [geom.location.lng, geom.location.lat];
}

import React from 'react';
import {useSelector} from 'react-redux';
import {AreaValue} from '../../../src/models/types';
import {convertArea, getUnitSystem} from '../../../src/selectors/units';
import {useApis} from '../apis/ApisContext';

interface AreaProps {
  value?: AreaValue | null;
}

// Component to render a localized `AreaValue` converted to the current unit system, derived from the current locale.
// Null values will be rendered as '-'.
export const Area: React.FC<AreaProps> = React.memo(({value}) => {
  const {t} = useApis();
  const unitSystem = useSelector(getUnitSystem);
  const targetUnit = unitSystem.areaUnit;
  const currentArea: AreaValue | null = value ?? null;
  const converted = convertArea(targetUnit, currentArea);
  if (!converted) return <>-</>;
  return <>{t({type: 'AreaUnit', ...converted})}</>;
});

interface AreaHaProps {
  value?: number | null;
}

// Component to render a localized area number given in hectares converted to the current unit system, derived from the
// current locale. Null values will be rendered as '-'.
export const AreaHa: React.FC<AreaHaProps> = React.memo(({value}) => (
  <Area value={typeof value === 'number' ? {val: value, unit: 'hectares'} : null} />
));

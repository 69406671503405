import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {Alert, Col, DatePicker, DatePickerProps, Divider, Row, Typography} from 'antd';
import {Polygon} from 'geojson';
import moment from 'moment';
import {PanelMode} from 'rc-picker/lib/interface';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {MIN_PLANET_TILES_ZOOM} from '../../../src/constants/colors';
import {Bbox, bboxToGeoJSONPolygon, getOverlapRatio} from '../../../src/geo';
import {useDebounce} from '../../../src/util/hooks';
import {useApis} from '../apis/ApisContext';
import {getMap} from '../redux/selectors';
import './MapToolbox.css';

const {Paragraph} = Typography;

export const planetApiKey = ['PL', 'AK', '2ad6c', 'fae4405', '40ca9a', '7433961', '1e94e87'].join('');

export interface PlanetFeature {
  _links: {
    _self: string;
    assets: string;
    thumbnail: string;
  };
  _permissions: string[];
  assets: string[];
  geometry: Polygon;
  id: string;
  properties: {
    acquired: string;
    anomalous_pixels: number;
    clear_confidence_percent: number;
    clear_percent: number;
    cloud_cover: number;
    cloud_percent: number;
    ground_control: boolean;
    gsd: number;
    heavy_haze_percent: number;
    instrument: string;
    item_type: string;
    light_haze_percent: number;
    pixel_resolution: number;
    provider: string;
    published: string;
    publishing_stage: string;
    quality_category: string;
    satellite_azimuth: number;
    satellite_id: string;
    shadow_percent: number;
    snow_ice_percent: number;
    strip_id: string;
    sun_azimuth: number;
    sun_elevation: number;
    updated: string;
    view_angle: number;
    visible_confidence_percent: number;
    visible_percent: number;
  };
  type: string;
}

interface PlanetData {
  _links: {
    _first: string;
    _next: string;
    _self: string;
  };
  features: PlanetFeature[];
  type: string;
}

export const getRequestFilters = (bounds: Bbox, dateRange: [string, string]) => {
  return {
    filter: {
      type: 'AndFilter',
      config: [
        {
          type: 'GeometryFilter',
          field_name: 'geometry',
          config: bboxToGeoJSONPolygon(bounds),
        },
        {
          type: 'DateRangeFilter',
          field_name: 'acquired',
          config: {
            gte: dateRange[0],
            lte: dateRange[1],
          },
        },
        {
          type: 'RangeFilter',
          config: {
            gte: 0,
            lte: 0.25,
          },
          field_name: 'cloud_cover',
        },
        {
          type: 'PermissionFilter',
          config: ['assets:download'],
        },
        {
          type: 'OrFilter',
          config: [
            {
              type: 'AndFilter',
              config: [
                {
                  type: 'StringInFilter',
                  field_name: 'item_type',
                  config: ['PSScene'],
                },
                {
                  type: 'StringInFilter',
                  field_name: 'publishing_stage',
                  config: ['standard', 'finalized'],
                },
              ],
            },
            {
              type: 'StringInFilter',
              field_name: 'item_type',
              config: ['SkySatCollect'],
            },
          ],
        },
      ],
    },
    item_types: ['PSScene'],
  };
};

interface QueryParams {
  start: string | null;
  end: string | null;
  bounds: Bbox | null;
}

export function usePlanetQuickSearch(params: QueryParams, enabled: boolean): UseQueryResult<PlanetData | null, Error> {
  const apis = useApis();
  return useQuery<PlanetData | null, Error, PlanetData | null, [string, QueryParams]>(
    ['planetQuickSearch', params],
    async ({queryKey}): Promise<PlanetData | null> => {
      const [_key, {start, end, bounds}] = queryKey;
      if (!bounds || !start || !end) {
        return null;
      }
      const response = apis.fetchFn('https://api.planet.com/data/v1/quick-search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + btoa(planetApiKey + ':'),
        },
        body: JSON.stringify(getRequestFilters(bounds, [start, end])),
      });
      return (await response).json();
    },
    {
      enabled,
    },
  );
}

interface GetPlanetItemQueryParams {
  planetItemId: string | null;
}

export function usePlanetGetItem(
  params: GetPlanetItemQueryParams,
  enabled: boolean,
): UseQueryResult<PlanetFeature | null, Error> {
  const apis = useApis();
  return useQuery<PlanetFeature | null, Error, PlanetFeature | null, [string, GetPlanetItemQueryParams]>(
    ['planetGetItem', params],
    async ({queryKey}): Promise<PlanetFeature | null> => {
      const [_key, {planetItemId}] = queryKey;
      if (!planetItemId) {
        return null;
      }
      const response = apis.fetchFn(`https://api.planet.com/data/v1/item-types/PSScene/items/${planetItemId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + btoa(planetApiKey + ':'),
        },
      });
      return (await response).json();
    },
    {
      enabled,
    },
  );
}

interface PlanetToolboxProps {
  planetItemId: string | null;
  onPlanetItemIdChanged: (planetItemId: string | null) => void;
}

export function PlanetToolbox({planetItemId, onPlanetItemIdChanged}: PlanetToolboxProps) {
  const {clock, t} = useApis();
  const {lastBounds, lastZoom} = useSelector(getMap);
  const planetEnabled = lastZoom >= MIN_PLANET_TILES_ZOOM;
  const bounds = useDebounce(clock, lastBounds, 400);
  const [selectedPlanetItemData, setSelectedPlanetItemData] = useState<PlanetFeature | null>(null);
  const [dateRange, setDateRange] = useState<{start: string | null; end: string | null}>({
    start: moment().startOf('month').utcOffset(0, true).toISOString(),
    end: moment().endOf('month').utcOffset(0, true).toISOString(),
  });
  const [defaultPickerValue, setDefaultPickerValue] = useState<moment.Moment>(moment());
  const [datePickerMode, setDatePickerMode] = useState<PanelMode>('date');
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);

  const handleDateChange = (date: moment.Moment | null) => {
    setSelectedDate(date ?? null);
  };

  const handleDatePanelChange = (value: moment.Moment, mode: PanelMode) => {
    setDatePickerMode(mode);
    if (mode == 'date') {
      setDateRange({
        start: value.startOf('month').utcOffset(0, true).toISOString(),
        end: value.endOf('month').utcOffset(0, true).toISOString(),
      });
    }
  };

  // Get item details on initial load, in case it is set in the URL
  const {data: planetItemData, isLoading: isPlanetItemLoading} = usePlanetGetItem(
    {planetItemId},
    !!(planetItemId && !selectedPlanetItemData),
  );

  useEffect(() => {
    if (!isPlanetItemLoading && planetItemData && !selectedDate && !selectedPlanetItemData) {
      setSelectedPlanetItemData(planetItemData);
      // Note: Do not set the selectedDate here, because it might be possible that the selected planet item would be changed based on the best feature selection.
    }
  }, [isPlanetItemLoading, planetItemData, selectedDate, selectedPlanetItemData]);

  const {data, error, isLoading} = usePlanetQuickSearch(
    {start: dateRange.start, end: dateRange.end, bounds},
    planetEnabled,
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!selectedDate) {
      // In case there are no features available for date range, then open the date picker with the previous month selected.
      if (data && data.features.length == 0) {
        setDefaultPickerValue(moment().subtract(1, 'month'));
        setDateRange({
          start: moment().subtract(1, 'month').startOf('month').utcOffset(0, true).toISOString(),
          end: moment().subtract(1, 'month').endOf('month').utcOffset(0, true).toISOString(),
        });
      }
    } else {
      const itemsForSelectedDate =
        selectedDate && data
          ? data.features
              .filter(
                item => moment(item.properties.acquired).format('YYYY-MM-DD') === selectedDate.format('YYYY-MM-DD'),
              )
              .map(f => {
                const overlapRatio = getOverlapRatio(f.geometry, bboxToGeoJSONPolygon(bounds!)) ?? 0;
                return {...f, overlapRatio};
              })
              .sort((a, b) => b.overlapRatio - a.overlapRatio)
          : [];

      const itemId = itemsForSelectedDate.length > 0 ? itemsForSelectedDate[0].id : planetItemId;
      if (itemId !== planetItemId) {
        onPlanetItemIdChanged(itemId);
      }

      const feature = data?.features?.find(item => item.id == itemId) ?? null;
      setSelectedPlanetItemData(feature);
    }
  }, [bounds, data, dateRange.start, isLoading, onPlanetItemIdChanged, planetItemId, selectedDate]);

  const disableUnavailableDates: DatePickerProps['disabledDate'] = useCallback(
    (current: moment.Moment): boolean => {
      // If search is in progress, disable all dates
      if (isLoading) {
        return true;
      }

      // We don't want to disable the dates, in case the user is not in date selection mode.
      if (datePickerMode != 'date') {
        return false;
      }

      const availableDates =
        data && data.features
          ? new Set(data.features.map(item => moment(item.properties.acquired).format('YYYY-MM-DD')))
          : null;

      return !!(current && availableDates && !availableDates.has(current.format('YYYY-MM-DD')));
    },
    [data, datePickerMode, isLoading],
  );

  const showZoomWarning = lastZoom < MIN_PLANET_TILES_ZOOM;
  const showSelectDateWarning = !selectedDate && !planetItemId;
  const showBoundsWarning = !isLoading && planetItemId && !selectedPlanetItemData;

  return (
    <>
      <div data-testid="planet-toolbox" className="planet-toolbox">
        {showZoomWarning ? (
          <Alert description={t('ZoomInViewLayer')} type="info" showIcon />
        ) : showSelectDateWarning ? (
          <Alert description={t('PleaseSelectDate')} type="info" showIcon />
        ) : null}
        {planetEnabled && (
          <>
            {error && <Alert description={t('SomethingWentWrongError')} type="error" showIcon />}
            {showBoundsWarning && (
              <Alert description={t('PlanetTilesBoundsWarning')} type="warning" showIcon closable />
            )}
            <Row className="planet-toolbox-controls-container" justify={'space-between'}>
              <DatePicker
                defaultPickerValue={defaultPickerValue}
                data-testid="planet-toolbox-date-picker"
                placeholder={t('SelectDate')}
                value={selectedDate}
                onChange={handleDateChange}
                disabledDate={disableUnavailableDates}
                onPanelChange={handleDatePanelChange}
                allowClear={false}
                showToday={false}
              />
            </Row>
            {selectedPlanetItemData && (
              <Col
                data-testid={`planet-scene-${selectedPlanetItemData.id}`}
                className="planet-toolbox-scene-details-container">
                <Typography.Title level={5}>
                  {moment(selectedPlanetItemData.properties.acquired).format('LLLL')}
                </Typography.Title>
                <Row className="planet-toolbox-scene-details" gutter={20}>
                  <img
                    className="planet-toolbox-scene-thumbnail"
                    src={`${selectedPlanetItemData._links.thumbnail}?api_key=${planetApiKey}`}
                    alt="Thumbnail"
                  />
                  <Col>
                    <Paragraph>
                      {t('CloudCover')} <b>{selectedPlanetItemData.properties.cloud_percent}%</b>
                    </Paragraph>
                    <Paragraph>
                      {t('SnowIce')} <b>{selectedPlanetItemData.properties.snow_ice_percent}%</b>
                    </Paragraph>
                    <Paragraph>
                      {t('PixelResolution')} <b>{selectedPlanetItemData.properties.pixel_resolution}m</b>
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
            )}
          </>
        )}
      </div>
      <Divider />
    </>
  );
}

import {Select} from 'antd';
import React from 'react';
import {ClockI} from '../../../src/Clock';
import {GRADIENT_PALETTE} from '../../../src/constants/colors';
import {GetSampleCountsByAdjusterRow, UserEntity} from '../../../src/models/interfaces';
import {userEntityDesc} from '../../../src/text/desc';
import {parseDate} from '../../../src/util/date-util';
import {ApisContext} from '../apis/ApisContext';
import {useUserEntitiesByEmail} from '../queries/options';
import BarChart, {BarChartDatum} from '../report/BarChart';
import {DashboardCard} from './DashboardCard';
import {useSampleCountsByAdjuster} from './queries';

function samplesByLossAdjuster(
  data: GetSampleCountsByAdjusterRow[],
  start: Date,
  userEntitiesByEmail: Record<string, UserEntity>,
): BarChartDatum[] {
  const countByAdjuster: Record<string, number> = {};
  for (const {added_by, sample_date, count} of data) {
    if (!added_by || !sample_date || parseDate(sample_date)! <= start) {
      continue;
    }
    const addedBy = userEntityDesc(added_by, userEntitiesByEmail);
    countByAdjuster[addedBy] = countByAdjuster[addedBy] ?? 0;
    countByAdjuster[addedBy] += count ?? 0;
  }

  const experts = Object.entries(countByAdjuster).map(([key, value]) => ({key, value}) as BarChartDatum);
  experts.sort((a, b) => b.value - a.value);
  return experts.map((x, i) => ({...x, color: GRADIENT_PALETTE[i]}));
}

type ExpertComparisonModes = 'samples' | 'fields';
type ExpertComparisonRange = 'week' | 'month' | 'year' | 'all';

interface ExpertComparisonCardState {
  mode: ExpertComparisonModes;
  range: null | ExpertComparisonRange;
}

export const ExpertComparisonCard: React.FC = () => {
  const {clock, t} = React.useContext(ApisContext);
  const {data: userEntitiesByEmail, isFetching} = useUserEntitiesByEmail();
  const [state, setState] = React.useState<ExpertComparisonCardState>({mode: 'samples', range: 'all'});
  const sampleCountsByAdjuster = useSampleCountsByAdjuster();
  React.useEffect(() => {
    if (sampleCountsByAdjuster.isSuccess && userEntitiesByEmail) {
      setState(state => ({
        ...state,
        range: getNarrowestInterestingRange(clock, sampleCountsByAdjuster.data, userEntitiesByEmail),
      }));
    }
  }, [clock, sampleCountsByAdjuster.data, sampleCountsByAdjuster.isSuccess, userEntitiesByEmail]);
  const onChangeType = React.useCallback((value: ExpertComparisonModes) => {
    setState(state => ({...state, mode: value}));
  }, []);
  const onChangeTime = React.useCallback((value: ExpertComparisonRange) => {
    setState(state => ({...state, range: value}));
  }, []);
  const experts =
    state.mode === 'samples' && sampleCountsByAdjuster.isSuccess && userEntitiesByEmail
      ? samplesByLossAdjuster(sampleCountsByAdjuster.data, getStartDate(clock, state.range), userEntitiesByEmail)
      : [];
  // TODO(savv): Re-add the ability to show field stats, by fetching those dynamically.
  return (
    <DashboardCard
      long
      title={t('ExpertRanking')}
      loading={sampleCountsByAdjuster.isFetching || isFetching}
      hasData={experts.length > 0}>
      <div className="expert-card-selector">
        <Select<ExpertComparisonModes> onChange={onChangeType} value={state.mode}>
          <Select.Option value="samples">{t('Samples')}</Select.Option>
        </Select>
        <Select<ExpertComparisonRange> onChange={onChangeTime} value={state.range!}>
          <Select.Option value="week">{t('PastWeek')}</Select.Option>
          <Select.Option value="month">{t('PastMonth')}</Select.Option>
          <Select.Option value="year">{t('PastYear')}</Select.Option>
          <Select.Option value="all">{t('AllTime')}</Select.Option>
        </Select>
      </div>
      <BarChart width={300} height={experts.length * 30 + 50} id="expert-chart" data={experts} formatValue={String} />
    </DashboardCard>
  );
};

function getNarrowestInterestingRange(
  clock: ClockI,
  data: GetSampleCountsByAdjusterRow[],
  userEntitiesByEmail: Record<string, UserEntity>,
) {
  for (const range of ['week', 'month', 'year'] as const) {
    const experts = samplesByLossAdjuster(data, getStartDate(clock, range), userEntitiesByEmail);
    if (experts.length >= 3) {
      return range;
    }
  }

  return 'all';
}

function getStartDate(clock: ClockI, range: ExpertComparisonCardState['range']): Date {
  let startDate: null | Date = new Date(clock.now());
  if (range == 'all') {
    startDate = new Date(0);
  } else if (range == 'year') {
    startDate.setUTCFullYear(startDate.getUTCFullYear() - 1);
  } else if (range == 'month') {
    startDate.setUTCMonth(startDate.getUTCMonth() - 1);
  } else if (range == 'week') {
    startDate.setUTCDate(startDate.getUTCDate() - 7);
  }

  return startDate;
}

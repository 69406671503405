import {EnvironmentOutlined} from '@ant-design/icons';
import {Row, createColumnHelper} from '@tanstack/react-table';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {PALETTE_COLORS} from '../../../src/constants/colors';
import {LngLat} from '../../../src/geo';
import {
  Farm,
  GetFarmHarvestRowsRequest,
  GetFarmHarvestRowsRow,
  HarvestCrop,
  HarvestData,
  HarvestYear,
  getFarmHarvestRows,
} from '../../../src/models/interfaces';
import {aggregateFarmHarvestRows} from '../../../src/selectors/aggregators';
import {getCrops} from '../../../src/selectors/crops';
import {getUnitSystem} from '../../../src/selectors/units';
import {YieldStats} from '../../../src/selectors/yield';
import {filterNulls} from '../../../src/util/arr-util';
import {filtersToRequest} from '../../../src/util/req-util';
import {roundDecimals} from '../../../src/util/roundDecimals';
import {useApis} from '../apis/ApisContext';
import {CropTags} from '../components/CropTags';
import {DownloadButton} from '../components/DownloadButton';
import {EntityInfoView} from '../components/EntityInfo';
import {InfinityTable, InfinityTableProps, defaultColumnSizes, getAllowedOrdering} from '../components/InfinityTable';
import {YieldTHa} from '../components/Yield';
import {ErrorBoundary} from '../util/ErrorBoundary';
import './Farms.css';
import {ExpanderCol} from './ListView';
import './ListView.css';

interface FarmHarvestItem {
  farm: Farm;
  crop_id: string;
  harvest_year: HarvestYear;
  feasible_yield_t_ha?: number;
  insured_yield_t_ha?: number;
  estimated_yield_t_ha?: number;
  harvest_data: HarvestData[];
}

const columnHelper = createColumnHelper<FarmHarvestItem>();

const initialSorting = {id: 'added_on', desc: true} as const;

const placeholderData = getFarmHarvestPlaceholder();

function getFarmHarvestPlaceholder(num: number = 15): FarmHarvestItem[] {
  const placeholder: FarmHarvestItem[] = [];
  const randomStr = (min: number, max: number) => ''.padStart(min + Math.floor(Math.random() * (max - min)), 'x');
  const randomElement = <T,>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)];
  for (let i = 0; i < num; i++) {
    placeholder.push({
      farm: {
        // id must be unique, else react will not discard the placeholder rows.
        farm_id: '__placeholder_' + i,
        farm_name: randomStr(5, 25),
        address: randomStr(10, 20) + '\n' + randomStr(4, 7) + ' ' + randomStr(10, 15),
        external_farm_id: randomStr(5, 25),
        farm_location: [0, 0],
        added_on: new Date().toISOString(),
      } as Farm,
      crop_id: randomElement(HarvestCrop as unknown as string[]),
      harvest_year: '2020',
      feasible_yield_t_ha: Math.random() * 10,
      insured_yield_t_ha: Math.random() * 10,
      estimated_yield_t_ha: Math.random() * 10,
      harvest_data: [],
    });
  }
  return placeholder;
}

const SortableColumns = ['farm_name', 'external_farm_id', 'added_on'] as const;
type SortableColumn = (typeof SortableColumns)[number];

const fetchSetSize: number = 100;
const getRowId = (row: FarmHarvestItem): string => row.farm.farm_id + '$$$' + row.crop_id + '$$$' + row.harvest_year;
const getSortValue = (row: FarmHarvestItem, columnId: SortableColumn): string | null => row.farm[columnId] ?? null;
const getFarmId = (row: FarmHarvestItem): string => row.farm.farm_id;
const getFarmLocation = (row: FarmHarvestItem): LngLat | null => row.farm.farm_location;
const getFarmName = (row: FarmHarvestItem): string | null => row.farm.farm_name;
const getFarmAddress = (row: FarmHarvestItem): string | null => row.farm.address;
const getExternalFarmId = (row: FarmHarvestItem): string | null => row.farm.external_farm_id;
const getCropId = (row: FarmHarvestItem): string => row.crop_id;
const getHarvestYear = (row: FarmHarvestItem): HarvestYear => row.harvest_year;
const getFeasibleYield = (row: FarmHarvestItem): number | undefined => row.feasible_yield_t_ha;
const getInsuredYield = (row: FarmHarvestItem): number | undefined => row.insured_yield_t_ha;
const getEstimatedYield = (row: FarmHarvestItem): number | undefined => row.estimated_yield_t_ha;

export default function FarmHarvests() {
  const {authedFetcher, t} = useApis();
  const [hasData, setHasData] = React.useState<boolean>(false);
  const units = useSelector(getUnitSystem);
  const crops = useSelector(getCrops);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: '__details__',
        cell: ({row}) =>
          row.getCanExpand() && (
            <span onClick={() => row.toggleExpanded()} title={row.getIsExpanded() ? undefined : t('Details')}>
              <ExpanderCol.Expander isExpanded={row.getIsExpanded()} />
            </span>
          ),
        size: defaultColumnSizes.xxs,
        header: '', // Make sure to always return a string header for simpler handling downstream.
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.accessor<typeof getFarmLocation, ReturnType<typeof getFarmLocation>>(getFarmLocation, {
        id: 'farm_location',
        header: '',
        cell: ({getValue, row}) =>
          getValue() ? (
            <Link to={{pathname: '/map/base', search: '?farm_id=' + getFarmId(row.original)}} title={t('ShowOnMap')}>
              <EnvironmentOutlined style={{color: PALETTE_COLORS.secondary}} />
            </Link>
          ) : null,
        size: defaultColumnSizes.xxs,
        enableSorting: false,
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.accessor<typeof getFarmName, ReturnType<typeof getFarmName>>(getFarmName, {
        id: 'farm_name',
        header: t('FarmName'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: true,
      }),
      columnHelper.accessor<typeof getFarmAddress, ReturnType<typeof getFarmAddress>>(getFarmAddress, {
        id: 'address',
        header: t('FarmAddress'),
        size: defaultColumnSizes.l,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getExternalFarmId, ReturnType<typeof getExternalFarmId>>(getExternalFarmId, {
        id: 'external_farm_id',
        header: t('Reference'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: true,
      }),
      columnHelper.accessor<typeof getCropId, ReturnType<typeof getCropId>>(getCropId, {
        id: 'crop_id',
        header: t('harvest_crop'),
        size: defaultColumnSizes.l,
        cell: ({getValue}) => (
          <span className="mask">
            <CropTags cropIds={[getValue()]} />
          </span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getHarvestYear, ReturnType<typeof getHarvestYear>>(getHarvestYear, {
        id: 'harvest_year',
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        header: t('harvest_year'),
        size: defaultColumnSizes.s,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getFeasibleYield, ReturnType<typeof getFeasibleYield>>(getFeasibleYield, {
        id: 'feasible_yield',
        cell: ({getValue, row}) => (
          <span className="mask number">
            <YieldTHa value={getValue()} cropId={getCropId(row.original)} />
          </span>
        ),
        header: t('FeasibleYield'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getInsuredYield, ReturnType<typeof getInsuredYield>>(getInsuredYield, {
        id: 'insured_yield',
        cell: ({getValue, row}) => (
          <span className="mask number">
            <YieldTHa value={getValue()} cropId={getCropId(row.original)} />
          </span>
        ),
        header: t('InsuredYield'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getEstimatedYield, ReturnType<typeof getEstimatedYield>>(getEstimatedYield, {
        id: 'estimated_yield',
        cell: ({getValue, row}) => (
          <span className="mask number">
            <YieldTHa value={getValue()} cropId={getCropId(row.original)} />
          </span>
        ),
        header: t('EstimatedYield'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
      }),
    ],
    [],
  );

  const fetchDataFromApi: InfinityTableProps<FarmHarvestItem>['fetchData'] = useCallback(
    async ({orderBy, filters, pageParam}): Promise<FarmHarvestItem[]> => {
      const req: GetFarmHarvestRowsRequest = {
        ...filtersToRequest(filters),
        ordering: getAllowedOrdering(orderBy, SortableColumns, 'added_on'),
        row_count: fetchSetSize,
        continue_from_val: pageParam?.continue_from_val,
        continue_from_id: pageParam?.continue_from_id?.substring(0, 36), // uuid has 36 chars
      };
      const farmRows: GetFarmHarvestRowsRow[] = (await getFarmHarvestRows(
        authedFetcher,
        req,
      )) as GetFarmHarvestRowsRow[];
      setHasData(hasData => hasData || farmRows.length > 0);
      return farmRows.map(getYieldFromHarvestData);

      function getYieldFromHarvestData(row: GetFarmHarvestRowsRow): FarmHarvestItem {
        const stats: YieldStats = aggregateFarmHarvestRows(units, crops, row);
        return {
          farm: row.farm!,
          crop_id: row.crop_id!,
          harvest_year: row.harvest_year!,
          feasible_yield_t_ha: roundDecimals(stats.feasible) ?? undefined,
          insured_yield_t_ha: roundDecimals(stats.insured) ?? undefined,
          estimated_yield_t_ha: roundDecimals(stats.estimated) ?? undefined,
          harvest_data: filterNulls(row.harvest_data ?? []),
        };
      }
    },
    [],
  );

  return (
    <span className="list-view list-farm-harvests">
      <ErrorBoundary>
        <div className="list-view-action-buttons">
          <DownloadButton label={'DownloadXLSX'} downloadType="farm-harvest" enabled={hasData} />
        </div>
        <InfinityTable<FarmHarvestItem>
          columns={columns}
          initialSorting={initialSorting}
          fetchData={fetchDataFromApi}
          fetchSetSize={fetchSetSize}
          tableId="list/farm-harvest"
          getRowId={getRowId}
          getSortValue={getSortValue}
          placeholderData={placeholderData}
          renderDetails={RowFarmDetails}
          indentDetails={1}
          onNumberOfDataItemsChange={count => setHasData(count > 0)}
        />
      </ErrorBoundary>
    </span>
  );
}

const RowFarmDetails: React.FC<{row: Row<FarmHarvestItem>}> = React.memo(({row}) => {
  const harvestDataObj = Object.fromEntries(row.original.harvest_data.map(hd => [hd.harvest_id, hd]));
  const selectedEntity = {farm: row.original.farm, harvests: []};
  return <EntityInfoView selectedEntity={selectedEntity} expanded={true} harvestDataObj={harvestDataObj} />;
});

import {Feature, Point} from 'geojson';
import {throttle} from 'lodash';
import mapboxgl, {MapLayerMouseEvent} from 'mapbox-gl';
import {RefObject, useCallback, useEffect} from 'react';
import {LayerParams} from '../../../src/layers/layer-params';
import {MapLayers} from '../../../src/layers/map-layers';
import {MapNavFocus} from '../../../src/map/map-focus';
import {EntityGeoProps, isEntityGeoPropsFeature, isPointFeature} from '../../../src/models/geojson';
import {GetMapStatsRow} from '../../../src/models/interfaces';
import {andPredicate, filterArr} from '../../../src/util/arr-util';
import {reportErr} from '../util/err';
import {
  benchmarkYieldLayers,
  expectedLossLayer,
  farmLayer,
  fieldLayer,
  sampleLayer,
  yieldRatioLayer,
} from './layers-specs';
import {mapStatsLayerId} from './useMapStatsLayer';
import {useShowPopup} from './useShowPopup';

// The set of layers that are interactive, excluding mapStatsLayerId, as it needs some special handling. See
// handleMouseMove.
const interactiveLayers = [
  benchmarkYieldLayers.wheat.id,
  yieldRatioLayer.id,
  expectedLossLayer.id,
  expectedLossLayer.id,
  sampleLayer.id,
  fieldLayer.id,
  farmLayer.id,
  mapStatsLayerId,
] as const;

export function useMouseHandlers(
  map: RefObject<undefined | mapboxgl.Map>,
  layer: MapLayers,
  canonical_date: null | string,
  visibleFieldIds: Set<string>,
  layerParams: null | LayerParams,
  focus: null | MapNavFocus,
  onFeaturesClicked?: (features: Feature<Point, EntityGeoProps>[]) => boolean,
) {
  const showPopup = useShowPopup(map, layer, canonical_date, layerParams, focus);
  const handleMouseMove = useCallback(throttle(handleMouseMove_, 0.1), []);

  useEffect(() => {
    map.current!.on('click', interactiveLayers, handleClick);
    return () => {
      map.current!.off('click', interactiveLayers, handleClick);
    };
  }, [onFeaturesClicked, showPopup]);

  useEffect(() => {
    if (!map.current) {
      reportErr(new Error('map object was not available on mount!'));
      return;
    }

    map.current.on('mousemove', interactiveLayers, handleMouseMove);
    map.current.on('mouseleave', interactiveLayers, handleMouseLeave);

    return () => {
      map.current!.off('mousemove', interactiveLayers, handleMouseMove);
      map.current!.off('mouseleave', interactiveLayers, handleMouseLeave);
    };
  }, []);

  function handleClick(e: MapLayerMouseEvent) {
    const features = e.features ?? [];
    const entityFeatures = filterArr(features, andPredicate(isEntityGeoPropsFeature, isPointFeature));
    if (onFeaturesClicked && entityFeatures.length && onFeaturesClicked(entityFeatures)) {
      return; // MapRouter handled this click.
    }

    for (const f of features) {
      try {
        if (showPopup(f)) {
          return;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  function handleMouseLeave() {
    map.current!.getCanvas().style.cursor = '';
  }

  function hasOpaqueMapStat(x: null | GetMapStatsRow) {
    return x?.total_area_ha != null;
  }

  // The map stats layer hides features with transparency based on their feature state; this makes
  // it harder to detect when the viewable layer is entered, so we use this special (slower) check in
  // that case.
  function handleMouseMove_(e: MapLayerMouseEvent) {
    map.current!.getCanvas().style.cursor = 'pointer';
    if (e.features?.every(x => x.layer.id == mapStatsLayerId && !hasOpaqueMapStat(x.state as GetMapStatsRow))) {
      map.current!.getCanvas().style.cursor = '';
    }
  }
}

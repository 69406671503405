import {Card, CardProps} from 'antd';
import React from 'react';
import {WithChildren} from '../../../src/util/react-util';
import {LocalizedEmptyImage} from './Dashboard';

type DashboardCard = WithChildren &
  Pick<CardProps, 'title' | 'loading'> & {
    hasData: boolean;
    long?: boolean;
  };

// A slightly customized version of the Card component from antd. Supporting long scrollable content. And providing
// an image when no data is present.
export const DashboardCard: React.FC<DashboardCard> = ({children, hasData, loading, long, title}) => {
  return (
    <Card title={title} loading={loading} bodyStyle={{overflowY: long ? 'auto' : 'hidden'}}>
      {hasData ? children : <LocalizedEmptyImage />}
    </Card>
  );
};

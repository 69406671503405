import {EnvironmentOutlined} from '@ant-design/icons';
import {useQuery} from '@tanstack/react-query';
import {Row, createColumnHelper} from '@tanstack/react-table';
import {Button, Checkbox, Popover, Table, Tooltip} from 'antd';
import {ColumnType} from 'antd/lib/table';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {PALETTE_COLORS} from '../../../src/constants/colors';
import {getEnabledFlags} from '../../../src/feature-flags';
import {LngLat} from '../../../src/geo';
import {
  Farm,
  GetFarmRowsRequest,
  GetFarmRowsRow,
  GetStatsByHarvestRow,
  getFarmRows,
  getStatsByHarvest,
} from '../../../src/models/interfaces';
import {DbFilterState} from '../../../src/redux/reducers/filters';
import {getFilters} from '../../../src/selectors/filters';
import {farmDesc} from '../../../src/text/desc';
import {filterNulls} from '../../../src/util/arr-util';
import {filtersToRequest} from '../../../src/util/req-util';
import {useApis} from '../apis/ApisContext';
import {AreaHa} from '../components/Area';
import {CropTag, CropTags, useCropTags} from '../components/CropTags';
import {DownloadButton} from '../components/DownloadButton';
import {EmailList} from '../components/EmailList';
import {FileAttachment} from '../components/FileAttachment';
import {InfinityTable, InfinityTableProps, defaultColumnSizes, getAllowedOrdering} from '../components/InfinityTable';
import {YieldTHa} from '../components/Yield';
import {ErrorBoundary} from '../util/ErrorBoundary';
import './Farms.css';
import {ExpanderCol} from './ListView';
import './ListView.css';

const columnHelper = createColumnHelper<GetFarmRowsRow>();

const initialSorting = {id: 'added_on', desc: true} as const;

const placeholderData = getFarmPlaceholder();

function getFarmPlaceholder(num: number = 15): GetFarmRowsRow[] {
  const placeholder: GetFarmRowsRow[] = [];
  const randomStr = (min: number, max: number) => ''.padStart(min + Math.floor(Math.random() * (max - min)), 'x');
  for (let i = 0; i < num; i++) {
    placeholder.push({
      farm: {
        // id must be unique, else react will not discard the placeholder rows.
        farm_id: '__placeholder_' + ('' + i).padStart(5, '0'),
        farm_name: randomStr(5, 25),
        address: randomStr(10, 20) + '\n' + randomStr(4, 7) + ' ' + randomStr(10, 15),
        user_group: randomStr(10, 30),
        external_farm_id: randomStr(5, 25),
        farm_location: null,
        comments: null,
        added_on: new Date().toISOString(),
      } as GetFarmRowsRow['farm'],
      farm_crop_ids: [],
      total_area_ha: Math.floor(Math.random() * 40 * 10) / 10,
      num_fields: 42,
      num_samples: 814,
    });
  }
  return placeholder;
}

const fetchSetSize: number = 100;

// Use farm_id as the row id.
const getRowId = (row: GetFarmRowsRow): string => row.farm!.farm_id as string;

// Explicitly list the sortable columns for get_farm_rows here. If a new sortable column is added to get_farm_rows,
// it must be added here as well. This is a safety measure to ensure we are only trying to sort on supported columns.
const SortableColumns = ['farm_name', 'external_farm_id', 'added_on'] as const;
type SortableColumn = (typeof SortableColumns)[number];
const getSortValue = (row: GetFarmRowsRow, columnId: SortableColumn): string | null => row.farm![columnId];

const getLocation = (row: GetFarmRowsRow): LngLat | null => row.farm!.farm_location;
const getFarmName = (row: GetFarmRowsRow): string => row.farm!.farm_name || '-';
const getFarmAddress = (row: GetFarmRowsRow): string => row.farm!.address || '-';
const getFarmExternalRef = (row: GetFarmRowsRow): string => row.farm!.external_farm_id || '-';
const getFarmUserGroup = (row: GetFarmRowsRow): string => row.farm!.user_group || '-';
const getFarmEditors = (row: GetFarmRowsRow): string[] =>
  filterNulls(row.farm?.editors ?? [])?.map(e => e.substring(1)) ?? [];
const getFarmTotalArea = (row: GetFarmRowsRow): number => row.total_area_ha || 0;
const getFarmCrops = (row: GetFarmRowsRow): string[] => filterNulls(row.farm_crop_ids || []);
const getFarmNumFields = (row: GetFarmRowsRow): string => String(row.num_fields ?? '-');
const getFarmNumSamples = (row: GetFarmRowsRow): string => String(row.num_samples ?? '-');

export default function Farms() {
  const {authedFetcher, t} = useApis();
  const [selectedFarms, setSelectedFarms] = React.useState<GetFarmRowsRow[]>([]);
  const [hasData, setHasData] = React.useState<boolean>(false);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: '__details__',
        cell: ({row}) =>
          row.getCanExpand() && (
            <span onClick={() => row.toggleExpanded()} title={row.getIsExpanded() ? undefined : t('Details')}>
              <ExpanderCol.Expander isExpanded={row.getIsExpanded()} />
            </span>
          ),
        size: defaultColumnSizes.xxs,
        header: '', // Make sure to always return a string header for simpler handling downstream.
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.accessor<typeof getLocation, ReturnType<typeof getLocation>>(getLocation, {
        id: 'farm_location',
        header: '',
        cell: ({getValue, row}) =>
          getValue() ? (
            <Link to={{pathname: '/map/base', search: '?farm_id=' + row.id}} title={t('ShowOnMap')}>
              <EnvironmentOutlined style={{color: PALETTE_COLORS.secondary}} />
            </Link>
          ) : null,
        size: defaultColumnSizes.xxs,
        enableSorting: false,
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.display({
        id: '__selection__',
        cell: ({row}) =>
          row.getCanSelect() && <Checkbox checked={row.getIsSelected()} onChange={() => row.toggleSelected()} />,
        size: defaultColumnSizes.xxs,
        header: '', // Make sure to always return a string header for simpler handling downstream.
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.accessor<typeof getFarmName, ReturnType<typeof getFarmName>>(getFarmName, {
        id: 'farm_name',
        header: t('FarmName'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: true,
      }),
      columnHelper.accessor<typeof getFarmAddress, ReturnType<typeof getFarmAddress>>(getFarmAddress, {
        id: 'address',
        header: t('FarmAddress'),
        size: defaultColumnSizes.l,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getFarmExternalRef, ReturnType<typeof getFarmExternalRef>>(getFarmExternalRef, {
        id: 'external_farm_id',
        header: t('Reference'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: true,
      }),
      columnHelper.accessor<typeof getFarmUserGroup, ReturnType<typeof getFarmUserGroup>>(getFarmUserGroup, {
        id: 'user_group',
        header: t('UserGroup'),
        size: defaultColumnSizes.s,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getFarmEditors, ReturnType<typeof getFarmEditors>>(getFarmEditors, {
        id: 'editors',
        header: t('Editors'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => (
          <span className="mask">
            <EmailList emails={getValue()} />
          </span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getFarmTotalArea, ReturnType<typeof getFarmTotalArea>>(getFarmTotalArea, {
        id: 'total_area_ha',
        cell: ({getValue}) => (
          <span className="mask numbers">
            <AreaHa value={getValue()} />
          </span>
        ),
        header: t('TotalCultivatedArea'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getFarmCrops, ReturnType<typeof getFarmCrops>>(getFarmCrops, {
        id: 'farm_crop_ids',
        cell: ({getValue, row}) => (
          <Popover
            content={<CropStatsPopoverContent farmId={row.id} cropIds={getValue()} />}
            title={`${t('CropType-s')}: ${farmDesc(t, row.original.farm!)}`}
            placement="left">
            <span className="mask">
              <CropTags cropIds={getValue()} minimized />
            </span>
          </Popover>
        ),
        header: t('CropType-s'),
        size: defaultColumnSizes.m,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getFarmNumFields, ReturnType<typeof getFarmNumFields>>(getFarmNumFields, {
        id: 'num_fields',
        header: t('NumberOfFields'),
        size: defaultColumnSizes.xxs,
        cell: ({getValue}) => <span className="mask numbers">{getValue()}</span>,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getFarmNumSamples, ReturnType<typeof getFarmNumSamples>>(getFarmNumSamples, {
        id: 'num_samples',
        header: t('NumberOfSamples'),
        size: defaultColumnSizes.xxs,
        cell: ({getValue}) => <span className="mask numbers">{getValue()}</span>,
        enableSorting: false,
      }),
    ],
    [t],
  );

  const fetchDataFromApi: InfinityTableProps<GetFarmRowsRow>['fetchData'] = useCallback(
    async ({orderBy, filters, pageParam}): Promise<GetFarmRowsRow[]> => {
      const req: GetFarmRowsRequest = {
        ...filtersToRequest(filters),
        ...pageParam,
        // Supported ordering: added_on-desc, farm_name-asc, farm_name-desc, external_farm_id-asc, external_farm_id-desc
        ordering: getAllowedOrdering(orderBy, SortableColumns, 'added_on'),
        row_count: fetchSetSize,
      };
      const farmRows: GetFarmRowsRow[] = await getFarmRows(authedFetcher, req);
      setHasData(hasData => hasData || farmRows.length > 0);
      return farmRows;
    },
    [authedFetcher],
  );

  return (
    <span className="list-view">
      <ErrorBoundary>
        <FarmListActions selectedFarms={selectedFarms} hasData={hasData} />
        <InfinityTable<GetFarmRowsRow>
          columns={columns}
          initialSorting={initialSorting}
          fetchData={fetchDataFromApi}
          fetchSetSize={fetchSetSize}
          tableId="list/Farm"
          getRowId={getRowId}
          getSortValue={getSortValue}
          placeholderData={placeholderData}
          renderDetails={RowFarmDetails}
          indentDetails={1}
          onRowSelectionChange={setSelectedFarms}
          onNumberOfDataItemsChange={count => setHasData(count > 0)}
        />
      </ErrorBoundary>
    </span>
  );
}

const FarmDetails: React.FC<{farm: Farm; cropIds: string[]}> = React.memo(({farm, cropIds}) => {
  const {t} = useApis();
  return (
    <div className="farm-details">
      <div className="farm-details-row">
        <span className="farm-name">
          <img alt="Pin" src="/pin.svg" width="20" /> {farm.farm_name}
        </span>
        <span className="farm-address">{farm.address}</span>
        <span className="farm-portfolio">
          <label>{t('UserGroup')}</label>
          {farm.user_group}
        </span>
        <CropTags cropIds={cropIds} />
      </div>
      <div className="farm-details-row farm-details-attachments">
        {farm.attachments.map(file => (
          <FileAttachment key={file.uri} file={file} />
        ))}
      </div>
    </div>
  );
});

const RowFarmDetails: React.FC<{row: Row<GetFarmRowsRow>}> = React.memo(({row}) => (
  <FarmDetails farm={row.original.farm!} cropIds={filterNulls(row.original.farm_crop_ids ?? [])} />
));

interface FarmListActionsProps {
  hasData: boolean;
  selectedFarms: GetFarmRowsRow[];
}

// Component holding the action buttons on top of the list.
const FarmListActions: React.FC<FarmListActionsProps> = React.memo(({hasData, selectedFarms}) => {
  const {t} = useApis();
  const selectedFarmCount: number = selectedFarms.length;
  const hasSelectedFarms: boolean = selectedFarmCount > 0;
  const selectedFarmIds: string[] = selectedFarms.map(selected => selected.farm!.farm_id);
  const numGroups: number = new Set(selectedFarms.map(selected => selected.farm!.user_group)).size;
  const flags = useSelector(getEnabledFlags);

  return (
    <>
      <div className="list-view-action-buttons">
        {<DownloadButton label={'DownloadXLSX'} downloadType="farm" enabled={hasData} />}
        {flags.has('hideEditingFunctionality') ? null : !hasSelectedFarms ? (
          <Tooltip title={t('PleaseSelectFarm')}>
            <Button disabled>{t('AddEditors')}</Button>
          </Tooltip>
        ) : numGroups > 1 ? (
          <Tooltip title={t('AddEditorsOneGroup')}>
            <Button disabled>{t('AddEditors')}</Button>
          </Tooltip>
        ) : (
          <Link to={'/assign-editors/farm/' + selectedFarmIds.join(',')}>
            <Button id="add-editors">{t('AddEditors')}</Button>
          </Link>
        )}
        {flags.has('mergeEntities') &&
          !flags.has('hideEditingFunctionality') &&
          selectedFarmCount > 1 &&
          (numGroups > 1 ? (
            <Tooltip title={t('SelectOneGroup')}>
              <Button disabled>{t('Merge')}</Button>
            </Tooltip>
          ) : (
            <Link to={'/merge/farm/' + selectedFarmIds.join(',')}>
              <Button id="merge">{t('Merge')}</Button>
            </Link>
          ))}
        {flags.has('hideEditingFunctionality') ? null : !hasSelectedFarms ? (
          <Tooltip title={t('PleaseSelectFarm')}>
            <Button disabled>{t('Edit')}</Button>
          </Tooltip>
        ) : (
          <Link to={'/edit/farms?farm_ids=' + selectedFarmIds.join(',')}>
            <Button id="edit-farms">{t('Edit')}</Button>
          </Link>
        )}
        {!flags.has('hideEditingFunctionality') && (
          <Link to={'/add/farms'}>
            <Button>{t('AddFarm')}</Button>
          </Link>
        )}
      </div>
    </>
  );
});

function sort<Data extends object>(by: keyof Data, as: 'string' | 'number'): (a: Data, b: Data) => number {
  switch (as) {
    case 'number':
      return (a, b) => ((a[by] as unknown as number) ?? 0) - ((b[by] as unknown as number) ?? 0);
    case 'string':
      return (a, b) => ((a[by] as unknown as string) ?? '').localeCompare((b[by] as unknown as string) ?? '');
  }
}

const CropStatsPopoverContent: React.FC<{farmId: string; cropIds: string[]}> = ({farmId, cropIds}) => {
  const {authedFetcher, t} = useApis();
  const filters = useSelector(getFilters);
  const farmFilters: DbFilterState = {
    ...filters,
    farm_id: [farmId], // overwrite to return only the current farm
  };
  const {data = [], isFetching} = useQuery(['get_stats_by_harvest', farmFilters], () =>
    getStatsByHarvest(authedFetcher, filtersToRequest(farmFilters)),
  );
  type HarvestStat = GetStatsByHarvestRow & {cropTag?: CropTag};
  const cropTags = useCropTags(cropIds);
  const preparedData: HarvestStat[] = data
    .map(d => {
      const cropTag = cropTags.find(t => t.cropId === d.crop_id);
      if (!cropTag) {
        console.info('Could not find a CropTag for farm: %s, stats: %o', farmId, d);
      }
      return {
        ...d,
        cropTag,
      };
    })
    .sort((a, b) => (a.cropTag?.label ?? '').localeCompare(b.cropTag?.label ?? ''));
  type CheckedColumnsType<Data> = ColumnType<Data> & {
    // Extend the type to add a check that dataIndex is actually an existing key in our data structure.
    dataIndex: keyof Data;
  };
  const columns: CheckedColumnsType<HarvestStat>[] = [
    {
      title: t('harvest_crop'),
      dataIndex: 'crop_id',
      key: 'cropId',
      render: (cropId: string) => <CropTag {...cropTags.find(t => t.cropId === cropId)!} />,
      sorter: sort('crop_id', 'string'),
    },
    {
      title: t('HarvestYear'),
      dataIndex: 'harvest_year',
      key: 'year',
      sorter: sort('harvest_year', 'string'),
    },
    {
      title: t('TotalCultivatedArea'),
      dataIndex: 'total_area_ha',
      key: 'area',
      render: (val: number | null) => <AreaHa value={val} />,
      sorter: sort('total_area_ha', 'number'),
    },
    {
      title: t('EstimatedYieldAbbr'),
      dataIndex: 'estimated_yield_t_ha',
      key: 'yield',
      render: (val: number | null, record: HarvestStat) => (
        <YieldTHa value={val ?? undefined} cropId={record.crop_id} />
      ),
      sorter: sort('estimated_yield_t_ha', 'number'),
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={preparedData}
        size="small"
        loading={isFetching}
        rowKey={(row: HarvestStat) => row.crop_id! + row.harvest_year}
      />
    </div>
  );
};

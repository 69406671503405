import {CommonApis} from '../CommonApis';
import {FarmData, FieldData, HarvestData, PolicyData} from '../models/data';
import {Claim, ClaimDamage, Farm, Field, Harvest, Policy, Sample, Visit, VisitLite} from '../models/interfaces';
import {EntityType, TableName} from '../models/serialization';
import {OmitDbColumns, Uuid} from '../models/types';
import {
  DbActionTypes,
  DbMetaActions,
  insertMany,
  syncQueuedMutations,
  updateEntity,
  updateFarm,
  updateField,
} from '../redux/actions/db';
import {MutationQueueItem} from '../redux/reducers/db';
import {ThunkStore} from '../redux/types';
import {StateI} from '../selectors/stateI';
import {fetchEntitiesBy, fetchEntity, getFarmFields, getFarmHarvests} from '../util/fetchEntity';
import {ValidateShape} from '../util/obj-util';
import {PostgrestQuery} from '../util/postgrest-query';

// An abstraction that allows client & server code to share logic, while handling DB operations differently.
// NOTE: do not use this code for server-only or client-only logic, as it's quite clunky to use.
export interface StateOrm {
  fetchEntitiesBy(table: 'farm', q: PostgrestQuery | PostgrestQuery[]): Promise<Farm[]>;

  fetchEntitiesBy(table: 'policy', q: PostgrestQuery | PostgrestQuery[]): Promise<Policy[]>;

  fetchEntitiesBy(table: 'field', q: PostgrestQuery | PostgrestQuery[]): Promise<Field[]>;

  fetchEntitiesBy(table: 'harvest', q: PostgrestQuery | PostgrestQuery[]): Promise<Harvest[]>;

  fetchEntitiesBy(table: 'sample', q: PostgrestQuery | PostgrestQuery[]): Promise<Sample[]>;

  fetchEntitiesBy(table: 'visit', q: PostgrestQuery | PostgrestQuery[]): Promise<VisitLite[]>;

  fetchEntitiesBy(table: 'claim', q: PostgrestQuery | PostgrestQuery[]): Promise<Claim[]>;

  fetchEntitiesBy(table: 'claim_damage', q: PostgrestQuery | PostgrestQuery[]): Promise<ClaimDamage[]>;

  fetchEntitiesBy(table: string, q: PostgrestQuery | PostgrestQuery[]): Promise<any[]>;

  getFarmById(farm_id: string): Promise<null | Farm>;

  getFieldsByFarmId(farm_id: string): Promise<Field[]>;

  getFarmHarvestsForFarm(farm_id: string): Promise<Harvest[]>;

  updateEntity<T>(table: 'harvest', id: Uuid, entity: ValidateShape<T, Partial<HarvestData>>): Promise<void>;
  updateEntity<T>(table: 'policy', id: Uuid, entity: ValidateShape<T, Partial<PolicyData>>): Promise<void>;
  updateEntity<T>(table: TableName, id: Uuid, entity: ValidateShape<T, Partial<EntityType>>): Promise<void>;

  updateFarm<T>(farm_id: Uuid, farm: ValidateShape<T, Partial<FarmData>>): Promise<void>;

  updateField<T>(field_id: Uuid, field: ValidateShape<T, Partial<FieldData>>): Promise<void>;

  insertMany<F1, P, F2, H, V, C, CD>(
    partialFarms: ValidateShape<F1, OmitDbColumns<Farm>>[],
    partialPolicies: ValidateShape<P, OmitDbColumns<Policy>>[],
    partialFields: ValidateShape<F2, OmitDbColumns<Field>>[],
    partialHarvests: ValidateShape<H, OmitDbColumns<Harvest>>[],
    visits: ValidateShape<V, OmitDbColumns<Visit>>[],
    partialClaims: ValidateShape<C, OmitDbColumns<Claim>>[],
    partialClaimDamages: ValidateShape<CD, OmitDbColumns<ClaimDamage>>[],
  ): Promise<void>;

  commit(): Promise<void>;
}

export function getFetcherStateOrm<S extends Omit<StateI, 'db'>, A extends DbActionTypes | DbMetaActions>(
  store: ThunkStore<S, A>,
  apis: CommonApis,
  onCommitError: (item: MutationQueueItem, err: unknown) => Promise<void>,
): StateOrm {
  return {
    fetchEntitiesBy(table: string, q: PostgrestQuery | PostgrestQuery[]): Promise<any> {
      return fetchEntitiesBy(apis.authedFetcher, table, q);
    },
    async getFarmHarvestsForFarm(farm_id: string): Promise<Harvest[]> {
      return getFarmHarvests(apis.authedFetcher, farm_id);
    },
    async getFieldsByFarmId(farm_id: string): Promise<Field[]> {
      return getFarmFields(apis.authedFetcher, farm_id);
    },
    async commit(): Promise<void> {
      await store.dispatch(syncQueuedMutations(onCommitError, apis.authedFetcher, apis.clock, this));
    },
    async getFarmById(farm_id: string): Promise<Farm | null> {
      return fetchEntity(apis.authedFetcher, 'farm', farm_id);
    },
    async insertMany<F1, P, F2, H, V, C, CD>(
      partialFarms: ValidateShape<F1, OmitDbColumns<Farm>>[],
      partialPolicies: ValidateShape<P, OmitDbColumns<Policy>>[],
      partialFields: ValidateShape<F2, OmitDbColumns<Field>>[],
      partialHarvests: ValidateShape<H, OmitDbColumns<Harvest>>[],
      partialVisits: ValidateShape<V, OmitDbColumns<Visit>>[],
      partialClaims: ValidateShape<C, OmitDbColumns<Claim>>[],
      partialClaimDamages: ValidateShape<CD, OmitDbColumns<ClaimDamage>>[],
    ) {
      store.dispatch(
        insertMany(
          apis,
          partialFarms,
          partialPolicies,
          partialFields,
          partialHarvests,
          partialVisits,
          partialClaims,
          partialClaimDamages,
        ),
      );
    },
    async updateEntity<T>(table: TableName, id: Uuid, entity: ValidateShape<T, Partial<EntityType>>): Promise<void> {
      store.dispatch(updateEntity(apis, table, id, entity));
    },
    async updateFarm<T>(farm_id: Uuid, farm: ValidateShape<T, Partial<FarmData>>) {
      store.dispatch(updateFarm(apis, farm_id, farm));
    },
    async updateField<T>(field_id: Uuid, field: ValidateShape<T, Partial<FieldData>>) {
      store.dispatch(updateField(field_id, field));
    },
  };
}

export type EntityFetcher = Pick<StateOrm, 'fetchEntitiesBy'>;

import {FetcherFunc} from '../../../src/FetcherFunc';
import {CropMonCountryCode} from '../../../src/layers/layer-params';
import {MonitoredCrop} from '../../../src/models/crop-mon';
import {
  CropMonitoringRegionAvailability,
  CropMonitoringRegionalBestPredictions,
  HarvestSeason,
  HarvestYear,
} from '../../../src/models/interfaces';
import {convertYield, getUnitSystem, getYieldUnits} from '../../../src/selectors/units';
import {Apis} from '../apis/Apis';

export async function getCropMonRegions(authedFetcher: FetcherFunc, country_code?: CropMonCountryCode) {
  const params = country_code ? [['country_code', 'eq.' + country_code] as [string, string]] : undefined;
  const res = await authedFetcher({method: 'GET', path: 'api/crop_mon_regions', params});
  const regionNames: {[region_id: string]: string} = {};
  for (const {region_id, name} of res) {
    regionNames[region_id] = name;
  }
  return regionNames;
}

export interface BoxPlotDatum {
  date?: Date; // One element must have an undefined date; it becomes the "benchmark" boxplot in the front.
  median: number;
  q1: number;
  q3: number;
  min: number;
  max: number;
}

function transformCropMonSeries(
  apis: Apis,
  harvest_crop: MonitoredCrop | null,
  res: {series: Record<string, [number, number]>},
) {
  const units = getUnitSystem(apis.store.getState());
  const getConvertedYield = (val: number) => {
    const yieldUnit = getYieldUnits(units, harvest_crop)[0];
    return convertYield(yieldUnit, {unit: 'tons-per-hectare', val}, harvest_crop)?.val ?? 0;
  };
  const series: BoxPlotDatum[] = Object.entries(res.series).map(([label, [avg, stddev]]) => ({
    date: label == 'benchmark' ? undefined : new Date(label),
    median: getConvertedYield(avg),
    q1: getConvertedYield(avg - 0.675 * stddev),
    q3: getConvertedYield(avg + 0.675 * stddev),
    min: getConvertedYield(avg - 2.326 * stddev),
    max: getConvertedYield(avg + 2.326 * stddev),
  }));
  const dates = series.map(x => x.date?.toISOString().slice(0, 10) || '').filter(x => !!x);
  dates?.sort();
  return series;
}

export async function fetchCropMonSeries(
  apis: Apis,
  harvest_crop: MonitoredCrop,
  harvest_season: HarvestSeason,
  harvest_year: HarvestYear,
  region_id: string,
): Promise<BoxPlotDatum[]> {
  const params: [string, string][] = [
    ['harvest_crop', 'eq.' + harvest_crop],
    ['harvest_season', 'eq.' + harvest_season],
    ['harvest_year', 'eq.' + harvest_year],
    ['region_id', 'eq.' + region_id],
  ];

  const res: CropMonitoringRegionalBestPredictions[] = await apis.authedFetcher({
    method: 'GET',
    path: 'api/crop_monitoring_regional_best_predictions',
    params,
  });

  if (!res.length) {
    return [];
  }

  return transformCropMonSeries(apis, harvest_crop, res[0]);
}

export async function cropMonitoringRegionAvailability(
  apis: Apis,
  region_id: string,
): Promise<CropMonitoringRegionAvailability[]> {
  const params: [string, string][] = [['region_id', 'eq.' + region_id]];
  return apis.authedFetcher({
    method: 'GET',
    path: 'api/crop_monitoring_region_availability',
    params,
  });
}

import {Reducer, applyMiddleware, combineReducers, createStore} from 'redux';
import thunk from 'redux-thunk';
import {CropActions} from '../../../src/redux/actions/crops';
import {DbMetaActions} from '../../../src/redux/actions/db';
import {DbFilterActions} from '../../../src/redux/actions/filters';
import {CropState, crops} from '../../../src/redux/reducers/crops';
import {DbMetaState, dbMeta} from '../../../src/redux/reducers/db';
import {DbFilterState, filters} from '../../../src/redux/reducers/filters';
import {ThunkStore} from '../../../src/redux/types';
import map, {MapActions, MapState} from './map';

export interface State {
  dbMeta: DbMetaState;
  filters: DbFilterState;
  crops: CropState;
  map: MapState;
}

export type Actions = DbMetaActions | DbFilterActions | MapActions | CropActions;

const reducers: Reducer<State, Actions> = combineReducers({
  dbMeta: dbMeta,
  filters,
  map,
  crops: crops,
});

export const getStore = () => createStore(reducers, applyMiddleware(thunk)) as ThunkStore<State, Actions>;

import {createSelector} from 'reselect';
import {UserGroup} from '../models/interfaces';
import {DbMetaState} from '../redux/reducers/db';
import {filterNulls, remove} from '../util/arr-util';
import cmp from '../util/cmp';
import {getCurUserEmail, getDbMetaObj, getUserGroupMemberships} from './dbMeta';

export const getPreferredUserGroups = createSelector([getDbMetaObj], (dbMeta): string[] => {
  const res = dbMeta.userGroups.filter(x => x.preferred_owner).map(x => x.user_group);
  res.sort(cmp);
  return res;
});
export const getUserGroups = (state: {dbMeta: Pick<DbMetaState, 'userGroups'>}) => state.dbMeta.userGroups;
export const getUserGroupSet = createSelector(
  [getUserGroups],
  userGroups => new Set(userGroups.map(x => x.user_group)),
);
// grantors = true  => returns grantors for each group
// grantors = false => returns grantees for each group
function traverseUserGroups(grantors: boolean, userGroups: UserGroup[]): {[group: string]: undefined | Set<string>} {
  const transitiveUserGroups: {[group: string]: undefined | Set<string>} = {};

  function addEdge(from: string, to: string) {
    if (!transitiveUserGroups[from]) {
      transitiveUserGroups[from] = new Set([from]);
    }
    transitiveUserGroups[from]!.add(to);
  }

  for (const group of userGroups) {
    const grantee = group.user_group;
    addEdge(grantee, grantee);
    for (const grantor of filterNulls(group.grantors)) {
      if (grantors) {
        addEdge(grantee, grantor);
      } else {
        addEdge(grantor, grantee);
      }
    }
  }

  let done = false;
  for (let i = 0; !done && i < 100; ++i) {
    // Get all transitive user groups, with a max of 100 iterations.
    done = true;
    for (const group in transitiveUserGroups) {
      const groupRelatives = transitiveUserGroups[group];
      if (!groupRelatives) {
        console.warn('getTransitiveUserGroupGrantors:', group, '(group) was missing from user groups!', userGroups);
        continue;
      }

      for (const grantor of groupRelatives) {
        const grantorParents = transitiveUserGroups[grantor];
        if (!grantorParents) {
          console.warn(
            'getTransitiveUserGroupGrantors:',
            grantor,
            '(grantor) was missing from user groups!',
            userGroups,
          );
          continue;
        }

        for (const grantorParent of grantorParents) {
          if (!groupRelatives.has(grantorParent)) {
            done = false;
            groupRelatives.add(grantorParent);
          }
        }
      }
    }
  }

  return transitiveUserGroups;
}

export const getTransitiveUserGroupGrantors = createSelector([getUserGroups], traverseUserGroups.bind(null, true));
export const getTransitiveUserGroupGrantees = createSelector([getUserGroups], traverseUserGroups.bind(null, false));

export const getCurrentUserTransitiveFullMembershipsSet = createSelector(
  [getCurUserEmail, getTransitiveUserGroupGrantors, getUserGroupMemberships],
  (userEmail, transitiveUserGroups, memberships) => {
    return new Set(
      memberships
        .filter(m => m.membership_type == 'full' && m.email == userEmail)
        .flatMap(m => Array.from(transitiveUserGroups[m.user_group] ?? []))
        .filter(remove.nulls),
    );
  },
);

import * as Sentry from '@sentry/react';
import {History} from 'history';
import {isDev, isProd, versionStr} from './env';

export function initLogging(history: History, email?: string) {
  if (window.location.hostname == 'localhost') {
    return;
  }
  Sentry.init({
    environment: isProd ? 'production' : isDev ? 'dev' : 'staging',
    dsn: 'https://086c9572169044adb61ff5503e1acb00@sentry.io/3933187',
    maxBreadcrumbs: 750,
    maxValueLength: 1_000,
    release: versionStr,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/\/api\/rpc/, /\/api2\/export/, /\/report/, /\/tiles/],
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          // For react-router-dom 5.x we need to have access to the history object.
          history,
        ),
      }),
    ],
    // For finer control of sent transactions you can adjust this value, or use tracesSampler
    tracesSampleRate: 0.1,
  });
  Sentry.setUser({email});
}

import {Feature, Point} from 'geojson';
import mapboxgl from 'mapbox-gl';
import * as React from 'react';
import {RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import cropPeriods from '../../../src/crop-periods.json';
import equal from '../../../src/fast-deep-equal';
import {getEnabledFlags} from '../../../src/feature-flags';
import {Bbox, LngLat, fromArrBbox, getShapeBbox} from '../../../src/geo';
import diagonalStripesImage from '../../../src/images/diagonal-stripes.png';
import markerImage from '../../../src/images/pin.png';
import triangleImage from '../../../src/images/triangle.png';
import {getLatestLayerDate} from '../../../src/layers/canonical-date';
import {LayerParams} from '../../../src/layers/layer-params';
import {MapLayers, getStyleUrl} from '../../../src/layers/map-layers';
import {MapNavFocus} from '../../../src/map/map-focus';
import {EntityGeoProps} from '../../../src/models/geojson';
import {getCrops} from '../../../src/selectors/crops';
import {getTodaysDate} from '../../../src/selectors/dbMeta';
import {getCountryCodeGroups, getUnitSystem} from '../../../src/selectors/units';
import {getUserConfig} from '../../../src/selectors/userConfig';
import {ApisContext} from '../apis/ApisContext';
import SpinningDots from '../components/SpinningDots';
import {getLastLocation, getLastZoom} from '../redux/selectors';
import {reportErr} from '../util/err';
import './Map.css';
import Mapbox from './Mapbox';
import {getMapboxLayersSources} from './layers-sources';
import {entityFeatureLayers} from './layers-specs';
import {useCurrentMarker} from './useCurrentMarker';
import {useEntityFeatures} from './useEntityFeatures';
import {useLayerFeatures} from './useLayerFeatures';
import {SelectableMapStatValues, useMapStatsLayer} from './useMapStatsLayer';
import {useMouseHandlers} from './useMouseHandlers';

export type MapProps = {
  focus: null | MapNavFocus;
  layer: MapLayers;
  canonical_date: null | string;
  layerParams: null | LayerParams;
  onFeaturesClicked?: (features: Feature<Point, EntityGeoProps>[]) => boolean;
  showEntityFeatures: boolean;
  onMapboxMapInstance: (map: mapboxgl.Map) => void; // See MapboxProps.mapCb
  selectedMapStat?: SelectableMapStatValues;
};

const DEFAULT_ZOOM_LEVELS_BY_TYPE: {[P in MapNavFocus['type']]: number} = {
  lnglat: 14,
  address: 14,
  farm: 12,
  field: 13,
  sample: 14,
};

interface InitialViewport {
  recalcAfterFetch: boolean;
  centerCoords: LngLat;
  zoomLevel: number;
  bounds?: Bbox;
}

// Map shows a map containing the currently selected layer (interfield, soil moisture, etc) as well as all filtered
// entity features (fields, field shapes, etc). It is responsible for fetch the underlying data for these, calculating
// the right set of Sources and Layers, and passing those to the Mapbox component, which then creates and manages
// the underlying mapboxgl.Map object.
export default ({
  layer,
  canonical_date,
  layerParams,
  showEntityFeatures,
  onFeaturesClicked,
  focus,
  onMapboxMapInstance,
  selectedMapStat = 'total_area_ha',
}: MapProps) => {
  const {t} = useContext(ApisContext);
  const todaysDate = useSelector(getTodaysDate);
  if (!canonical_date) {
    canonical_date = getLatestLayerDate(todaysDate, layer);
  }

  const marker = useCurrentMarker(focus);
  const crops = useSelector(getCrops),
    units = useSelector(getUnitSystem),
    lastZoom = useSelector(getLastZoom);
  const userGroupViewport = useSelector(getUserGroupViewport);
  const lastLocation = useSelector(getLastLocation);
  const [initialViewport, setInitialViewport] = useState<InitialViewport>(calcInitialViewport());
  useEffect(changeInitialViewport, [marker]);
  const map = useRef<mapboxgl.Map>();
  const innerMapCb = useCallback((x: mapboxgl.Map) => {
    map.current = x;
    map.current.on('style.load', () => {
      // (Re-)Load images whenever the styleURL has changed, else images will be missing after switching between layers
      // using different styleURLs.
      // Relying on an useEffect hook based on a changed styleURL is not sufficient. Likely because the style might not
      // be done loading, when the useEffect hook is called, which means we add an image to a style that is about to be
      // replaced, and the new style thus lacks the image. Therefore, we rely on the mapbox event system, ensuring we
      // only add the images once the style has actually been loaded.
      loadImage(map.current!, 'diagonal-stripes', diagonalStripesImage);
      loadImage(map.current!, 'marker-15', markerImage);
      loadImage(map.current!, 'triangle-15', triangleImage);
    });
    onMapboxMapInstance(x);
  }, []);
  const [visibleFieldIds, setVisibleFieldIds] = useState(new Set<string>());
  const fieldFeaturesCb = useCallback((newVisibleFieldIds: Set<string>) => {
    if (!equal(visibleFieldIds, newVisibleFieldIds)) {
      setVisibleFieldIds(newVisibleFieldIds);
    }
  }, []);
  const {layerGeojson, loadingLayer} = useLayerFeatures(map, layer, canonical_date, visibleFieldIds, layerParams);
  const styledataSignal = useMapStyledataSignal(map);
  const {isLoading: loadingMapStats, sumMapStats} = useMapStatsLayer(
    map,
    styledataSignal,
    layer,
    showEntityFeatures,
    selectedMapStat,
  );
  const {entityFeatures, loadingEntityFeatures} = useEntityFeatures(sumMapStats ?? null);

  const flags = useSelector(getEnabledFlags);
  const userConfig = useSelector(getUserConfig);
  let {layers, sources} = useMemo(() => {
    const res = getMapboxLayersSources(
      userConfig,
      layer,
      canonical_date,
      lastZoom,
      layerGeojson,
      entityFeatures,
      layerParams,
      t,
    );
    if (!showEntityFeatures) {
      res.layers = res.layers.filter(x => !entityFeatureLayers.has(x.layer.id));
    }
    return res;
  }, [layer, canonical_date, lastZoom, layerGeojson, entityFeatures, layerParams, crops, units, t, showEntityFeatures]);
  const styleURL = useMemo(
    () => getStyleUrl(flags, layer, canonical_date, window.location.origin),
    [flags, layer, canonical_date],
  );
  useMouseHandlers(map, layer, canonical_date, visibleFieldIds, layerParams, focus, onFeaturesClicked);

  const loading = loadingLayer || loadingEntityFeatures || loadingMapStats;
  return (
    <>
      <Mapbox
        onMapboxMapInstance={innerMapCb}
        centerCoords={initialViewport.centerCoords}
        zoomLevel={initialViewport.zoomLevel}
        bounds={initialViewport.bounds}
        styleUrl={styleURL}
        sources={sources}
        layers={layers}
        fieldFeaturesCb={fieldFeaturesCb}
        marker={marker}
      />
      {loading ? <SpinningDots className="map-loading-spin" size={32} /> : null}
    </>
  );

  function changeInitialViewport() {
    if (!initialViewport.recalcAfterFetch || !marker) {
      return;
    }

    setInitialViewport(calcInitialViewport());
  }

  function calcInitialViewport(): InitialViewport {
    const centerCoords = marker?.location || lastLocation || [8.2275, 46.8182];
    const focusType = marker?.type;

    // If there's no reasonable initial location, start from a more zoomed out viewport,
    // to aid the user in finding his location.
    const zoomLevel = focusType ? DEFAULT_ZOOM_LEVELS_BY_TYPE[focusType] : lastLocation ? lastZoom : 5;

    const bounds =
      !marker?.location && !lastLocation
        ? // Use filtered entity bounds only on a clean state.
          userGroupViewport
        : // Don't provide bounds if a focus has been set,  or we have a known last location.
          undefined;

    return {
      centerCoords,
      zoomLevel,
      // Note: Mapbox-gl will ignore centerCoords+zoomLevel if bounds are provided
      bounds: bounds ?? undefined,
      recalcAfterFetch: !!focus && !marker,
    };
  }
};

const getUserGroupViewport = createSelector([getCountryCodeGroups], countryGroups => {
  const countrySet = new Set(countryGroups);
  let bbox: null | Bbox = null;
  // Find bounding boxes for countrySet using crop periods, and only process each country once.
  for (const period of cropPeriods) {
    if (!countrySet.delete(period.country_code)) {
      continue;
    }

    const periodBbox = fromArrBbox(period.bbox as [LngLat, LngLat]);
    bbox = bbox ? getShapeBbox([bbox.ne, bbox.sw, periodBbox.ne, periodBbox.sw]) : periodBbox;
  }

  return bbox;
});

// This variable is a signal that the map's style has loaded or changed, and we may have to re-add our own layers to it.
// It returns a string with all layer ids which can be used to detect changes. It can also be used to avoid
// adding layers before the map is ready (in which case it's falsy).
export function useMapStyledataSignal(map: RefObject<undefined | mapboxgl.Map>): string {
  const [styledataSignal, setStyledataSignal] = useState('');
  useEffect(() => {
    if (!map.current) {
      reportErr(new Error('map object was not available on mount!'));
      return;
    }
    const onStyledata = () => {
      const signal =
        map.current
          ?.getStyle()
          .layers.map(x => x.id)
          .sort()
          .join(',') ?? '';
      setStyledataSignal(signal);
    };
    map.current.on('styledata', onStyledata);

    return () => {
      map.current?.off('styledata', onStyledata);
    };
  }, []);

  return styledataSignal;
}

function loadImage(map: mapboxgl.Map, name: string, image: string) {
  map.loadImage(image, (error, image) => {
    if (error || !image) {
      reportErr(error ?? new Error('No image loaded'), 'loadImage-' + name);
    } else if (!map.hasImage(name)) {
      map.addImage(name, image, {pixelRatio: 3});
    }
  });
}

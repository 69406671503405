import {MultiPolygon, Polygon} from 'geojson';
import {LngLat, bboxContainsPoint, getBoundingBox, getOverlapRatio, isPointInPolygon} from '../geo';
import {Field, Harvest} from '../models/interfaces';
import {transportToMultiPolygon} from '../models/serialization';
import {AggregatedHarvest} from '../selectors/aggregators';
import {remove} from '../util/arr-util';
import {InseeCodeToHarvestIds} from './custom-decomposition';
import {FarmReportValues} from './report-types';

function getRelativeOverlapWithInseeRegion(
  insee_shape: MultiPolygon,
  field_shape: Polygon | null,
  field_location: LngLat | null,
): number {
  if (field_shape) {
    return getOverlapRatio(insee_shape, field_shape) ?? 0;
  }
  if (field_location) {
    const shapeBB = getBoundingBox(insee_shape);
    const contained = shapeBB ? bboxContainsPoint(shapeBB, field_location) : false;
    // TODO(seb) Somehow differentiate between fields near the center and fields farther away?
    return contained ? 1 : 0;
  }
  return 0;
}

function getRatioOfContainedLocations(shape: MultiPolygon, locations: LngLat[]): number {
  if (!locations.length) {
    return 0;
  }
  return (
    locations.reduce((acc, location) => {
      return acc + (isPointInPolygon(location, shape) ? 1 : 0);
    }, 0) / locations.length
  );
}

export function getInseeCodeMapping(
  inseeShapes: Record<string, string>,
  report: FarmReportValues,
): InseeCodeToHarvestIds {
  // Convert from twkb to geojson MultiPolygons
  const inseeMultiPolygons = Object.fromEntries(
    Object.entries(inseeShapes).map(([k, v]) => [k, transportToMultiPolygon(v)]),
  );

  // Get visit related samples, harvests and their fields
  const fields: Field[] = [];
  const fieldLevelHarvests: Harvest[] = [];
  const farmLevelHarvests: AggregatedHarvest[] = [];
  for (const summary of report.summaryByHarvest) {
    for (const harvest of summary.harvests) {
      if (harvest.field) {
        fields.push(harvest.field);
        fieldLevelHarvests.push(harvest.harvest);
      } else {
        farmLevelHarvests.push(harvest);
      }
    }
  }

  // Map insee codes to field-level harvests. Note that inseeShapes is already limited to the shapes appearing
  // in the Groupama mission, which simplifies things.
  const fieldLevelHarvestCodes: {
    harvest_id: string[];
    inseeCode: string | null;
  }[] = fields.map(f => {
    const fieldHarvestIds = fieldLevelHarvests.filter(h => h.field_id == f.field_id).map(h => h.harvest_id);
    // Prefer the insee code that matches the region_id, as this is computationally cheaper.
    if (f.region_id) {
      const field_region_id = f.region_id!.slice(0, -1);
      const inseeCode = Object.keys(inseeShapes).find(inseeCode => field_region_id.endsWith(inseeCode));
      if (inseeCode) {
        return {
          harvest_id: fieldHarvestIds,
          inseeCode: inseeCode,
        };
      }
    }
    // For a visit executed without network access, the region_id will not exist on any newly created field. As
    // region_ids are attached by the server only, due to the size of the region shape database. The fallback option
    // is to compute the insee region that fields belongs to, based on the offline available insee shapes attached
    // to the visit.
    const inseeCode: [string | null, number] = Object.entries(inseeMultiPolygons)
      .map<[string, number]>(([inseeCode, shape]) => {
        return [inseeCode, getRelativeOverlapWithInseeRegion(shape, f.field_shape, f.field_location)];
      })
      .reduce<[string | null, number]>(
        ([maxCode, maxOverlap], [curCode, curOverlap]) => {
          if (curOverlap > maxOverlap) {
            return [curCode, curOverlap];
          }
          return [maxCode, maxOverlap];
        },
        [null, 0],
      );
    return {
      harvest_id: fieldHarvestIds,
      inseeCode: inseeCode[0],
    };
  });

  // Map insee codes to farm-level harvests.
  const farmLevelHarvestCodes: {
    harvest_id: string[];
    inseeCode: string | null;
  }[] = farmLevelHarvests.map(h => {
    const {harvest, samples} = h;
    const locations = samples.map(s => s.sample_location).filter(remove.nulls);
    const inseeCode: [string | null, number] = Object.entries(inseeMultiPolygons)
      // For each insee code/shape, get the ratio of sample locations contained within the regions shape.
      .map<[string, number]>(([inseeCode, shape]) => {
        const number = getRatioOfContainedLocations(shape, locations);
        return [inseeCode, number];
      })
      // Keep only the insee code with the highest ratio of contained locations.
      .reduce<[string | null, number]>(
        ([maxCode, maxIncluded], [curCode, curIncluded]) => {
          if (curIncluded > maxIncluded) {
            return [curCode, curIncluded];
          }
          return [maxCode, maxIncluded];
        },
        [null, 0],
      );
    return {
      harvest_id: [harvest.harvest_id],
      inseeCode: inseeCode[0],
    };
  });

  return [...fieldLevelHarvestCodes, ...farmLevelHarvestCodes].reduce<Record<string, string[]>>((acc, comb) => {
    if (comb.inseeCode) {
      if (!acc[comb.inseeCode]) {
        acc[comb.inseeCode] = [];
      }
      acc[comb.inseeCode].push(...comb.harvest_id);
    }
    return acc;
  }, {});
}

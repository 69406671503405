// Split text longer than 30 characters into multiple lines, returns an array of strings with a maximum length of
// 30 characters each.
export function splitText(textElement: string): string[] {
  if (textElement.length <= 30) {
    return [textElement];
  }
  const parts = [];
  if (textElement.includes(',')) {
    // try to break lines by comma (e.g. Wheat (Soft), Winter becomes Wheat (Soft)\nWinter)
    parts.push(...textElement.split(',').map(part => part.trim()));
  } else if (textElement.includes(' ')) {
    const lastSpaceIndex = textElement.lastIndexOf(' ');
    parts.push(...[textElement.substring(0, lastSpaceIndex).trim(), textElement.substring(lastSpaceIndex + 1).trim()]);
  } else {
    parts.push(...[textElement.slice(0, textElement.length / 2), textElement.slice(textElement.length / 2)]);
  }
  return parts.flatMap(part => splitText(part));
}

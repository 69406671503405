import {CommonViewStyle} from '../components/CommonViewStyle';
import {fastDeepEqual} from '../fast-deep-equal';
import {I18nSimpleKey} from '../i18n/i18n';
import {ValueUnit} from '../models/types';
import {FormyComponent, FormyComponentProps} from './FormyComponent';
import {FormyI} from './index';

export interface FormyTextBaseProps<F, Fk> {
  label: I18nSimpleKey;
  field: Fk;
  formy: FormyI<F>;
  inputProps?: any;
  promptMsg?: I18nSimpleKey;
  required?: boolean;
  onBlur?: () => void;
}

export function parseNum(s: string): null | {s: string; num: null | number} {
  s = s.trim();
  if (s === '' || s == '.' || s == ',' || s == '-') {
    // Remove any spaces, but allow a singular decimal separator OR a leading minus.
    return {s: s, num: null};
  }

  const num = Number(s.replace(',', '.'));
  if (isNaN(num)) {
    // Go back to previous state.
    return null;
  }

  return {s, num};
}

interface FormyTextNumState<F, Fk extends keyof F> {
  // Track's the textbox's value.
  valueStr: undefined | string;
  // We keep track of the FormyI value here; if it changes, the parent's value takes precedence, and we update
  // valueStr.
  curFormyValue: null | number;
}

export class FormyTextNumBase<
  F extends {[P in Fk]: null | number},
  Fk extends keyof F,
  P extends FormyComponentProps<F, Fk>,
> extends FormyComponent<F, Fk, P, FormyTextNumState<F, Fk>> {
  constructor(props: P) {
    super(props);

    this.state = this.getNewState();
  }

  getNewState(): FormyTextNumState<F, Fk> {
    const formyValue = this.value;
    return {
      curFormyValue: formyValue,
      valueStr: formyValue == null ? undefined : String(formyValue),
    };
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<FormyTextNumState<F, Fk>>): void {
    const formyValue = this.value;
    if (formyValue !== prevState.curFormyValue) {
      this.setState(this.getNewState());
    }
  }

  onChangeText = (s: string) => {
    const parsed = parseNum(s);
    if (parsed == null) {
      return;
    }

    this.setState({valueStr: parsed.s, curFormyValue: parsed.num}, () => this.handleChange(parsed.num as F[Fk]));
  };
}

export interface FormyUnitProps<U extends ValueUnit, F extends {[P in Fk]: null | U}, Fk extends keyof F>
  extends FormyComponentProps<F, Fk> {
  className?: string;
  units: Readonly<U['unit'][]>;
  label?: I18nSimpleKey;
  prependCmp?: any;
  style?: CommonViewStyle;
  inline?: boolean;
  required?: boolean;
  disabled?: boolean;
}

interface FormyUnitState<U extends ValueUnit, F extends {[P in Fk]: null | U}, Fk extends keyof F> {
  // Track's the textbox's value.
  valueStr: undefined | string;
  // We keep track of the FormyI value here; if it changes, the parent's value takes precedence, and we update
  // valueStr.
  curFormyValue: null | U;

  selectedUnit: null | U['unit'];
}

export class FormyUnitBase<
  U extends ValueUnit,
  F extends {[P in Fk]: null | U},
  Fk extends keyof F,
> extends FormyComponent<F, Fk, FormyUnitProps<U, F, Fk>, FormyUnitState<U, F, Fk>> {
  state: FormyUnitState<U, F, Fk> = this.getNewState();

  getShownUnit() {
    return this.value?.unit ?? (this.state && this.state.selectedUnit) ?? this.props.units[0];
  }

  getNewState(): FormyUnitState<U, F, Fk> {
    return {
      curFormyValue: this.value,
      valueStr: this.value?.val == null ? '' : String(this.value.val),
      selectedUnit: this.value?.unit ?? null,
    };
  }

  componentDidUpdate(prevProps: Readonly<FormyUnitProps<U, F, Fk>>, prevState: Readonly<FormyUnitState<U, F, Fk>>) {
    const formyValue = this.value;
    if (!fastDeepEqual(formyValue, prevState.curFormyValue as F[Fk])) {
      this.setState(this.getNewState());
    }

    if (this.props.units != prevProps.units) {
      if (
        (this.state.selectedUnit && !this.props.units.includes(this.state.selectedUnit)) ||
        (this.value && !this.props.units.includes(this.value.unit))
      ) {
        this.onChangeUnit(this.props.units[0]);
      }
    }
  }

  onChangeText = (s: string) => {
    const parsed = parseNum(s);
    if (parsed == null) {
      return;
    }

    const parsedValue = parsed.num == null ? null : ({val: parsed.num, unit: this.getShownUnit()} as U);
    this.setState({valueStr: parsed.s, curFormyValue: parsedValue as F[Fk]}, () =>
      this.handleChange(parsedValue as F[Fk]),
    );
  };

  // Called when the user selects a new unit.
  onChangeUnit = (unit: U['unit']) => {
    if (this.value) {
      this.handleChange({val: this.value.val, unit} as F[Fk]);
    }
    this.setState({selectedUnit: unit ?? this.props.units[0]});
  };
}

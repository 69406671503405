import {UserDeleteOutlined} from '@ant-design/icons';
import {Button, List, Popconfirm, message} from 'antd';
import React, {FC, useCallback} from 'react';
import {revokeUserAccess} from '../../../src/models/interfaces';
import {useApis} from '../apis/ApisContext';
import {AdminUserEntity} from '../util/admin-util';
import {useKeycloak} from '../util/useKeycloak';

export const RemoveKeycloakUser: FC<{
  userEntities: AdminUserEntity[];
  onCancel?: () => void;
  onDeleted?: (deletedEmails: string[]) => void;
}> = ({userEntities, onCancel, onDeleted}) => {
  const {t, authedFetcher} = useApis();
  const {remove} = useKeycloak();
  const onConfirm = useCallback(async () => {
    const confirmedDeletedEmails: string[] = [];
    const actionPromises = userEntities.map(async u => {
      // Drop all memberships first
      for (const m of u.memberships) {
        await revokeUserAccess(authedFetcher, {
          param_email: u.email,
          param_user_group: m.user_group.user_group,
          param_membership_type: m.membership_type,
        });
      }
      // Then delete the user entity on keycloak
      const result = await remove(u.user_id);
      if (result == true) {
        confirmedDeletedEmails.push(u.email);
        message.success(t({type: 'DeletedUserX', user_name: u.email}), 5);
      } else {
        message.error(t({type: 'ErrorDeletingUserXY', user_name: u.email, error_message: result.message}), 10);
      }
    });
    await Promise.all(actionPromises);
    onDeleted?.(confirmedDeletedEmails);
  }, [authedFetcher, onDeleted, remove, t, userEntities]);
  return (
    <Popconfirm
      title={
        <List
          size="small"
          header={`Delete ${userEntities.length} user(s):`}
          dataSource={userEntities}
          renderItem={item => <List.Item>{item.email}</List.Item>}
          rowKey={item => item.user_id}
        />
      }
      icon={null}
      onConfirm={onConfirm}
      onCancel={onCancel}>
      <Button type="primary" icon={<UserDeleteOutlined />} danger disabled={userEntities.length == 0}>
        {t(userEntities.length == 1 ? 'DeleteUser' : 'DeleteUsers')}
      </Button>
    </Popconfirm>
  );
};

import {ResetAction} from '../../../src/redux/actions/reset';
import {DbMetaState} from '../../../src/redux/reducers/db';
import {ThunkAction} from '../../../src/redux/types';
import {Apis} from '../apis/Apis';
import {reportErr} from '../util/err';

export function logout(apis: Apis): ThunkAction<Promise<void>, {dbMeta: DbMetaState}, ResetAction> {
  return async dispatch => {
    try {
      dispatch({type: 'RESET'});
      await apis.logout();
    } catch (e) {
      reportErr(e, 'logout');
    }
  };
}

import {DBSchema, IDBPDatabase, openDB} from 'idb';
import {jwtDecode} from 'jwt-decode';
import Keycloak from 'keycloak-js';
import {AuthI} from '../../../src/CommonApis';
import {reportErr} from '../util/err';
import {clearAppState} from './clear';
import {isLocalhost} from './env';

export function authFromAltToken(token: string): AuthI {
  let claims = jwtDecode<any>(token);
  if (!claims.email) {
    const err = new Error(`Couldn't handleAltToken ${token}`);
    reportErr(err, 'AuthManager');
    throw err;
  }

  return {
    getUser: () => ({email: claims.email}),
    getAuthToken: () => Promise.resolve(token),
    logout: async () => {
      claims = null;
    },
  };
}

export async function authFromKeycloak(): Promise<AuthI> {
  const keycloak = new Keycloak({
    url: isLocalhost ? 'https://staging.green-triangle.com/auth' : '/auth',
    realm: 'gt',
    clientId: 'app',
  });

  const storedSecrets = await getStoredSecrets();
  const authenticated = await keycloak.init({
    onLoad: 'check-sso',
    checkLoginIframe: !isLocalhost, // On localhost, we login using staging, and the iframe check doesn't work.
    ...storedSecrets,
  });
  if (!authenticated) {
    await keycloak.login({scope: 'email offline_access'});
    throw new Error(`Keycloak failed to login!`);
  }

  try {
    await keycloak.updateToken(5);
  } catch (e) {
    if (e == true) {
      await clearAppState();
      await keycloak.login({scope: 'email offline_access'});
      throw new Error(`Keycloak failed to login!`);
    }
  }

  if (keycloak.token && keycloak.idToken && keycloak.refreshToken) {
    await setStoredSecrets({token: keycloak.token, idToken: keycloak.idToken, refreshToken: keycloak.refreshToken});
  }

  const email: string = (keycloak.tokenParsed as any)?.email ?? 'invalid-user-email';
  if (email == 'invalid-user-email') {
    reportErr(new Error(`no email for ${keycloak.token}`), 'keycloak-email');
  }

  const name: string = (keycloak.tokenParsed as any)?.name;
  const locale: undefined | string = (keycloak.tokenParsed as any)?.locale;
  return {
    getAuthToken: async (): Promise<string> => {
      await keycloak.updateToken(5);
      if (!keycloak.idToken) {
        throw new Error('token was empty; authenticated=' + keycloak.authenticated);
      }
      return keycloak.idToken;
    },
    getUser: () => ({email, name, locale}),
    logout: async () => {
      await clearAppState();
      return keycloak.logout({redirectUri: 'https://www.green-triangle.com'});
    },
  };
}

// We store the refreshToken here, because the cookie based authentication does not work after restarting the
// keycloak server.

interface Secrets {
  token: string;
  idToken: string;
  refreshToken: string;
}

interface StoredSecrets extends DBSchema {
  kv: {
    key: 'secrets';
    value: Secrets;
  };
}

async function getStoredSecretsDb(): Promise<IDBPDatabase<StoredSecrets>> {
  return await openDB<StoredSecrets>('kc-secrets', 1, {
    upgrade(db) {
      db.createObjectStore('kv');
    },
  });
}

async function getStoredSecrets(): Promise<undefined | Secrets> {
  try {
    const db: IDBPDatabase<StoredSecrets> = await getStoredSecretsDb();
    try {
      return await db.get('kv', 'secrets');
    } finally {
      await db.close();
    }
  } catch (e) {
    reportErr(e, 'stored-secrets-get');
    return undefined;
  }
}

async function setStoredSecrets(secrets: Secrets): Promise<void> {
  try {
    const db: IDBPDatabase<StoredSecrets> = await getStoredSecretsDb();
    try {
      await db.put('kv', secrets, 'secrets');
    } finally {
      await db.close();
    }
  } catch (e) {
    reportErr(e, 'stored-secrets-put');
    return undefined;
  }
}

import React, {CSSProperties} from 'react';
import {FormyComponent, FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {WithChildren} from '../../../src/util/react-util';

interface LabelProps {
  style?: CSSProperties;
}

export class Label extends React.PureComponent<WithChildren & LabelProps> {
  render() {
    return (
      <span className="formyLabel" style={this.props.style}>
        {this.props.children}
      </span>
    );
  }
}

interface FormyLabelProps<F, Fk extends keyof F> extends FormyComponentProps<F, Fk> {
  className?: string;
  transform?: (x: F[Fk]) => string;
}

export class FormyLabel<F, Fk extends keyof F> extends FormyComponent<F, Fk, FormyLabelProps<F, Fk>> {
  render() {
    return <span className={this.props.className}>{String(this.value)}</span>;
  }
}

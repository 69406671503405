export const grHiddenName = ['gr', 'ou', 'pa', 'ma'].join('') as 'groupama';

export const grTraining = ['-training', '-demo', '-gpvl-formation', '-goc-formation'].map(x => grHiddenName + x);

// Note that gan is missing from this list, even though it grants to groupama in data.user_group_acl.
export const grAllGroupsExGan = ['', '-ge', '-gcm', '-gpvl', '-goc', '-gca', '-gne', '-graa', '-glbr', '-gmed']
  .map(x => grHiddenName + x)
  .concat(grTraining);

export const grAllGroups = [...grAllGroupsExGan, 'gan'];

export const paHiddenName = ['pa', 'ci', 'fi', 'ca'].join('') as 'pacifica';
export const txHiddenName = ['te', 'rr', 'ex', 'pe', 'rt'].join('') as 'terrexpert';
export const txTrainingAndDemo = ['-formation', '-demo'].map(x => txHiddenName + x);
export const paAllGroups = [
  ...['', '-au', '-co', '-dp', '-e', '-extra', '-no', '-se', '-so'].map(x => paHiddenName + x),
  ...txTrainingAndDemo,
];
export const txAllGroups = [
  ...['', '-aas', '-au', '-co', '-e', '-ma', '-no', '-se', '-so'].map(x => txHiddenName + x),
  ...txTrainingAndDemo,
];
export const etHiddenName = ['et', 'oi', 'le'].join('');
export const aaHiddenName: string = ['a', 'a', 's'].join('');
export const gaHiddenName: string = ['g', 'a', 'n'].join('');
export const assHiddenName: string = ['as', 'si', 'te', 'ca'].join('');
export const assAllGroups: string[] = ['', '-training'].map(x => assHiddenName + x);
export const mdaHiddenName: string = ['ma', 'md', 'a'].join('');
export const mdaAllGroups = [
  mdaHiddenName,
  'Beni-Mellal-Khenifra',
  'Casablanca-Settat',
  'Draa-Tafilalet',
  'Fes-Meknes',
  'Guelmim-Oued-Noun',
  'Marrakech-Safi',
  'Oriental',
  'Rabat-Sale-Kenitra',
  'Souss-Massa',
  'Tanger-Tetouan-Alhoceima',
];
// Production used latam-admin while staging uses admin-latam.
export const internalAdminGroup = ['admin', 'admin-latam', 'latam-admin', 'pkm-admin'];
export const mgmtAllGroups = [...internalAdminGroup, grHiddenName, paHiddenName, etHiddenName, txHiddenName, 'tester'];
export const europeGroups = [
  'RUS',
  'ESP',
  'AUT',
  'GEO',
  'CZE',
  'BGR',
  'GRC',
  'SVK',
  'ROU',
  'UKR',
  'CHE',
  'FRA',
  'ITA',
  'HUN',
  'BEL',
  'POL',
  'DEU',
  'MDA',
  'LTU',
  'LVA',
  'EST',
  'SRB',
  'GBR',
  'FIN',
  'SVN',
  'PRT',
];
export const latamGroups = ['ARG', 'BLZ', 'BOL', 'BRA', 'CHL', 'COL', 'ECU', 'MEX', 'PER', 'PRY', 'URY'];
export const claimsModeWhitelist = [...internalAdminGroup, ...latamGroups, 'tester', 'pzu'];

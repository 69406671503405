import {Image} from 'antd';
import React from 'react';

type SpinningDotsProps = {
  size: number;
  className?: string;
};

const SpinningDots = ({size, className}: SpinningDotsProps) => {
  return (
    <div className={className}>
      <Image
        preview={false}
        src="/dots.svg"
        width={size}
        height={size}
        style={{animation: 'App-logo-spin infinite 1s linear'}}
      />
    </div>
  );
};

export default SpinningDots;

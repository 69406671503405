import {QueryClient} from '@tanstack/react-query';

export const appQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // See: https://tanstack.com/query/v4/docs/react/guides/important-defaults
      // only re-fetch if last fetch is older than 2 minutes
      staleTime: 2 * 60 * 1000,
      // re-use data only if not older than 5 minutes (will still fetch if stale, but use cached data in the meantime)
      cacheTime: 5 * 60 * 1000,
    },
  },
});

import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons';
import {Select} from 'antd';
import React from 'react';
import {formatLayerCanonicalDate} from '../../../src/layers/canonical-date';
import {MapLayers} from '../../../src/layers/map-layers';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';

interface DateSelectorProps {
  layer: MapLayers;
  curDate: string;
  layerDates: string[];
  onChange: (x: string) => void;
  yearOnly: boolean;
}

export class DateSelector extends React.PureComponent<DateSelectorProps> {
  static contextType = ApisContext;
  context!: Apis;

  moveDate = (offset: number) => {
    let idx = this.props.layerDates.indexOf(this.props.curDate);
    if (idx == -1) {
      return;
    }

    const curDate = this.props.layerDates[idx + offset];
    if (curDate) {
      this.props.onChange(curDate);
    }
  };

  prevDate = () => this.moveDate(-1);
  nextDate = () => this.moveDate(1);

  render() {
    if (this.props.layerDates.length == 0) {
      return null;
    }

    const idx = this.props.layerDates.indexOf(this.props.curDate);
    return (
      <span id="timeslider">
        <LeftCircleOutlined
          id="date-selector-prev"
          size={40}
          className={'half-padding' + (idx == 0 ? ' disabled-color' : '')}
          onClick={idx == 0 ? undefined : this.prevDate}
        />
        <Select
          id="date-selector"
          onChange={this.props.onChange}
          value={this.props.curDate}
          className="timeslider-Selector">
          {this.props.layerDates.map(d => (
            <Select.Option key={d} value={d}>
              {this.props.yearOnly ? d.substr(0, 4) : formatLayerCanonicalDate(this.context.t, this.props.layer, d)}
            </Select.Option>
          ))}
        </Select>
        <RightCircleOutlined
          id="date-selector-next"
          size={40}
          className={'half-padding' + (idx == this.props.layerDates.length - 1 ? ' disabled-color' : '')}
          onClick={idx == this.props.layerDates.length - 1 ? undefined : this.nextDate}
        />
      </span>
    );
  }
}

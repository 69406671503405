import React from 'react';
import {CommonViewStyle} from '../components/CommonViewStyle';
import {I18nSimpleKey} from '../i18n/i18n';
import {FormyI, FormyState, SubmitEventHandler} from './index';

export interface FormySubmitProps<F> {
  label: I18nSimpleKey;
  formy: FormyI<F>;
  onSubmitOverride?: SubmitEventHandler;
  showSpinner?: boolean;
  doNotConfirmSubmissionToUser?: boolean;
  style?: CommonViewStyle;
}

export class FormySubmitBase<F, ExtendedProps = {}> extends React.Component<
  FormySubmitProps<F> & ExtendedProps,
  {isSubmitting: boolean}
> {
  protected _mode: Readonly<Pick<FormyState, 'mode'>>;

  constructor(props: FormySubmitProps<F> & ExtendedProps) {
    super(props);
    this.state = {isSubmitting: false};
    this._mode = props.formy.watchState('mode', this);
  }

  componentWillUnmount() {
    this.props.formy.unwatch(this);
  }

  handleSubmit = async (): Promise<boolean> => {
    this.setState({isSubmitting: true});
    const handler = this.props.onSubmitOverride ? this.props.onSubmitOverride : this.props.formy.getSubmitHandler();
    try {
      return await handler();
    } finally {
      this.setState({isSubmitting: false});
    }
  };
}

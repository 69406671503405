import {defaultMemoize} from 'reselect';
import {fastDeepEqual} from '../fast-deep-equal';
import {DbFilterState, dbFiltersInitialState} from '../redux/reducers/filters';

export const getFilters = (state: {filters: DbFilterState}) => {
  return state.filters;
};
export const areFiltersEmpty = defaultMemoize((filters: DbFilterState) =>
  fastDeepEqual(filters, dbFiltersInitialState),
);

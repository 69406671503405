import {reportErr} from '../util/err';

export async function clearAppState() {
  try {
    // indexedDB is currently only used by auth.ts, to store the keycloak refresh token.
    (await indexedDB.databases()).forEach(x => x.name != null && indexedDB.deleteDatabase(x.name));
  } catch (e) {
    reportErr(e, 'clear-indexedDb');
  }
}

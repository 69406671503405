import {QueryObserverResult, useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {MembershipType, UserEntity, UserGroup, UserGroupMembership} from '../../../src/models/interfaces';
import {
  getTransitiveUserGroupGrantees,
  getTransitiveUserGroupGrantors,
  getUserGroups,
} from '../../../src/selectors/userGroups';
import {getPostgrestQueryParams} from '../../../src/util/postgrest-query';
import {useApis} from '../apis/ApisContext';

// Modified version of UserGroupMembership, using UserGroup instead of string, because we need the name for the UI.
export interface MembershipUpdate {
  user_group: UserGroup;
  email: string;
  membership_type: MembershipType;
}

// Simple struct to hold grants and revokes for a single user.
export interface MembershipUpdates {
  grant: MembershipUpdate[];
  revoke: MembershipUpdate[];
}

// Simple struct used by the AdminUserEntityTable.
export interface AdminUserEntity {
  user_id: string;
  key: string;
  selectable: boolean;
  email: string;
  first_name?: string;
  last_name?: string;
  memberships: MembershipUpdate[];
  customers: UserGroup[];
  accessible_user_groups: UserGroup[];
}

// Get name for a user group. If the user group has a name, use that. Otherwise, use the user_group field.
// Optionally return the name and the raw user_group field.
export function toName(userGroup: UserGroup, includeRaw: boolean = false) {
  if (includeRaw) {
    return userGroup.name ? `${userGroup.name} (${userGroup.user_group})` : userGroup.user_group;
  }
  return userGroup.name ?? userGroup.user_group;
}

// Return intersection of current users user-admin (transitive) user groups with the selected UserEntities user groups.
export function useAdminUserGroupFilter(selected: AdminUserEntity[]) {
  const {isAdmin, transitiveUserAdminGroups} = useAdminInfo();
  if (!isAdmin) {
    return [];
  }
  return transitiveUserAdminGroups
    .filter(g => !/^[A-Z]{3}$/.test(g.user_group)) // Remove country groups
    .filter(g => selected.every(u => u.accessible_user_groups.includes(g)));
}

export function useTransitiveUserGroups() {
  const userGroups = useSelector(getUserGroups);
  const grantees = useSelector(getTransitiveUserGroupGrantees);
  const grantors = useSelector(getTransitiveUserGroupGrantors);
  return useMemo(() => {
    return {
      grantees: resolveUserGroups(grantees),
      grantors: resolveUserGroups(grantors),
    };

    function resolveUserGroups(mapped: Record<string, Set<string> | undefined>) {
      return Object.entries(mapped)
        .map(([key, value]) => {
          return [key, Array.from(value!).map(g => userGroups.find(u => u.user_group == g)!)] as [string, UserGroup[]];
        })
        .reduce<Record<string, UserGroup[]>>((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    }
  }, [grantees, grantors, userGroups]);
}

export interface AdminInfo {
  loading: boolean;
  userAdminGroups: UserGroup[];
  transitiveUserAdminGroups: UserGroup[];
  isAdmin: boolean;
}

export function useAdminInfo(): AdminInfo {
  const {authedFetcher, getUser} = useApis();
  const {grantors} = useTransitiveUserGroups();
  const userGroups = useSelector(getUserGroups);
  // Relying on useQuery to ensure we only fetch this at most once across all components.
  const {isSuccess, data} = useQuery(['userGroupMembership', getUser().email], async () => {
    const rows = await authedFetcher({
      method: 'GET',
      path: 'api/user_group_membership',
      params: [
        ...getPostgrestQueryParams([
          {
            column: 'email',
            operator: 'eq',
            value: getUser().email,
          },
          {
            column: 'membership_type',
            operator: 'eq',
            value: 'user-admin',
          },
        ]),
        ['select', 'user_group'],
      ],
    });
    const userAdminGroups: string[] = await rows.map((r: {user_group: string}) => r.user_group);
    const transitiveUserAdminGroups: UserGroup[] = userAdminGroups.flatMap(g => Array.from(grantors[g]!));
    return {
      userAdminGroups: userAdminGroups.map(g => userGroups.find(x => x.user_group == g)!),
      transitiveUserAdminGroups,
      isAdmin: userAdminGroups.length > 0,
    };
  });
  return useMemo(() => {
    return {
      loading: !isSuccess,
      userAdminGroups: [],
      transitiveUserAdminGroups: [],
      isAdmin: false,
      ...data,
    };
  }, [isSuccess, data]);
}

const FETCH_PAGE_SIZE = 500;

export interface UseFetchAll<T> {
  loading: boolean;
  entities: T[];
  refetch: () => Promise<QueryObserverResult<T[], unknown>>;
}

// A simple wrapper around useQuery to fetch all entries for a given entity.
export function useFetchAll(entity: 'user_entity', paginationKey: (keyof UserEntity)[]): UseFetchAll<UserEntity>;
export function useFetchAll(
  entity: 'user_group_membership',
  paginationKey: (keyof UserGroupMembership)[],
): UseFetchAll<UserGroupMembership>;
export function useFetchAll<T = UserEntity | UserGroupMembership>(
  entity: 'user_entity' | 'user_group_membership',
  paginationKey: (keyof T)[],
): UseFetchAll<T> {
  const {authedFetcher} = useApis();
  const fetch = async (key: string[] | undefined): Promise<T[]> => {
    const params: [string, string][] = [
      [
        // Ensure we sort by the pagination key(s)
        'order',
        paginationKey
          .map(String)
          .map(k => `${k}.asc`)
          .join(','),
      ],
      ['limit', String(FETCH_PAGE_SIZE)],
    ];
    if (key) {
      // If we have a key, add a filter to only return entities with a key greater than the last key.
      params.push(
        ...getPostgrestQueryParams({
          columns: paginationKey.map(String),
          operator: 'gt',
          values: key.map(String),
        }),
      );
    }
    return authedFetcher({
      method: 'GET',
      path: `api/${entity}`,
      params,
    });
  };
  const {isSuccess, isLoading, refetch, data} = useQuery<T[]>([entity, paginationKey], async () => {
    const data: T[] = [];
    let batch: T[] = [];
    let key: string[] | undefined = undefined;
    do {
      batch = await fetch(key);
      data.push(...batch);
      const last = batch[batch.length - 1];
      key = last && paginationKey.map(k => last[k]).map(String);
    } while (batch.length == FETCH_PAGE_SIZE);
    return data;
  });
  return {
    loading: isLoading,
    entities: isSuccess ? data : [],
    refetch,
  };
}

export const notACountryGroup = (x: UserGroup) => !/^[A-Z]{3}$/.test(x.user_group);

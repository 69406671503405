import {AuthI} from '../../../src/CommonApis';

export function getLocale(auth: null | Pick<AuthI, 'getUser'>) {
  const langOverride = window.location.search
    .slice(1)
    .split('&')
    .map(x => x.split('='))
    .filter(x => x[0] == 'hl');
  if (langOverride.length) {
    return langOverride[0][1];
  }
  return auth?.getUser().locale ?? (window.navigator.language || 'en-US').replace(/_/, '-');
}

import fastDeepEqual from '../fast-deep-equal';
import {isObjEmpty} from './obj-util';

export function dedupeMetadata(a: {metadata: any}, b: {metadata: any}, changes: Partial<{metadata: any}>) {
  let newMetadata: any = {};
  if (b.metadata == null || fastDeepEqual(a.metadata, b.metadata)) {
    newMetadata = a.metadata ?? {};
  } else if (typeof a.metadata == 'object' && typeof b.metadata == 'object') {
    newMetadata = {...a.metadata, ...b.metadata};
  }
  changes.metadata = newMetadata;
}

export function coalesceObjects<Y extends Record<string, any>, X extends Y>(
  changes: Partial<Y>,
  a: X,
  b: X,
  cols: null | (keyof Y)[],
  // If true, it will not refuse to merge entities if there is a conflict; instead, it will take the latest value.
  bestEffort: boolean = false,
): boolean {
  if (cols == null) {
    cols = Object.keys(b) as (keyof Y)[];
  }
  for (const k of cols) {
    const aVal = a[k],
      bVal = b[k];
    if (aVal != null && bVal != null && !fastDeepEqual(aVal, bVal)) {
      if (bestEffort) {
        if (a['added_on'] && b['added_on'] && b['added_on'] > a['added_on']) {
          changes[k] = bVal;
        }
      } else {
        return false;
      }
    }
    if (aVal == null && bVal != null) {
      changes[k] = bVal;
    }
  }
  return true;
}

export function dedupeCustomColumns<
  T extends Record<string, any>,
  E extends {custom_columns: null | T; added_on: string},
>(a: E, b: E, changes: Partial<{custom_columns: null | T}>): void {
  if (b.custom_columns == null || fastDeepEqual(a.custom_columns, b.custom_columns)) {
    return;
  }
  if (a.custom_columns == null) {
    changes.custom_columns = b.custom_columns;
    return;
  }

  const customColChanges = {};
  coalesceObjects(
    customColChanges,
    {added_on: a.added_on, ...a.custom_columns},
    {added_on: b.added_on, ...b.custom_columns},
    null,
    true,
  );

  // TODO(savv): consider adding recursive coalescing for nested objects, such as VisitCustomColumns.etl, if needed.
  //  See here for rest of discussion: https://github.com/greentriangle/agro/pull/4323#discussion_r1801101640

  if ('added_on' in customColChanges && !('added_on' in a.custom_columns || 'added_on' in b.custom_columns)) {
    delete customColChanges['added_on'];
  }

  if (isObjEmpty(customColChanges)) {
    return;
  }
  changes.custom_columns = {...changes.custom_columns, ...a.custom_columns!, ...customColChanges};
}

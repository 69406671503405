import React from 'react';

class SvgImage extends React.Component<{color?: string; name: string; width?: number}> {
  render() {
    const props = this.props;
    const Svg = SVGs[props.name];
    return Svg ? <Svg {...props} /> : null;
  }
}

const SVGs: {[id: string]: React.FC<{width?: number; color?: string}>} = {
  // Source SVG adapted from /public/circle.svg
  circle: ({width = 32, color = 'black'}: {width?: number; color?: string}) => (
    <svg
      style={{verticalAlign: 'middle'}}
      name="circle"
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      fill="none">
      <circle cx={width / 2} cy={width / 2} r={width / 2} fill={color} />
    </svg>
  ),
  triangle: ({width = 32, color = 'none'}: {width?: number; color?: string}) => (
    <svg
      style={{verticalAlign: 'middle'}}
      name="triangle"
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      fill="none">
      <polygon points={`${width / 2},0 0,${width} ${width},${width}`} fill={color} />
    </svg>
  ),
};

export default SvgImage;

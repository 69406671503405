import {Sample} from '../models/interfaces';
import {IndexedSamples} from '../redux/reducers/db';

export function getSamplesByHarvestId(sample: IndexedSamples): {[harvest_id: string]: Sample[]} {
  const res: {[harvest_id: string]: Sample[]} = {};
  for (const sample_id in sample) {
    const harvest_id = sample[sample_id].harvest_id!;
    if (!(harvest_id in res)) {
      res[harvest_id] = [];
    }
    res[harvest_id].push(sample[sample_id]);
  }
  return res;
}

// An element for selecting the user's group.
import React from 'react';
import {useSelector} from 'react-redux';
import {FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {identityOptions} from '../../../src/Formy/FormyEnum';
import {getPreferredUserGroups} from '../../../src/selectors/userGroups';
import {FormyEnum} from './FormyEnum';

export default function FormyUserGroupSelector<F extends {[P in Fk]: null | string}, Fk extends keyof F>(
  props: FormyComponentProps<F, Fk>,
) {
  const userGroups = useSelector(getPreferredUserGroups);
  return (
    <FormyEnum<string, F, Fk>
      data-testid={props.field}
      options={identityOptions(userGroups)}
      selectMsg="SelectPortfolio"
      field={props.field}
      formy={props.formy}
    />
  );
}

import {ClockI} from '../../Clock';
import {DbFields} from '../../models/types';
import {clearNullsInPlace, isObjEmpty} from '../../util/obj-util';
import {DbMetaState} from '../reducers/db';

// x is usually something like FieldData, whereas Y would usually be {farm_id: 'b33f...-..'}.
export function prepInsertedEntity<X extends {custom_columns?: any}, Y extends {}>(
  apis: {clock: ClockI},
  stateOrEmail: {dbMeta: DbMetaState} | string,
  x: X,
  extraProps: Y,
): X & Y & DbFields {
  let e = prepInsertedEntityBase(apis, stateOrEmail, x, extraProps);
  clearNullsInPlace(e.custom_columns);
  if (isObjEmpty(e.custom_columns)) {
    e.custom_columns = null;
  }
  return e;
}

// IMPORTANT: use this function only for entities without custom columns
export function prepInsertedEntityBase<X extends {}, Y extends {}>(
  apis: {clock: ClockI},
  stateOrEmail: {dbMeta: DbMetaState} | string,
  x: X,
  extraProps: Y,
): X & Y & DbFields {
  if (typeof stateOrEmail == 'object') {
    stateOrEmail = stateOrEmail.dbMeta.curUser?.email ?? '';
  }
  return {
    ...x,
    ...extraProps,
    added_on: new Date(apis.clock.now()).toISOString(),
    // updated_at is filled by the database, as it needs to contain a value at the time it's written.
    updated_at: null as unknown as string,
    added_by: stateOrEmail,
  };
}

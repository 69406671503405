import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import {Alert, AlertProps, Button, Modal, Space} from 'antd';
import React, {FC, useCallback, useMemo, useState} from 'react';
import {Formy} from '../../../src/Formy/Formy';
import {useFormy} from '../../../src/Formy/hooks';
import {Locales} from '../../../src/i18n/i18n';
import {KeycloakPublicServerApiUser} from '../../../src/models/keycloak';
import {remove as arrayRemove} from '../../../src/util/arr-util';
import {isEmail} from '../../../src/validator-constraints';
import {FormyEnum, FormyMultiEnum} from '../Formy/FormyEnum';
import {FormyTable} from '../Formy/FormyTable';
import {FormyTextStr} from '../Formy/FormyText';
import {useApis} from '../apis/ApisContext';
import {notACountryGroup, useAdminInfo} from '../util/admin-util';
import {useKeycloak} from '../util/useKeycloak';
import './AddKeycloakUser.css';

export const AddKeycloakUser: FC<{onAdded: (addedEmails: string[]) => void}> = ({onAdded}) => {
  const {t} = useApis();
  const [showCreateUser, setShowCreateUser] = useState<boolean>(false);

  return (
    <>
      <AddKeycloakUserModal show={showCreateUser} setShow={setShowCreateUser} onAdded={onAdded} />
      <Space>
        <Button
          data-testid="kc-add-user"
          type="primary"
          icon={<UserAddOutlined />}
          onClick={() => setShowCreateUser(show => !show)}>
          {t('AddUser')}
        </Button>
      </Space>
    </>
  );
};

function getUserDefaults(): KeycloakPublicServerApiUser {
  return {
    email: '',
    customer: [],
    firstName: null,
    lastName: null,
    locale: null,
  };
}

type UserForm = {users: KeycloakPublicServerApiUser[]};

const AddKeycloakUserModal: FC<{
  show: boolean;
  setShow: (show: boolean) => void;
  onAdded: (addedEmails: string[]) => void;
}> = ({show, setShow, onAdded}) => {
  const {t} = useApis();
  const {add} = useKeycloak();
  const [createdUsers, setCreatedUsers] = useState<
    (KeycloakPublicServerApiUser & {
      status: AlertProps['type'];
      message?: string;
    })[]
  >([]);
  const [state, setState] = useState<'edit' | 'submit' | 'success' | 'error'>('edit');
  const formy = useFormy<UserForm>(
    'new',
    async () => ({
      users: [getUserDefaults()],
    }),
    t,
    async (values, _, formy) => {
      try {
        setState('submit');
        const successfullyCreatedUsersEmail: string[] = [];
        const fetches = values.users.map(async user => {
          setCreatedUsers(users => {
            return [...users.filter(u => u.email !== user.email), {...user, status: 'info'}];
          });
          const result = await add(user);
          if (result == true) {
            setCreatedUsers(users => {
              return [...users.filter(u => u.email !== user.email), {...user, status: 'success'}];
            });
            successfullyCreatedUsersEmail.push(user.email);
          } else {
            setCreatedUsers(users => {
              return [
                ...users.filter(u => u.email !== user.email),
                {
                  ...user,
                  status: 'error',
                  message: result.message,
                },
              ];
            });
          }
        });
        await Promise.all(fetches).finally(() => {
          onAdded(successfullyCreatedUsersEmail);
          formy?.getChangeHandler('users')([getUserDefaults()]);
        });
      } finally {
        setState(createdUsers.every(u => u.status === 'success') ? 'success' : 'error');
      }
    },
    values => {
      return {
        users: values.users.map(user => {
          return {
            email: !isEmail(user.email),
            customer: user.customer.length < 1,
            locale: !user.locale,
          };
        }),
      };
    },
    null,
  );
  const onCancel = useCallback(() => {
    formy?.getChangeHandler('users')([getUserDefaults()]);
    setCreatedUsers([]);
    setState('edit');
    setShow(false);
  }, [formy, setShow]);
  return (
    <Modal
      title={t('AddUser')}
      open={show}
      width="80vw"
      destroyOnClose={true}
      okButtonProps={state == 'submit' ? {disabled: true, icon: <LoadingOutlined />} : {}}
      okText={state == 'success' ? t('Close') : t('AddUser')}
      onOk={state == 'success' ? onCancel : formy?.submitHandler}
      onCancel={onCancel}
      closable={true}>
      {state !== 'success' && <AddKeycloakUserFormy formy={formy} />}
      <div>
        <Space direction="vertical">
          {createdUsers.map(user => {
            const alertMessage = [
              user.email,
              user.customer.join(', '),
              '<password-hidden>',
              user.firstName,
              user.lastName,
              user.message,
            ]
              .filter(arrayRemove.nulls)
              .map(x => '"' + x + '"')
              .join(', ');
            return (
              <Alert
                key={user.email}
                message={
                  <div>
                    <span>{alertMessage}</span>
                  </div>
                }
                type={user.status}
                showIcon
                icon={<CreateUserIcon status={user.status} />}
                closable={user.status != 'info'}
              />
            );
          })}
        </Space>
      </div>
    </Modal>
  );
};

const AddKeycloakUserFormy: React.FC<{formy?: Formy<UserForm>}> = ({formy}) => {
  return formy ? <_AddKeycloakUserFormy formy={formy} /> : <div></div>;
};

const _AddKeycloakUserFormy: React.FC<{formy: Formy<UserForm>}> = ({formy}) => {
  const {loading, transitiveUserAdminGroups} = useAdminInfo();
  const options: [unknown, string][] = useMemo(() => {
    return loading
      ? []
      : transitiveUserAdminGroups.filter(notACountryGroup).map(x => [x.user_group, x.name || x.user_group]);
  }, [loading, transitiveUserAdminGroups]);
  return (
    <div className="formy-keycloak">
      <FormyTable
        formy={formy}
        field="users"
        headers={['Email', 'FirstName', 'LastName', 'Customer', 'Locale']}
        renderRowCells={formy => [
          <FormyTextStr formy={formy} field="email" type="email" />,
          <FormyTextStr formy={formy} field="firstName" />,
          <FormyTextStr formy={formy} field="lastName" />,
          <FormyMultiEnum options={options} selectMsg="Select" formy={formy} field="customer" />,
          <FormyEnum options={Locales.map(l => [l, l])} selectMsg="Select" formy={formy} field="locale" />,
        ]}
        iconAdd={<UserAddOutlined />}
        iconRemove={<UserDeleteOutlined />}
        labelAdd="AddUser"
        defaultValue={(users: KeycloakPublicServerApiUser[]) => {
          if (users.length == 0) {
            return getUserDefaults();
          }
          return {
            ...getUserDefaults(),
            // Copy the last user's customer and locale to the new user.
            customer: users[users.length - 1].customer,
            locale: users[users.length - 1].locale,
          };
        }}
      />
    </div>
  );
};

const CreateUserIcon: FC<{status: AlertProps['type']}> = ({status}) => {
  if (status === 'success') {
    return <UserAddOutlined />;
  } else if (status === 'error') {
    return <ExclamationCircleOutlined />;
  } else {
    return <SyncOutlined spin />;
  }
};

import {Col, Empty, Row} from 'antd';
import React, {useContext} from 'react';
import {ApisContext} from '../apis/ApisContext';
import Header from '../components/Header';
import {NoDataAlert} from '../components/NoDataAlert';
import './Dashboard.css';
import {ExpertComparisonCard} from './ExpertComparisonCard';
import {HectaresByCondition} from './HectaresByCondition';
import {HectaresByCrop} from './HectaresByCrop';
import LiveFeedCard from './LiveFeedCard';
import {SampleTimeseries} from './SampleTimeseries';
import {SamplesByCrop} from './SamplesByCrop';
import {SummaryStats} from './SummaryStats';
import {YieldByCrop} from './YieldByCrop';
import {useSampleRows, useStatsByHarvest} from './queries';

const colSpans = {xs: 24, sm: 24, md: 24, lg: 12, xl: 8, xxl: 6};
const gutter = {xs: 16, sm: 24, md: 20, lg: 20};

const Dashboard: React.FC = () => {
  return (
    <div data-testid="Dashboard">
      <Header showFilters={true} />
      <div className="Dashboard">
        <PossibleNoDataAlert />
        <Row justify="center" gutter={gutter} className="fix-padding">
          <Col {...colSpans} className="card-wrapper">
            <LiveFeedCard />
          </Col>
          <Col {...colSpans} className="card-wrapper">
            <SampleTimeseries />
          </Col>
          <Col {...colSpans} className="card-wrapper">
            <ExpertComparisonCard />
          </Col>
          <Col {...colSpans} className="card-wrapper">
            <SummaryStats />
          </Col>
          <Col {...colSpans} className="card-wrapper">
            <YieldByCrop />
          </Col>
          <Col {...colSpans} className="card-wrapper">
            <SamplesByCrop />
          </Col>
          <Col {...colSpans} className="card-wrapper">
            <HectaresByCrop />
          </Col>
          <Col {...colSpans} className="card-wrapper">
            <HectaresByCondition />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;

// A component to warn the user that his current filter settings return no data. Has a button to allow the user to
// reset the filters.
const PossibleNoDataAlert: React.FC = React.memo(() => {
  const {data: samples = [], isSuccess: gotSamples} = useSampleRows();
  const {data: byHarvest = [], isSuccess: gotByHarvest} = useStatsByHarvest();
  return gotSamples && samples.length === 0 && gotByHarvest && byHarvest.length === 0 ? (
    <Row justify="center" gutter={gutter} align="middle">
      <Col span={24}>
        <div className="low-on-data">
          <NoDataAlert />
        </div>
      </Col>
    </Row>
  ) : null;
});

export const LocalizedEmptyImage: React.FC = React.memo(() => {
  const {t} = useContext(ApisContext);
  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('NoData')} />;
});

import {Socket} from 'socket.io-client';
import {getUuid} from './get-uuid';
import {FieldLayerType, RegionalLayerType} from './layers/map-layers';

export type FollowLayerCb = (max_updated_at: string) => void;

export interface FollowedFieldLayer {
  layer_type: FieldLayerType;
  canonical_date: string;
  fieldIds: string[];
}

export interface FollowedRegionalLayer {
  layer_type: Exclude<RegionalLayerType, 'crop-mon' | 'flood-risk'>;
  canonical_date: string;
  fieldIds: null;
}

export type FollowedLayer = FollowedFieldLayer | FollowedRegionalLayer;
export type FollowLayerMsgStart = FollowedLayer & {type: 'start'; requestId: string};
export type FollowLayerMsgStop = FollowedLayer & {type: 'stop'; requestId: string};
export type FollowLayerMsg = FollowLayerMsgStart | FollowLayerMsgStop;

// When we update a layer in the DB, we aim to show this update to the user as fast as possible, especially if he is
// currently viewing that layer. For a given layer, its max updated_at is the latest time when we added a shape for that
// layer, which is what we use to know if we need to fetch new shapes.
// There is a number of different fetching strategies depending on the layer:
// 1. for *regional* layers like soil moisture, for a given (layer_type, canonical_date), we always fetch all shapes
//    for all user groups that this user can access, on a regular basis (say every ten minutes); if the user is
//    currently viewing that layer, we also monitor the max updated_at of all those
// 2. for *cached* field layers, like the latest date of interfield in the mobile app, we also fetch all fields
//    that that user can access on a regular basis; however, unlike regional layers, we *only* monitor the
//    max updated_at of the set of shapes in the user's viewport but still update *all* shapes if an update is necessary
// 3. for *non-cached* field layers, we only fetch those fields in the user's viewport; additionally we only monitor
//    those fields max updated_at. As the user pans around, the set of field ids changes.
//
// One important consideration is that for a given layer (layer_type, canonical_date), the max updated_at of a subset
// of shapes (say, for a set of fields of interfield) is always going to be smaller or equal than for the whole layer.
// This is important to keep in mind when deciding whether to re-fetch or not. The max updated_at of a layer package for
// a set of fields can only be compared to the max updated_at of the same set of fields!
export interface SocketLayerUpdate {
  requestId: string;
  updated_at: string;
}

export function getFollowLayer(socket: Socket) {
  let followedLayerCbs: {[requestId: string]: FollowLayerCb} = {};

  socket.on('layer_update', (msg: SocketLayerUpdate) => {
    if (followedLayerCbs[msg.requestId]) {
      followedLayerCbs[msg.requestId](msg.updated_at);
    }
  });

  // A function to inform the Server to start sending updates for this layer. Returns a callback to stop following this
  // layer.
  function followLayer(layer: FollowedLayer, cb: (maxUpdatedAt: string) => void): StopCb {
    if (!socket) {
      console.error('Attempted to follow layer without a socket!');
      return () => {};
    }
    const requestId = getUuid();
    followedLayerCbs[requestId] = cb;
    const msg: FollowLayerMsgStart = {type: 'start', requestId, ...layer};
    socket.emit('follow_layer', msg);

    const stopMsg: FollowLayerMsgStop = {type: 'stop', requestId, ...layer};
    return () => {
      delete followedLayerCbs[requestId];
      socket?.emit('follow_layer', stopMsg);
    };
  }

  return followLayer;
}

export type StopCb = () => void;

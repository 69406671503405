import {useQuery} from '@tanstack/react-query';
import {Button, Space} from 'antd';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {FormyI, SubmitEventHandler} from '../../../src/Formy';
import {getI18nOptions} from '../../../src/Formy/FormyEnum';
import {useFormyValue} from '../../../src/Formy/hooks';
import {ReportConfig} from '../../../src/forms/ReportConfig';
import {getFetcherStateOrm} from '../../../src/gt-pack/stateOrm';
import {FarmEntities, fetchFarmEntities, filterFarmEntities} from '../../../src/report/report-util';
import {getCrops} from '../../../src/selectors/crops';
import {getCurUserEmail} from '../../../src/selectors/dbMeta';
import {formatDate} from '../../../src/text/date';
import {cropDesc} from '../../../src/text/desc';
import {filterNulls, unique} from '../../../src/util/arr-util';
import {FormyCheckbox} from '../Formy/FormyCheckbox';
import FormyEntitySelector from '../Formy/FormyEntitySelector';
import {FormyEnum} from '../Formy/FormyEnum';
import {FormyFarmerEmail} from '../Formy/FormyFarmerEmail';
import {FormySubmit} from '../Formy/FormySubmit';
import {useApis} from '../apis/ApisContext';
import SpinningDots from '../components/SpinningDots';
import {reportErr} from '../util/err';

type ReportConfigFormProps = {
  formy: FormyI<ReportConfig>;
  onPreview: SubmitEventHandler;
  onDownload: SubmitEventHandler;
  onSend: SubmitEventHandler;
};

type ReportConfigOptionsProps = {
  formy: FormyI<ReportConfig>;
};

function ReportConfigOptions(props: ReportConfigOptionsProps & {farmEntities: null | FarmEntities}) {
  const farm_id = useFormyValue(props.formy, 'farm_id');
  const sampleDates = useFormyValue(props.formy, 'sampleDates');
  const cropIds = useFormyValue(props.formy, 'cropIds');
  const dates = useMemo(() => Object.keys(sampleDates ?? {}).sort(), [sampleDates]);
  const crops = useSelector(getCrops);
  const harvestYears = useMemo(
    () => unique(filterNulls(props.farmEntities?.harvest.map(x => x.harvest_year) ?? []), 'desc'),
    [props.farmEntities],
  );
  const numFields = props.farmEntities?.field.length ?? 0;
  const harvestCropFormy = props.formy.getSectionFormy('cropIds');
  const t = props.formy.t;
  if (farm_id && !props.farmEntities) {
    return <SpinningDots size={24} />;
  }

  const sampleDateCheckboxes = [],
    cropIdCheckboxes = [];
  for (const sample_date of dates) {
    sampleDateCheckboxes.push(
      <FormyCheckbox
        key={sample_date}
        formy={props.formy.getSectionFormy('sampleDates')}
        field={sample_date}
        label={() => formatDate(t, sample_date)}
        labelPosition={'left'}
      />,
    );
  }
  for (const cropId in cropIds) {
    cropIdCheckboxes.push(
      <span key={cropId}>
        <FormyCheckbox
          formy={harvestCropFormy}
          field={cropId}
          label={() => cropDesc(t, crops, cropId)}
          labelPosition={'left'}
        />
      </span>,
    );
  }

  let farmHarvestYears = null;
  if (farm_id) {
    if (harvestYears.length) {
      farmHarvestYears = (
        <>
          <span className="formy-label">{props.formy.t('HarvestYear')}</span>
          <FormyEnum
            options={getI18nOptions(t, harvestYears)}
            selectMsg="HarvestYear"
            field="harvest_year"
            formy={props.formy}
          />
        </>
      );
    } else {
      const msg = props.formy.t(numFields > 0 ? {type: 'ReportNoHarvests', numFields: numFields} : 'ReportNoFields');
      farmHarvestYears = <h3 className="formy-input">{msg}</h3>;
    }
  }

  const updateAllCheckBoxes = (status: boolean) => {
    const allDates = Object.fromEntries(Object.keys(sampleDates ?? {}).map(x => [x, status]));
    props.formy.getChangeHandler('sampleDates')(allDates);
    const allCrops = Object.fromEntries(Object.keys(cropIds ?? {}).map(x => [x, status]));
    props.formy.getChangeHandler('cropIds')(allCrops);
  };

  return (
    <>
      {farmHarvestYears}
      <span className="formy-label">
        <br />
        {sampleDateCheckboxes.length || cropIdCheckboxes.length ? (
          <Button className="gt-report-button" size="small" onClick={() => updateAllCheckBoxes(false)}>
            {t('ClearAll')}
          </Button>
        ) : null}
        {sampleDateCheckboxes.length || cropIdCheckboxes.length ? (
          <Button className="gt-report-button" size="small" onClick={() => updateAllCheckBoxes(true)}>
            {t('SelectAll')}
          </Button>
        ) : null}
      </span>
      <span className="gt-report-flex-card">
        {sampleDateCheckboxes.length > 0 && (
          <span className="gt-report-flex-card-left">
            {t('SamplingDate-s') + ':'}
            {sampleDateCheckboxes}
          </span>
        )}
        <span className="gt-report-flex-card-left">
          {cropIdCheckboxes.length > 0 && t('CropType-s') + ':'}
          {cropIdCheckboxes}
        </span>
      </span>
    </>
  );
}

export const ReportConfigForm = React.memo<ReportConfigFormProps>(props => {
  const apis = useApis();
  const farm_id = useFormyValue(props.formy, 'farm_id');
  const {data: farmEntities} = useQuery(['farmEntities', farm_id], () =>
    farm_id
      ? fetchFarmEntities(
          getFetcherStateOrm(apis.store, apis, async e => reportErr(e, 'ReportConfigForm')),
          farm_id,
        )
      : Promise.resolve(null),
  );
  return (
    <span className="gt-report-selector gt-report-card">
      <span className="formy-label">{apis.t('SelectFarm')}</span>
      <FormyEntitySelector onNewEntity={null} formy={props.formy} field="farm_id" entityType="farm" />
      <ReportConfigOptions formy={props.formy} farmEntities={farmEntities ?? null} />
      <FormyReportConfigActions {...props} farmEntities={farmEntities ?? null} />
    </span>
  );
});

const FormyReportConfigActions = React.memo<ReportConfigFormProps & {farmEntities: null | FarmEntities}>(props => {
  const {formy, onSend, onPreview, onDownload, farmEntities} = props;
  const cropIds = useFormyValue(formy, 'cropIds', true);
  const sampleDates = useFormyValue(formy, 'sampleDates', true);
  const crop_id =
    cropIds &&
    Object.entries(cropIds)
      .filter(x => x[1])
      .map(x => x[0]);
  const sample_date =
    sampleDates &&
    Object.entries(sampleDates)
      .filter(x => x[1])
      .map(x => x[0]);
  const harvest_year = useFormyValue(formy, 'harvest_year');
  const selectedFieldCount = useMemo(() => {
    const dbState = farmEntities && filterFarmEntities(farmEntities, harvest_year, crop_id, sample_date);
    return dbState && Object.keys(dbState.field).length;
  }, [farmEntities, harvest_year, crop_id, sample_date]);
  const farm_id = useFormyValue(formy, 'farm_id');
  const userEmail = useSelector(getCurUserEmail);
  if (selectedFieldCount == null || !farm_id || !harvest_year) {
    return null;
  }

  if (selectedFieldCount > 40) {
    return (
      <h2 className="gt-page-section-subheader">
        {formy.t({type: 'ReportContainsTooManyFields', numFields: selectedFieldCount})}
      </h2>
    );
  }
  return (
    <>
      <span className="formy-input">
        <Space>
          <FormySubmit label="Preview" formy={formy} onSubmitOverride={onPreview} doNotConfirmSubmissionToUser={true} />
          <FormySubmit
            label="Download"
            formy={formy}
            onSubmitOverride={onDownload}
            doNotConfirmSubmissionToUser={true}
          />
        </Space>
      </span>
      <span className="formy-label">{formy.t('SendTo')}</span>
      <span className="formy-input">
        <FormyCheckbox formy={formy} field="sendToUser" label={() => userEmail} labelPosition={'left'} />
        <br />
        <FormyFarmerEmail formy={formy} field="farmer_email" />
      </span>
      <span className="formy-input">
        <FormySubmit label="Send" formy={formy} onSubmitOverride={onSend} doNotConfirmSubmissionToUser={true} />
      </span>
    </>
  );
});
